import styled from "styled-components/macro";

export default styled.span`
  margin-right: 10px;
  cursor: pointer;
  svg {
    height: 20px;
    width: 20px;
  }  
  svg path { 
    fill: ${({ theme }) => theme.palette.secondaryText}; 
    height: 20px;
    width: 20px;
  }  
  &:hover {
    svg path {
      fill: ${({ theme }) => theme.palette.primaryText};
    }
  }
`;
