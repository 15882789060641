import { createStore } from "redux";
import reducers from "./combineReducers";

// eslint-disable-next-line no-underscore-dangle
const reduxDevtools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

export default createStore(
  reducers,
  reduxDevtools,
);
