import React from "react";
import styled from "styled-components";

export default (icon) => {
  const Styled = styled(icon)`
    position: relative;
    top: 2px;
    margin-right: 5px;
  `;

  return <Styled />;
};
