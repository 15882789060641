import React, { useState } from "react";
import PropTypes from "prop-types";
import { COMMENT, NEW_COMMENT } from "../../../containers/CommitDetail/constants";
import { CopyIcon, ProjectsIcon } from "../../../images";
import { CodeLine } from "../CodeLine";
import { InlineCommentsList } from "../CommentsList";
import {
  Container,
  Header,
  CodeBlock,
  CopyButton,
  ViewFileLink,
} from "./styled";

const FileBlock = ({
  fileName,
  lines,
  fetchCommitDetail,
  toggleActionBlock,
  shouldActionBlockOpen,
  copyFileLink,
  fileIndex,
  committerId,
  commitImageUrl,
  buttonShare,
  onCancelShowOff,
  containerShowOff,
  commitAuthor,
  setState,
  openFileLink,
  enableHighlighter,
}) => {
  const [unSaveComments, setUnSaveComments] = useState([]);
  const blink = window.location.hash === `#file-${fileIndex}`;
  return (
    <Container id={`file-${fileIndex}`} blink={blink}>
      <Header>
        <span>{fileName}</span>
        <div>
          <ViewFileLink onClick={() => openFileLink(fileIndex)} title="View File">
            <ProjectsIcon />
          </ViewFileLink>
          <CopyButton onClick={() => copyFileLink(fileIndex)} title="Copy Link">
            <CopyIcon />
          </CopyButton>
        </div>
      </Header>
      <CodeBlock className="code-block">
        {lines.reduce((acc, { comments = [], ...line }, lineIndex) => {
          const actionType = {
            comment: !comments.length ? NEW_COMMENT : COMMENT,
          };
          /* eslint-disable react/no-array-index-key */
          acc.push(<CodeLine
            key={lineIndex}
            {...line}
            committerId={committerId}
            toggleActionBlock={type => toggleActionBlock(line.path, line.position, type)}
            shouldActionBlockOpen={type => shouldActionBlockOpen(line.path, line.position, type)}
            fetchCommitDetail={fetchCommitDetail}
            actionType={actionType}
            commitImageUrl={commitImageUrl}
            buttonShare={buttonShare}
            onCancelShowOff={onCancelShowOff}
            containerShowOff={containerShowOff}
            commitAuthor={commitAuthor}
            setState={setState}
            setUnSaveComments={setUnSaveComments}
            unSaveComments={unSaveComments}
            enableHighlighter={enableHighlighter}
          />);

          if (comments.length) {
            acc.push(
              <InlineCommentsList
                key={`comments-${lineIndex}`}
                comments={comments}
                committerId={committerId}
                path={line.path}
                position={line.position}
                fetchCommitDetail={fetchCommitDetail}
                addComment={shouldActionBlockOpen(line.path, line.position, COMMENT)}
              />
            );
          }
          /* eslint-enable react/no-array-index-key */
          return acc;
        }, [])}
      </CodeBlock>
    </Container>
  );
};

FileBlock.propTypes = {
  fileName: PropTypes.string,
  lines: PropTypes.arrayOf(PropTypes.object),
  fetchCommitDetail: PropTypes.func,
  toggleActionBlock: PropTypes.func,
  shouldActionBlockOpen: PropTypes.func,
  copyFileLink: PropTypes.func.isRequired,
  fileIndex: PropTypes.number.isRequired,
  committerId: PropTypes.number.isRequired,
  commitImageUrl: PropTypes.string,
  buttonShare: PropTypes.object,
  onCancelShowOff: PropTypes.func,
  containerShowOff: PropTypes.object,
  commitAuthor: PropTypes.string,
  setState: PropTypes.func.isRequired,
  openFileLink: PropTypes.func.isRequired,
  enableHighlighter: PropTypes.bool.isRequired,
};

export default FileBlock;
