import moment from "moment";
import { axios, API_URL } from "../../utils/api";
import { getUserData } from "../../utils/helpers/userData";
import { PER_PAGE_LIMIT } from "./constants";

export const filterCommits = (commitList, uuid) => {
  const filteredList = commitList
    .reduce((acc, { commits = [] } = {}) => acc.concat(commits), [])
    .filter(({ mineCommit, rejectReviewer = "" } = {}) => !mineCommit && !rejectReviewer)
    .reverse();

  const currentCommitIndex = filteredList.findIndex(({ commitUuid = "" } = {}) => commitUuid === uuid);
  const slicedList = filteredList.splice(0, currentCommitIndex);
  filteredList.splice(0, 1);
  return filteredList.concat(slicedList);
};

const formatInfoText = ({ additions, deletions } = {}, filesLength = 0) => {
  const formatStat = (stat, text) => {
    switch (stat) {
      case 0:
        return "";
      case 1:
        return `${stat} ${text.slice(0, text.length - 1)}`;
      default:
        return `${stat} ${text}`;
    }
  };

  // return formatted string, e.g. "Showing 1 changed file with 1 addition and 2 deletions"
  return `Showing ${
    formatStat(filesLength, "changed files")
  } with ${
    formatStat(additions, "additions")
  }${
    additions && deletions ? " and " : ""
  }${
    formatStat(deletions, "deletions")}`;
};

export const formatDate = (dateString) => {
  const now = moment().utc();
  const date = moment(dateString).utc();
  const diff = now.diff(date, "minutes");

  switch (true) {
    // less than a minute
    case diff < 1:
      return "just now";

    // less than two minutes
    case diff < 2:
      return "a minute ago";

    // less than an hour
    case diff < 60:
      return `${now.diff(date, "minutes")} minutes ago`;

    // less than 2 hours
    case diff < 120:
      return "an hour ago";

    // less than 24 hours
    case diff < 1440:
      return `${now.diff(date, "hours")} hours ago`;

    // less than 2 days
    case diff < 2880:
      return "a day ago";

    // less than a month
    case diff < 43200:
      return `${now.diff(date, "days")} days ago`;

    // less than 2 months
    case diff < 86400:
      return "a month ago";

    // less than a year
    case diff < 525600:
      return `${now.diff(date, "months")} months ago`;

    // show full date by default
    default:
      return date.format("MMMM Do YYYY");
  }
};

export const mapCommitDetail = (commitData, previousCommit, nextCommit) => {
  const mapComments = (comments = []) => comments.map((comment) => {
    return {
      ...comment,
      formattedDate: formatDate(comment.createdAt),
    };
  });

  return {
    ...commitData,
    previousCommit,
    nextCommit,
    comments: mapComments(commitData.comments),
  };
};

export const mapCommitFiles = (files) => {
  return files.map((file) => {
    const mapCodeLines = lines => lines.map((line) => {
      const mapComments = (comments = []) => comments.map((comment) => {
        return {
          ...comment,
          formattedDate: formatDate(comment.createdAt),
        };
      });

      return {
        ...line,
        comments: mapComments(line.comments),
      };
    });

    return {
      ...file,
      lines: mapCodeLines(file.lines),
    };
  });
};

export const getCommit = async (
  repoId,
  uuid,
  enableHighlighter,
) => {
  const technologyId = parseInt(localStorage.getItem('technologyFilterValue')) || 0;
  const {
    data: {
      success = false, data: {
        commitDetail, files, previousCommit, nextCommit,
      } = {},
    } = {},
  } = await axios.get(`${API_URL}/repository/${repoId}/commit/${uuid}`, {
    params: {
      enableHighlighter,
      technologyId,
    },
  });

  if (success) {
    return {
      ...mapCommitDetail(commitDetail, previousCommit, nextCommit),
      files: mapCommitFiles(files),
      infoText: formatInfoText(commitDetail.stats, commitDetail.filesCount),
    };
  }
  return {};
};

export const acceptCommit = async (uuid) => {
  const data = await axios.put(`${API_URL}/commit/${uuid}/accept`, {});

  return data;
};

export const rejectCommit = async (uuid) => {
  const data = await axios.put(`${API_URL}/commit/${uuid}/reject`, {});

  return data;
};

export const getNextCommit = async (repositoryId, uuid, status, page) => {
  const { id: teamMemberId = "" } = getUserData() || {};

  // fetch commits first
  const commitsListResponse = await axios.get(`${API_URL}/repository/${repositoryId}/commits`, {
    params: {
      teamMemberId,
      status,
      sort: "desc",
      page,
      perPage: PER_PAGE_LIMIT,
    },
  });
  const {
    data: {
      success = false,
      data = [],
    } = {},
  } = commitsListResponse || {};

  const formattedList = filterCommits(data, uuid);

  const [
    {
      commitUuid = "",
      sha = "",
      repositoryFullName: repoFullName = "",
      repositoryId: repoId = "",
      repositoryName: repoName = "",
      message = "",
    } = {},
  ] = formattedList || [];

  if (success && formattedList.length) {
    return {
      repoName,
      repoFullName,
      repoId,
      message,
      uuid: commitUuid,
      sha,
      teamMemberId,
    };
  }

  return null;
};

export const getNextCommitN = async () => {
  const { id: teamMemberId = "" } = getUserData() || {};
  // fetch commits first
  const commitResponse = await axios.get(`${API_URL}/commit/nextCommit`, {
    params: {
      technologyId: localStorage.getItem('technologyFilterValue'),
    },
  });
  const {
    data: {
      success = false,
      data = {},
    } = {},
  } = commitResponse || {};

  const {
    uuid,
    sha = "",
    repoFullName,
    repositoryId: repoId = "",
    repositoryName: repoName = "",
    message = "",
  } = data;
  if (success) {
    return {
      repoName,
      repoFullName,
      repoId,
      message,
      uuid,
      sha,
      teamMemberId,
    };
  }
  return null;
};

export const markAsFixed = async (uuid) => {
  const response = await axios.put(`${API_URL}/commit/${uuid}/markAsFixed`, {});

  return response.status === 204;
};
