import React from 'react';
import { PropTypes } from 'prop-types';
import { ROUTES } from "../../containers/Routes";
import Flex from "../Flex";
import { PathHeader } from "../PathHeader";
import { Button, ButtonContainer } from "./styled";
import GuidelineCategories from './GuidelineCategories';

const Header = ({
  isOpenCategory,
  selectedCategory = 0,
  openCategoryDropDown,
  onChangeCategory,
  guidelineCategoriesList,
  guidelineCategoriesNames,
  handleNewGuideline,
}) => {
  return (
    <Flex justify="space-between">
      <PathHeader pathItems={[{ label: "Guide lines", link: ROUTES.GUIDELINES }]} />
      <ButtonContainer>
        <Flex justify="space-between">
          <GuidelineCategories
            open={isOpenCategory}
            openDropDown={() => openCategoryDropDown(!isOpenCategory)}
            onSelectCategory={({ value }) => onChangeCategory(value)}
            guidelineCategoriesList={guidelineCategoriesList}
            label={guidelineCategoriesNames[selectedCategory] || "Select Category"}
          />
          <Button onClick={() => handleNewGuideline(true)}>+ Add new guideline</Button>
        </Flex>
      </ButtonContainer>
    </Flex>
  );
};

Header.propTypes = {
  isOpenCategory: PropTypes.bool,
  selectedCategory: PropTypes.number,
  openCategoryDropDown: PropTypes.func.isRequired,
  onChangeCategory: PropTypes.func.isRequired,
  guidelineCategoriesList: PropTypes.array.isRequired,
  guidelineCategoriesNames: PropTypes.object.isRequired,
  handleNewGuideline: PropTypes.func.isRequired,
};


export default Header;
