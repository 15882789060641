import styled from "styled-components/macro";

export default styled.div`
  margin: 20px 0px 20px;
  color: ${({ theme }) => theme.palette.primaryText};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  & .loader {
    margin-bottom: 50px;
  }
`;
