import React from 'react';
import { PropTypes } from 'prop-types';
import { DataTable } from "../../containers/DataTable";
import { Label, TechnologyWrapper } from "./styled";
import TechnologyTile from "./TechnologyTile";

const Table = ({
  projectList,
  tableHeadColumns,
  onChangeSort,
}) => {
  const rows = [];

  const getNameColumnValue = (item) => {
    return (
      <TechnologyWrapper>
        <TechnologyTile name={item.technologyName || ''} />
        <Label>{item.name}</Label>
      </TechnologyWrapper>
    );
  };

  if (!projectList || !projectList.length) {
    rows.push({
      name: { value: "No record found.", colSpan: 6 },
    });
  } else {
    projectList.forEach((item) => {
      rows.push({
        name: { value: getNameColumnValue(item), width: '40%' },
        systemRejectedCommitsCount: { value: item.systemRejectedCommitsCount, width: '30%' },
        rejectedCommitsCount: { value: item.rejectedCommitsCount, width: '30%' },
      });
    });
  }

  return (
    <DataTable
      rows={rows}
      columns={tableHeadColumns}
      onChangeSort={onChangeSort}
      defaultSort={{ column: 'name', order: 'asc' }}
    />
  );
};

Table.propTypes = {
  projectList: PropTypes.array.isRequired,
  tableHeadColumns: PropTypes.array.isRequired,
  onChangeSort: PropTypes.func.isRequired,
};


export default Table;
