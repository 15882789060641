import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Transition } from "react-transition-group";
import { Popup as Content } from "../../components/Popup";
import withPopup from "./withPopup";

import { ANIMATION_DURATION, TRANSITION_DURATION } from "./constants";
import { Container, Overlay } from "./styled";

const Popup = ({
  closePopup,
  Popup: {
    isOpen,
    text,
  },
}) => {
  useEffect(() => {
    const timeout = isOpen
      ? setTimeout(() => {
        closePopup();
      }, ANIMATION_DURATION)
      : undefined;

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen, closePopup]);

  return (
    <Transition in={isOpen} timeout={TRANSITION_DURATION}>
      {state => (
        <Overlay state={state}>
          <Container state={state}>
            <Content text={text} />
          </Container>
        </Overlay>
      )}
    </Transition>
  );
};

Popup.propTypes = {
  closePopup: PropTypes.func.isRequired,
  Popup: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
};

export default withPopup(Popup);
