import React from "react";
import PropTypes from "prop-types";
import Flex from "../Flex";
import { CommentsCountText } from "./styled";

const CommitTileStatus = ({
  Icon,
  text,
}) => (
  <Flex align="center">
    <CommentsCountText>{ text }</CommentsCountText>
    <Icon />
  </Flex>
);

CommitTileStatus.propTypes = {
  Icon: PropTypes.object.isRequired,
  text: PropTypes.number.isRequired,
};

export default CommitTileStatus;
