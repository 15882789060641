import { axios, API_URL } from "../../utils/api";

export const getFeatures = async () => {
  const response = await axios.get(`${API_URL}/feature/monthwise`);
  if (response.status === 200) {
    const { data: { data = [] } } = response || {};
    return data;
  }
  throw new Error('cannot get features!!');
};

export const saveFeature = async (params) => {
  const response = await axios.post(`${API_URL}/feature/save`, params);
  if (response.status === 200) {
    const { data: { data = {} } } = response || {};
    return data;
  }
};

export const updateFeature = async (id, data) => {
  const response = await axios.put(`${API_URL}/feature/${id}`, data);
  return response.status === 204;
};

export const deleteFeature = async (id) => {
  const response = await axios.delete(`${API_URL}/feature/${id}`);
  return response.status === 204;
};
