import PropTypes from "prop-types";
import {
  AndroidIcon, iOSIcon, DotNetIcon, NodeIcon, PhpIcon, ReactIcon, ProjectsIcon,
} from "../../images";
import { TECHNOLOGY_TYPE } from "../Projects/ProjectTile/constants";
import { RenderIcon } from "./styled";

const TechnologyTile = ({ name }) => {
  switch (name) {
    case TECHNOLOGY_TYPE.REACT:
      return (
        RenderIcon(ReactIcon)
      );

    case TECHNOLOGY_TYPE.NODE:
      return (
        RenderIcon(NodeIcon)
      );

    case TECHNOLOGY_TYPE.IOS:
      return (
        RenderIcon(iOSIcon)
      );

    case TECHNOLOGY_TYPE.ANDROID:
      return (
        RenderIcon(AndroidIcon)
      );

    case TECHNOLOGY_TYPE.REACTNATIVE:
      return (
        RenderIcon(ReactIcon)
      );

    case TECHNOLOGY_TYPE.DOTNET:
      return (
        RenderIcon(DotNetIcon)
      );

    case TECHNOLOGY_TYPE.PHP:
      return (
        RenderIcon(PhpIcon)
      );

    default:
      return (
        RenderIcon(ProjectsIcon)
      );
  }
};

TechnologyTile.propTypes = {
  name: PropTypes.string.isRequired,
};

export default TechnologyTile;
