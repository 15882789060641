import styled from "styled-components/macro";

export default styled.li`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${({ padding }) => padding || 0};
  color: ${({ theme }) => theme.palette.dropdownText};
  border-bottom: 1px solid ${({ theme }) => theme.palette.dropdownBorder};
  cursor: pointer;
  width: 250px;

  button {
    width: 100%;
    height: 100%;
  }

  & > ul {
    visibility: hidden;
  }

  :first-child {
    border-radius: 6px 6px 0 0;
  }

  :last-child {
    border-radius: 0 0 6px 6px;
    border-bottom: none;
  }

  :hover {
    background: ${(({ theme }) => theme.palette.dropdownHover)};
    & > ul {
      visibility: visible;
    }
  }
`;
