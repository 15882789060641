import styled from "styled-components/macro";
import palettes from "../../../containers/ThemeControl/themes/palettes";
const {
  LIGHT: { primaryBg },
} = palettes;

export default styled.div`
  background: ${({ forceLightTheme }) => forceLightTheme ? primaryBg : "none"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ height }) => height || "100%"};
  padding: 4px;
`;
