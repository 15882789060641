import React from "react";
import styled from "styled-components";

export default (icon) => {
  const Styled = styled(icon)`
    width: 40px;
    height: 30px;
    position: absolute;
  `;

  return <Styled />;
};
