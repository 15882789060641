import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getProjectsInsights } from '../api';

const useGetProjectRows = () => {
  const { projectList, totalCount, filters } = useSelector(store => store.projectsInsights);
  const { page, order, sort } = filters;

  useEffect(() => {
    getProjectsInsights(filters);
  }, [filters, page, order, sort]);

  return { projectList, totalCount };
};

export default useGetProjectRows;
