import React, { useEffect, useState } from "react";
import moment from "moment";
import { Chart } from "react-google-charts";
import DatePicker from "react-datepicker";
import { Container } from "./styled";
import { getUserStats } from "../../../containers/Dashboard/api";


const ChartViewContainer = () => {
  const [startDate, setStartDate] = useState(moment().utc().subtract(1, 'months').toDate());
  const [endDate, setEndDate] = useState(moment().utc().toDate());
  const datePickerStyle = {
    margin: "20px 20px 20px 20px",
  };
  const [data, setData] = useState([
    ['Commit Date', 'Accepted', 'Pending', 'Mark As Fixed', 'Record Count'],
    ['2023', 0, 0, 0, 0],
  ]);
  const options = {
    title: "Commit Stats",
    titleTextStyle: {
      color: "#FFF",
    },
    curveType: "function",
    legend: { position: "bottom" },
    backgroundColor: "#292E33",
    colors: ["#FF5630", "#00B8D9", "#0052CC", "#36B37E"],
    titleColor: "#FFF",
    hAxis: {
      textStyle: {
        color: "#FFF",
      },
    },
    vAxis: {
      minValue: 0,
      textStyle: {
        color: "#FFF",
      },
    },
    annotations: {
      textStyle: {
        color: "#red",
      },
    },
  };
  useEffect(() => {
    (async () => {
      const response = await getUserStats(startDate, endDate);
      const commitData = response.userStat.map((obj) => {
        return Object.values({ ...obj, commitDate: moment(obj.commitDate).format('Do MMM') });
      });
      setData([
        ['Commit Date', 'Accepted', 'Pending', 'Mark As Fixed', 'Record Count'],
        ...commitData,
      ]);
    })();
  }, [startDate, endDate]);

  return (
    <Container padding="0px">
      <div style={datePickerStyle}>
        <span>
          From:
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
          />
        </span>
        <span>
          To:
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
          />
        </span>
      </div>
      {data && (
        <Chart
          chartType="LineChart"
          width="100%"
          height="400px"
          data={data}
          options={options}
        />
      )}
    </Container>
  );
};

export default ChartViewContainer;
