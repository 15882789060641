import { axios, API_URL } from "../../utils/api";
import { formatResponseWithMeta } from "../../utils/common";
import { store } from "../../store";
import {
  setProjectList, setProjectError, setLoader,
} from "./actions";
import { PER_PAGE_LIMIT } from "./constants";

export const getProjectsInsights = async ({
  page = 1,
  sort,
  order,
  search = '',
  fromDate = '',
  toDate = '',
  technologyFilter: { value: technologyId = 0 },
}) => {
  try {
    store.dispatch(setLoader(true));
    const params = {
      page,
      perPage: PER_PAGE_LIMIT,
      order,
      sort,
      search,
      fromDate,
      toDate,
      technologyId,
    };
    const response = await axios.get(`${API_URL}/repositories/insights`, { params });

    if (response.status === 200) {
      const { data, meta: { totalCount = 0 } } = formatResponseWithMeta(response);
      store.dispatch(setProjectList({ projectList: data, totalCount: Number(totalCount) }));
      return data;
    }

    store.dispatch(setLoader(false));
    return false;
  } catch (error) {
    store.dispatch(setProjectError("API failed!"));
    return error;
  }
};

export const getTechnology = async () => {
  const responseData = await axios.get(`${API_URL}/technology`);

  const { data: { data = {} } = {} } = responseData;
  return data;
};
