import styled from "styled-components/macro";

export default styled.div`
  position: relative;
  height: 100%;
  vertical-align: middle;
  width: 20%

  // &.code-addition {
  //   background-color: ${({ theme }) => theme.palette.codeAdditionActionBg};
  // }

  // &.code-deletion {
  //   background-color: ${({ theme }) => theme.palette.codeDeletionActionBg};
  // }
`;
