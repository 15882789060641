import React from "react";
import { Text, Emoji, Container } from "./styled";

const WorkInProgress = () => (
  <Container>
    <Emoji>{"¯\\_(ツ)_/¯"}</Emoji>
    <Text>Work in progress</Text>
  </Container>
);

export default WorkInProgress;
