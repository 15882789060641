import React from "react";
import PropTypes from "prop-types";

const TBody = ({
  rows = [],
  columns = [],
  ...restProps
}) => {
  return (
    <tbody {...restProps}>
      {
        rows.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={index}>
            {
              columns.map(({ key = '' }) => {
                if (!item[key]) {
                  return false;
                }
                const { value = '', ...rest } = item[key];
                return (<td key={key} {...rest}>{ value }</td>);
              })
            }
          </tr>
        ))
      }
    </tbody>
  );
};

TBody.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
};

export default TBody;
