import styled from "styled-components/macro";

export default styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.primaryText};
  width: 80%;
  border: 2px solid ${({ theme }) => theme.palette.primaryBg};
  border-radius: 8px;
  margin-left: 20px;
  padding: 16px;
  margin-bottom: 20px;
  :last-child {
    margin-bottom: 0;
  }
  & svg path {
    stroke: transparent;
  }
  &:hover {
    background: ${({ theme }) => theme.palette.primaryBg};
    & svg path {
      stroke: ${({ theme }) => theme.palette.primaryText};
    }
  }
  & .actionButton:hover {
    color: ${({ theme }) => theme.palette.brandColor};
    & svg path {
      stroke: ${({ theme }) => theme.palette.brandColor};
    }
  }
`;
