import styled from "styled-components/macro";

export default styled.ul`
  display: flex;
  flex-direction: ${({ column }) => column ? "column" : "row"};
  align-items: center;
  padding: 4px;
  border-radius: 36px;

  li {
    margin: ${({ column }) => column ? "0 0 5px 0" : "0 5px 0 0"};
  }

  /* FIXME: placeholder color */
  background: ${({ theme }) => theme.palette.mainText};
`;
