import styled from "styled-components/macro";

export default styled.aside`
  position: fixed;
  z-index: 10;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.sidebarBg};
  ${({ theme }) => theme.mixins.dropShadow};
  border-radius: 0 8px 8px 0;
  height: 100%;
`;
