import React from "react";
import PropTypes from "prop-types";
import { OrgSwitchIcon, OrgSwitchOrangeIcon } from "../../../images";
import { withTheme, PALETTES } from "../../../containers/ThemeControl";
import {
  Container, Button, Icon, Label,
} from "./styled";
import { Options } from "../index";

const OrgSwitch = ({
  children,
  toggleOpen,
  isOpen,
  label = "Select organization",
  theme: { activePalette },
}) => {
  return (
    <Container>
      <Button onClick={toggleOpen} className="ignore-click">
        <Icon>
          {activePalette === PALETTES.DEFAULT
            ? <OrgSwitchIcon />
            : <OrgSwitchOrangeIcon />
          }
        </Icon>
        <Label>{label}</Label>
      </Button>
      {isOpen && <Options>{children}</Options>}
    </Container>
  );
};

OrgSwitch.propTypes = {
  children: PropTypes.any.isRequired,
  toggleOpen: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(OrgSwitch);
