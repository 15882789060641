import React from "react";
import PropTypes from "prop-types";
import { NavList } from "../../components/NavList";
import { NavLogo } from "../../components/NavLogo";
import { NavSwitch } from "../../components/NavSwitch";
import { ROUTES } from "../Routes";
import { withOrgs } from "../withOrgs";
import {
  StatsIcon,
  ProjectsIcon,
  ShareIcon,
  GuideIcon,
  HelpIcon,
  CommitIcon,
} from "../../images";
import { Container } from "./styled";
import { userRole } from "./constants";

const navSwitchProps = {
  label: "Collapse sidebar",
};

const Nav = ({
  theme: {
    navbar: { activeWidth },
  },
  toggleNavbar,
  orgs: {
    active: { role = "" } = {},
  } = {},
}) => {
  const navCollapsed = activeWidth === "SMALL";
  const { label } = navSwitchProps;
  const classToggle = navCollapsed ? "collapsed" : "expanded";

  const navListItems = [
    {
      icon: StatsIcon, to: ROUTES.DASHBOARD, exact: true, label: "Dashboard", key: 0, hidden: true,
    },
    {
      icon: ProjectsIcon, to: ROUTES.PROJECTS, label: "Projects", key: 1, hidden: false,
    },
    {
      icon: StatsIcon, to: ROUTES.INSIGHTS, label: "Insights", key: 2, hidden: false,
    },
    ...role === userRole.Admin ? [{
      icon: ShareIcon, to: ROUTES.MANAGE_PROJECTS, label: "Manage projects", key: 3, hidden: false,
    }] : [],
    {
      icon: ShareIcon, to: ROUTES.SHARE, label: "Show off", key: 3, hidden: true,
    },
    ...role === userRole.Admin ? [{
      icon: GuideIcon, to: ROUTES.GUIDELINES, label: "Guidelines", key: 4, hidden: false,
    }] : [],
    ...role === userRole.Admin ? [{
      icon: HelpIcon, to: ROUTES.UPDATES, label: "Updates", key: 5, hidden: false,
    }] : [],
    {
      icon: CommitIcon, to: ROUTES.ALLCOMMITS, label: "Commits", key: 6, hidden: false,
    },
    {
      icon: HelpIcon, to: ROUTES.HELP, label: "Help desk", key: 6, hidden: true,
    },
  ];

  const filterNavItems = navListItems.filter(item => item.hidden === false);
  return (
    <Container>
      <div>
        <NavLogo navCollapsed={navCollapsed} classToggle={classToggle} />
        <NavList navItems={filterNavItems} classToggle={classToggle} />
      </div>
      <NavSwitch label={!navCollapsed ? label : ""} toggleNav={toggleNavbar} navCollapsed={navCollapsed} />
    </Container>
  );
};

Nav.propTypes = {
  theme: PropTypes.object.isRequired,
  toggleNavbar: PropTypes.func.isRequired,
  orgs: PropTypes.object.isRequired,
};

export default withOrgs(Nav);
