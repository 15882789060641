import { OPEN_POPUP, CLOSE_POPUP } from "./constants";

export const initialState = {
  isOpen: false,
  text: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_POPUP:
      return {
        ...state,
        isOpen: true,
        text: action.payload,
      };

    case CLOSE_POPUP:
      return { ...state, isOpen: false };

    default: return state;
  }
};
