import React from "react";
import { Switch, Route } from "react-router-dom";
import { Projects } from "../Projects";
import { Commits } from "../Commits";
import { AllCommits } from "../AllCommits";
import { CommitDetail } from "../CommitDetail";
import { Dashboard } from "../Dashboard";
import { ViewFile } from "../ViewFile";
import { Updates } from "../Updates";
import { NotFound } from "../../components/NotFound";
import { WorkInProgress } from "../../components/WorkInProgress";
import { ManageProjects } from "../ManageProjects";
import { Guidelines } from "../Guidelines";
import { ProjectInsights } from "../ProjectsInsights";
import { ROUTES } from ".";
import { Settings } from "../Settings";
import PrivateRoute from './PrivateRoute';
import { ROLES } from "./constants";

export default () => {
  return (
    <Switch>
      <Route exact path={ROUTES.DASHBOARD} component={Dashboard} />
      <Route exact path={ROUTES.PROJECTS} component={Projects} />
      <Route exact path={ROUTES.REPO} component={Commits} />
      <Route exact path={ROUTES.COMMIT} component={CommitDetail} />
      <Route exact path={ROUTES.SHARE} component={WorkInProgress} />
      <Route exact path={ROUTES.HELP} component={WorkInProgress} />
      <Route exact path={ROUTES.VIEW_FILE} component={ViewFile} />
      <Route exact path={ROUTES.SETTINGS} component={Settings} />
      <PrivateRoute exact path={ROUTES.UPDATES} component={Updates} role={ROLES.admin} />
      <PrivateRoute exact path={ROUTES.MANAGE_PROJECTS} component={ManageProjects} role={ROLES.admin} />
      <Route exact path={ROUTES.INSIGHTS} component={ProjectInsights} />
      <PrivateRoute exact path={ROUTES.GUIDELINES} component={Guidelines} role={ROLES.admin} />
      <Route exact path={ROUTES.ALLCOMMITS} component={AllCommits} />
      <Route component={NotFound} />
    </Switch>
  );
};
