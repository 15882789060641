import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from "../Dropdown";
import { DropdownTick } from "../../images";

const GuidelineCategories = ({
  open, openDropDown, onSelectCategory, guidelineCategoriesList, label,
}) => (
  <Dropdown
    className="dropdown"
    label={label}
    Icon={DropdownTick}
    data={guidelineCategoriesList}
    handleDropdownClick={openDropDown}
    handleChoiceClick={val => onSelectCategory(val)}
    isMenuOpen={open}
  />
);

GuidelineCategories.propTypes = {
  open: PropTypes.bool,
  guidelineCategoriesList: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  openDropDown: PropTypes.func.isRequired,
  onSelectCategory: PropTypes.func.isRequired,
};

export default GuidelineCategories;
