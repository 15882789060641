import { combineReducers } from "redux";
import themeReducer from "../containers/ThemeControl/reducer";
import authReducer from "../containers/AuthUser/reducer";
import orgReducer from "../containers/withOrgs/reducer";
import PopupReducer from "../containers/Popup/reducer";
import commitListFiltersReducer from "../containers/Commits/reducer";
import guidelinesReducer from "../containers/GuidelinesDropdown/reducer";
import projectsReducer from "../containers/ManageProjects/reducer";
import guidelineReducer from "../containers/Guidelines/reducer";
import categorizedProjectsReducer from "../containers/Projects/reducer";
import projectsInsightsReducer from "../containers/ProjectsInsights/reducer";

export default combineReducers({
  theme: themeReducer,
  auth: authReducer,
  orgs: orgReducer,
  Popup: PopupReducer,
  commitListFilters: commitListFiltersReducer,
  guidelines: guidelinesReducer,
  guideline: guidelineReducer,
  projects: projectsReducer,
  categorizedProjects: categorizedProjectsReducer,
  projectsInsights: projectsInsightsReducer,
});
