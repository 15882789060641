import queryString from "query-string";
import { axios, API_URL } from "../../utils/api";
import { ROUTES } from "../Routes";
import { PER_PAGE_LIMIT } from "./constants";

export const getKanbanBoardCards = async (repositoryId) => {
  const responseData = await axios.get(`${API_URL}/repository/${repositoryId}/kanbanBoardCards`);

  const { data: { data = [] } = {} } = responseData;
  return [{ label: "All", value: "" }, ...data];
};

export const getTechnology = async () => {
  const responseData = await axios.get(`${API_URL}/technology`);

  const { data: { data = {} } = {} } = responseData;
  return data;
};

export const getAllCommits = async (
  technologyId,
  repositoryId,
  page,
) => {
  const responseData = await axios.get(`${API_URL}/commit`, {
    params: {
      technologyId,
      repositoryId,
      page,
      perPage: PER_PAGE_LIMIT,
    },
  });
  const { data: { data = [], meta: { totalPages = 0 } } = {} } = responseData;
  return { commitGroups: data, totalPages };
};

export const formatResponseData = (data) => {
  return {
    formattedStatus: formatStatus(data),
    linkTo: generateCommitLink(data),
    parentsLinks: generateParentsLinks(data),
    ...data,
  };
};

export const formatUsersData = (data) => {
  const result = data.map(({ id: value, login: label }) => ({ label, value }));

  return [{ label: "All", value: "" }, ...result];
};

export const formatStatus = ({ status, reviewer }) => {
  switch (status) {
    case 0:
      return "waiting";
    case 1:
      return `accepted ${reviewer}`;
    case 2:
      return `rejected ${reviewer}`;
    case 3:
      return `passed ${reviewer}`;
    default:
      return "";
  }
};

const generateCommitLink = ({
  sha, commitUuid: uuid, repositoryName: repoName, repositoryFullName: repoFullName, repositoryId: repoId,
}) => {
  const search = queryString.stringify({
    sha,
    repoName,
    repoFullName,
    uuid,
    repoId,
  });

  return { pathname: ROUTES.COMMIT, search };
};

const generateParentsLinks = ({
  parentCommits = [], repositoryName: repoName, repositoryFullName: repoFullName, repositoryId: repoId,
}) => {
  return parentCommits.map(({
    sha, uuid, shortSha,
  }) => {
    return {
      link: generateCommitLink({
        sha, commitUuid: uuid, shortSha, repoName, repoFullName, repoId,
      }),
      label: shortSha,
    };
  });
};
