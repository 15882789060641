import {
  SET_GUIDELINE_INITIAL_STATE, SET_GUIDELINE_LIST, SET_GUIDELINE_ERROR, SET_GUIDELINE_FILTERS, SET_GUIDELINE_CATEGORIES, SET_GUIDELINE_LOADER,
} from "./constants";

export const initialState = {
  loader: false,
  error: null,
  guidelineList: undefined,
  totalCount: 0,
  filters: {
    page: 1, order: 'label', sort: 'asc', codingGuidelineCategoryId: null,
  },
  guidelineCategoriesList: [],
  guidelineCategoriesNames: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GUIDELINE_LIST: {
      return {
        ...state, guidelineList: action.payload.guidelineList, totalCount: action.payload.totalCount, loader: false,
      };
    }

    case SET_GUIDELINE_INITIAL_STATE: {
      return action.payload || initialState;
    }

    case SET_GUIDELINE_ERROR: {
      return { ...state, error: action.error };
    }

    case SET_GUIDELINE_LOADER: {
      return { ...state, loader: action.flag };
    }

    case SET_GUIDELINE_FILTERS: {
      return { ...state, filters: action.filters };
    }

    case SET_GUIDELINE_CATEGORIES: {
      return {
        ...state,
        guidelineCategoriesList: action.payload.guidelineCategoriesList,
        guidelineCategoriesNames: action.payload.guidelineCategoriesNames,
      };
    }

    default:
      return state;
  }
};
