import {
  SET_PROJECT_INITIAL_STATE, SET_PROJECT_LIST, SET_PROJECT_ERROR, SET_PROJECT_FILTERS, SET_PROJECT_LOADER,
} from "./constants";

export const initialState = {
  loader: false,
  error: null,
  projectList: undefined,
  totalCount: 0,
  filters: {
    page: 1,
    order: 'name',
    sort: 'asc',
    search: '',
    fromDate: '',
    toDate: '',
    technologyFilter: {
      label: 'All Stacks',
      value: 0,
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECT_LIST: {
      return {
        ...state, projectList: action.payload.projectList, totalCount: action.payload.totalCount, loader: false,
      };
    }

    case SET_PROJECT_INITIAL_STATE: {
      return action.payload || initialState;
    }

    case SET_PROJECT_ERROR: {
      return { ...state, error: action.error };
    }

    case SET_PROJECT_LOADER: {
      return { ...state, loader: action.flag };
    }

    case SET_PROJECT_FILTERS: {
      return { ...state, filters: action.filters };
    }

    default:
      return state;
  }
};
