import styled from "styled-components/macro";
import palettes from "../../../containers/ThemeControl/themes/palettes";
const {
  LIGHT: { brandColor },
} = palettes;
const SIZE = {
  small: "40px",
  medium: "64px",
  large: "80px",
  default: "64px",
};

export default styled.div`
  width: ${props => SIZE[props.size] ? SIZE[props.size] : SIZE.default};
  height: ${props => SIZE[props.size] ? SIZE[props.size] : SIZE.default};
  border: 6px solid transparent;
  border-radius: 50%;
  animation: lds-ring 1.2s linear infinite;
  border-bottom-color: ${({ forceLightTheme, theme }) => forceLightTheme ? brandColor : theme.palette.brandColor};
  border-top-color: ${({ forceLightTheme, theme }) => forceLightTheme ? brandColor : theme.palette.brandColor};

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
