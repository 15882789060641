import styled from "styled-components/macro";

export default styled.div`
    /* The switch - the box around the slider */
    & .switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 24px;
    }

    /* Hide default HTML checkbox */
    & .switch input {
    opacity: 0;
    width: 0;
    height: 0;
    }

    /* The slider */
    & .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.palette.dropdownSecondaryBg};
    -webkit-transition: .4s;
    transition: .4s;
    }

    & .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    }

    & input:checked + .slider {
    background-color: ${({ theme }) => theme.palette.brandColor};
    }

    & input:focus + .slider {
    box-shadow: 0 0 1px ${({ theme }) => theme.palette.brandColor};
    }

    & input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    }

    /* Rounded sliders */
    & .slider.round {
    border-radius: 34px;
    }

    & .slider.round:before {
    border-radius: 50%;
    }
`;
