import styled from "styled-components/macro";

export default styled.p`
  font-size: 48px;
  font-weight: 900;
  text-align: center;
  color: ${({ theme }) => theme.palette.mainText};
  padding-bottom: 24px;
  &:last-child {
    padding-bottom: 0;
  }
`;
