export const ROUTES = {
  DASHBOARD: "/dashboard",
  PROJECTS: "/projects",
  VIEW_FILE: "/view-file",
  MANAGE_PROJECTS: "/manage-projects",
  REPO: "/projects/repository",
  COMMIT: "/projects/commit",
  SHARE: "/share",
  GUIDELINES: "/guidelines",
  HELP: "/help-desk",
  UPDATES: "/updates",
  SETTINGS: "/settings",
  ALLCOMMITS: "/commits",
  INSIGHTS: "/insights",
};

export const ROLES = {
  admin: 'admin',
  member: 'member',
};
