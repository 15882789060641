import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

export default styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  height: 76px;
  width: 248px;

  text-decoration: none;
  color: ${({ theme }) => theme.palette.sidebarItem};

  svg path {
    fill: ${({ theme }) => theme.palette.sidebarItem};
  }

  &:hover {
    color: ${({ theme }) => theme.palette.sidebarItemHover};
    & svg path {
      fill: ${({ theme }) => theme.palette.sidebarItemHover};
    }
  }

  &.active {
    background: ${({ theme }) => theme.palette.brandColor};
    color: ${({ theme }) => theme.palette.sidebarItemActive};

    & svg path {
      fill: ${({ theme }) => theme.palette.sidebarItemActive};
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: -10px;
      height: 100%;
      width: 10px;
      background: ${({ theme }) => theme.palette.brandColor};
      border-radius: 0 10px 10px 0;
    }
  }

  &.collapsed {
    width: 96px;
    &.active {
      background: none;
      &:after {
        display: none;
      }
      & svg path {
        fill: ${({ theme }) => theme.palette.brandColor};
      }
    }
  }
`;
