import styled from "styled-components/macro";
import { GuideIcon } from "../../../images";

export default styled(GuideIcon)`
  height: 100%;
  width: 100%;
  path {
    fill: 2px solid ${({ theme }) => theme.palette.primaryText};
  }
`;
