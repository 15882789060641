/* eslint-disable prefer-template, react/no-danger */
import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import "./codemirror.css";

const useCodeMirrorMode = (mode) => {
  const [isSettingMode, setIsSettingMode] = useState(true);
  useEffect(() => {
    setIsSettingMode(true);
    import(/* webpackInclude: /(\.js|\.jsx)$/ */"codemirror/mode/" + mode + "/" + mode).then(() => {
      setIsSettingMode(false);
    });
  }, [mode]);

  return { isSettingMode, mode };
};

const CodeMirror = ({
  value,
  mode,
}) => {
  const { isSettingMode } = useCodeMirrorMode(mode);

  if (isSettingMode) {
    return null;
  }

  return (
    <div dangerouslySetInnerHTML={{ __html: value }} />
  );
};

CodeMirror.propTypes = {
  value: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
};

export default CodeMirror;
