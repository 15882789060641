import styled from "styled-components/macro";
import palettes from "../../../containers/ThemeControl/themes/palettes";

// use fixed color from the default palette regardless of theme choosen
const {
  DEFAULT: { brandColor },
} = palettes;

export default styled.button`
  display: flex;
  align-items: center;
  padding: 11px 0;
  background: ${brandColor};
  margin: ${({ margin }) => margin || "auto"};
  border-radius: 8px;
`;
