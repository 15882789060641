import styled from "styled-components/macro";

export default styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  z-index: 5;
  
  box-sizing: border-box;
  border-radius: 0 0 8px 8px;
  background: ${({ theme }) => theme.palette.dropdownChoiceBG};
  max-height: 300px;
  overflow-y: scroll;
`;
