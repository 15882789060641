import styled from "styled-components/macro";

export default styled.div`
  display: flex;
  flex: 1;
  width: 50%;
  justify-content: center;
  /* width: 50%;  */
  /* height: 16px; */
`;
