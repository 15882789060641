import styled, { css } from "styled-components/macro";
import { TRANSITION_DURATION } from "../constants";
import fadeAnimation from "./fadeAnimation";

const visible = css`
  opacity: 1;
  visibility: visible;
`;

const invisible = css`
  opacity: 0;
  visibility: hidden;
`;

export default styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.palette.overlayBg};

transition: all ${TRANSITION_DURATION}ms;

  /* always on top */
  z-index: 2;

  ${fadeAnimation(visible, invisible)};
`;
