import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ErrorMessage from "../../components/ErrorMessage";
import { setProjectError } from "./actions";

const ApiError = () => {
  const dispatch = useDispatch();
  const error = useSelector(store => store.projects.error);
  const onCancel = () => {
    dispatch(setProjectError(null));
  };

  return error && (<ErrorMessage data-test="ErrorMessage" message={error} onCancel={onCancel} />);
};

export default ApiError;
