import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { withOrgs } from "../withOrgs";
import { ROUTES } from "../Routes";
import {
  OrgSwitch as Switch,
  StyledOption as Option,
  Loader,
} from "../../components/OrgDropdown";

export const OrgSwitch = ({
  setActiveOrg,
  orgs: {
    list: orgsList,
    active: { login: chosenOrgName } = {},
  } = {},
}) => {
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = () => setOpen(prev => !prev);

  const handleOutsideClick = (event) => {
    const path = event.path || (event.composedPath && event.composedPath()) || [];
    const check = path.some(({ classList = [] } = {}) => [...(classList || [])].some(className => className === "ignore-click"));
    if (!check) setOpen(false);
  };

  const handleOptionClick = (option) => {
    setActiveOrg(option);
    setOpen(false);
    history.push(ROUTES.PROJECTS);
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <Switch isOpen={isOpen} toggleOpen={toggleOpen} label={chosenOrgName}>
      {orgsList.map(({ login, id, imported }, index, array) => {
        return (
          <Option key={id} onClick={() => handleOptionClick(array[index])}>
            {login}
            {!imported && <Loader />}
          </Option>
        );
      })}
    </Switch>
  );
};

OrgSwitch.propTypes = {
  setActiveOrg: PropTypes.func.isRequired,
  orgs: PropTypes.object.isRequired,
};

export default withOrgs(OrgSwitch);
