import styled from "styled-components/macro";

export default styled.button` 
  display: flex;
  justify-content: space-between;
  
  position: relative;
  min-width: 222px;

  background: ${({ theme }) => theme.palette.dropdownBg};
  box-sizing: border-box;
  border-radius: ${({ open }) => open ? "8px 8px 0 0" : "8px"};
  padding: 12px 16px 11px 17px;
  margin: 0 0 0 8px;
  
  color: ${({ theme }) => theme.palette.dropdownText};
  text-transform: capitalize;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  white-space: pre;
  
  svg {
    transform: ${({ open }) => open && "rotate(180deg)"};
    margin-left: 21px;  
  }
`;
