import styled from "styled-components/macro";
import ContentBlock from "../../ContentBlock";

export default styled(ContentBlock)`
  :hover {
    background: ${({ theme }) => theme.palette.primaryBgHover};
  }
  border-radius: 0px;
  background: unset;
  box-shadow: unset;
  padding: 16px;
  align: center;
  justify: flex-start;
  borderRadius: 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.bodyBg};
  & .flex-grow {
    flex-grow: 1;
  }
`;
