import styled from "styled-components/macro";

export default styled.div`
  width: 11%;
  &.code-addition {
    background-color: ${({ theme }) => theme.palette.codeAdditionActionBg};
  }

  &.code-deletion {
    background-color: ${({ theme }) => theme.palette.codeDeletionActionBg};
  }
`;
