import {
  DAILY, NEED_HELP, UPDATE_DAILY, UPDATE_NEED_HELP, CLEAR_PROJECTS_DATA,
} from "./constants";

export const setDaily = payload => ({
  type: DAILY,
  payload,
});

export const setNeedHelp = payload => ({
  type: NEED_HELP,
  payload,
});

export const updateDaily = payload => ({
  type: UPDATE_DAILY,
  payload,
});

export const updateNeedHelp = payload => ({
  type: UPDATE_NEED_HELP,
  payload,
});

export const clearProjectsData = () => ({
  type: CLEAR_PROJECTS_DATA,
});
