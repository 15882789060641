import { axios, API_URL } from "../../utils/api";
export const getUserStats = async (
  startDate,
  endDate,
) => {
  const responseData = await axios.get(`${API_URL}/dashboard/stats`, {
    params: {
      startDate,
      endDate,
    },
  });
  const { data: { data = [] } = {} } = responseData;
  return { userStat: data };
};
