/* eslint-disable react/jsx-one-expression-per-line */
import React, { useReducer, useEffect } from "react";
import { MentionsInput, Mention } from 'react-mentions';
import PropTypes from "prop-types";
import Flex from "../Flex";
import {
  NewComment,
} from "../Comment";
import { getUserData } from "../../utils/helpers/userData";
import {
  CommentFooter,
  Button,
  Loader,
  renderIcon,
  CancelButton,
  Instructions,
} from "./styled";
import CommitImage from './CommitImage';
import '../../GlobalStyle/mention.scss';

const ShareCommit = ({
  commitImageSrc,
  placeholder,
  loading,
  onSubmit,
  showOffTitle,
  organizationMembers,
  path,
  buttonName,
  icon,
  onCancel,
  crop,
  onImageLoaded,
  onCropComplete,
  onCropChange,
  inputShareMessage,
  croppedImageData,
  author: {
    avatarUrl: authorAvatar = "",
    login: authorLogin = "",
    id: authorId = "",
  } = {},
}) => {
  const initialState = {
    suggestions: [],
    editorValue: '',
    croppedImageSource: null,
    enableShareButton: false,
  };

  const reducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const [state, setState] = useReducer(reducer, initialState);
  const {
    suggestions, editorValue, enableShareButton,
  } = state;

  useEffect(() => {
    const isButtonEnabled = croppedImageData !== null && editorValue.length > 0;
    setState({
      enableShareButton: isButtonEnabled,
      croppedImageSource: croppedImageData,
    });
  }, [croppedImageData, editorValue.length]);

  useEffect(() => {
    setState({ suggestions: organizationMembers });
  }, [organizationMembers]);

  const { photo, id: loggedId = "" } = getUserData() || {};

  const newCommentProps = {
    avatar: authorAvatar,
    login: authorLogin,
    isCommentAuthor: loggedId.toString() === authorId.toString(),
  };

  const renderSuggestion = (suggestion,
    search,
    highlightedDisplay,
    index,
    focused) => {
    return (
      <div className={`user ${focused ? 'focused' : ''}`}>
        {highlightedDisplay}
      </div>
    );
  };

  const handleFocus = () => {
    inputShareMessage.current.classList.add("mention-comment-col__input-focus");
  };

  const handleBlur = () => {
    if (editorValue.length === 0) {
      inputShareMessage.current.classList.remove('mention-comment-col__input-focus');
    }
  };

  const handleChange = (e) => {
    const isButtonEnabled = croppedImageData !== null && e.target.value.length > 0;
    setState({
      editorValue: e.target.value,
      enableShareButton: isButtonEnabled,
    });
  };

  return (
    <Flex column width="100%">
      <Flex width="100%" position="relative" column="column">
        <CommitImage
          commitImageSrc={commitImageSrc}
          showOffTitle={showOffTitle}
          crop={crop}
          onImageLoaded={onImageLoaded}
          onCropComplete={onCropComplete}
          onCropChange={onCropChange}
        />
        <Flex width="100%" margin="18px 0 0">
          <NewComment
            {...newCommentProps}
            avatar={photo}
            className="ignore-click"
          />
          <Flex column width="100%">
            <Flex width="100%" position="relative">
              <MentionsInput
                placeholder={placeholder}
                className="mention-comment-col"
                value={editorValue}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                inputRef={inputShareMessage}
              >
                <Mention
                  className="rc-editor-text"
                  displayTransform={display => `@${display}`}
                  trigger="@"
                  data={suggestions}
                  renderSuggestion={renderSuggestion}
                />
              </MentionsInput>
            </Flex>
            <CommentFooter>
              <Flex align="center">
                <Button onClick={onSubmit} disabled={!enableShareButton} className={`${buttonName}-${path}`}>
                  {renderIcon(icon)}
                  Share
                </Button>
                {loading && <Loader />}
                <CancelButton onClick={onCancel}>Cancel</CancelButton>
              </Flex>
              <Instructions>**bold1** _italics_ `code` ```performatted``` &gt;quote</Instructions>
            </CommentFooter>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

ShareCommit.propTypes = {
  icon: PropTypes.object,
  croppedImageData: PropTypes.string,
  commitImageSrc: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showOffTitle: PropTypes.string.isRequired,
  organizationMembers: PropTypes.array,
  path: PropTypes.string,
  buttonName: PropTypes.string,
  onCancel: PropTypes.func,
  crop: PropTypes.object,
  onImageLoaded: PropTypes.func,
  onCropComplete: PropTypes.func,
  onCropChange: PropTypes.func,
  inputShareMessage: PropTypes.object,
  author: PropTypes.shape({
    avatarUrl: PropTypes.string,
    login: PropTypes.string,
  }),
};

export default ShareCommit;
