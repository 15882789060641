import styled from "styled-components/macro";

export default styled.button`
  background: ${({ theme }) => theme.palette.primaryBg};
  align-self: flex-end;
  border-radius: 6px;
  padding: 12px 15px;

  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.mainText};

  :hover {
    color: ${({ theme }) => theme.palette.primaryText};
  }

  white-space: pre;
`;
