import {
  CLEAR_PROJECTS_DATA,
  DAILY, NEED_HELP, UPDATE_DAILY, UPDATE_NEED_HELP,
} from "./constants";

export const initialState = {
  daily: [],
  needHelp: [],
};

const removeDuplicateProjects = (projects, property) => {
  const uniqueIds = new Set();
  const project = projects.filter((item) => {
    const isDuplicate = uniqueIds.has(item[property]);
    uniqueIds.add(item[property]);

    return !isDuplicate;
  });

  return project;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DAILY: {
      return {
        ...state,
        daily: removeDuplicateProjects([...state.daily, ...action.payload], 'id'),
      };
    }

    case NEED_HELP: {
      return {
        ...state,
        needHelp: removeDuplicateProjects([...state.needHelp, ...action.payload], 'id'),
      };
    }

    case UPDATE_DAILY: {
      return {
        ...state,
        daily: action.payload,
      };
    }

    case UPDATE_NEED_HELP: {
      return {
        ...state,
        needHelp: action.payload,
      };
    }

    case CLEAR_PROJECTS_DATA: {
      return {
        ...state,
        needHelp: [],
        daily: [],
      };
    }

    default:
      return state;
  }
};
