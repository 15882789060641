import styled from "styled-components/macro";

export default styled.p`
  font-size: 14px;
  line-height: 22px;
  margin-right: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primaryText};
  & a {
    font-weight: normal;
    color: ${({ theme }) => theme.palette.mainText};
    margin-left: 10px;
  }
  & a:hover {
    color: ${({ theme }) => theme.palette.primaryText};
  }
`;
