import React from 'react';
import { PropTypes } from 'prop-types';
import { DataTable } from "../../containers/DataTable";
import GuidelineCategories from './GuidelineCategories';

const Table = ({
  guidelineList,
  tableHeadColumns,
  openDropDown,
  categoryId,
  guidelineCategoriesList,
  guidelineCategoriesNames,
  onChange,
  inputValue,
  setInputValue,
}) => {
  const rows = [];
  const save = ({ target: { value, name } }, id) => {
    if (inputValue !== null && inputValue !== value) {
      onChange(id, { [name]: value });
    }
    setInputValue(null);
  };

  const onChangeInput = (value) => {
    if (inputValue === null) {
      setInputValue(value || '');
    }
  };

  const onPressEnter = ({ target: { value, name }, which }, id) => {
    if (which === 13) {
      save({ target: { value, name } }, id);
    }
  };

  if (!guidelineList || !guidelineList.length) {
    rows.push({
      label: { value: "No record found.", colSpan: 3 },
    });
  } else {
    guidelineList.forEach((item) => {
      rows.push({
        label: {
          value: (
            <input
              name="label"
              key={item.id}
              className="input"
              type="text"
              defaultValue={item.label}
              onChange={() => onChangeInput(item.label)}
              onBlur={e => save(e, item.id)}
              onKeyPress={e => onPressEnter(e, item.id)}
            />
          ),
        },
        link: {
          value: (
            <input
              name="link"
              key={item.id}
              className="input link"
              type="text"
              defaultValue={item.link}
              onChange={() => onChangeInput(item.link)}
              onBlur={e => save(e, item.id)}
              onKeyPress={e => onPressEnter(e, item.id)}
            />
          ),
        },
        category: {
          value: (
            <GuidelineCategories
              open={categoryId === item.id}
              openDropDown={() => openDropDown(item.id)}
              onSelectCategory={({ value }) => onChange(item.id, { codingGuidelineCategoryId: value })}
              guidelineCategoriesList={guidelineCategoriesList}
              label={(item.codingGuidelineCategory && guidelineCategoriesNames[item.codingGuidelineCategory.id]) || "Select Category"}
            />
          ),
        },
      });
    });
  }

  return (
    <DataTable
      rows={rows}
      columns={tableHeadColumns}
    />
  );
};

Table.propTypes = {
  guidelineList: PropTypes.array.isRequired,
  guidelineCategoriesList: PropTypes.array.isRequired,
  guidelineCategoriesNames: PropTypes.object.isRequired,
  tableHeadColumns: PropTypes.array.isRequired,
  openDropDown: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setInputValue: PropTypes.func.isRequired,
  categoryId: PropTypes.number,
  inputValue: PropTypes.string,
};


export default Table;
