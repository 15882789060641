import styled from "styled-components/macro";

export default styled.div`
  min-width: 180px;
  width: auto;
  padding: 14px;
  font-size: 14px;
  position: fixed;
  right: 30px;
  background: ${({ theme }) => theme.palette.brandColor};
  color: #FFFFFF;
  border-radius: 6px;
  z-index: 9;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
`;
