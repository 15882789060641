import styled from "styled-components/macro";
import { MEDIA } from "../../ThemeControl";

export default styled.div`
  position: fixed;
  z-index: 9;
  display: flex;
  width: 100%;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  padding-left: ${({ theme }) => `${theme.navbar.width}px`};
  border-bottom: 1px solid ${({ theme }) => theme.palette.topPanelBorder};
  background: ${({ theme }) => theme.palette.bodyBg};
  ${({ theme }) => theme.mixins.dropShadow};
  min-width: ${MEDIA.SM};
`;
