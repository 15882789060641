import styled from "styled-components/macro";

export default styled.h3`
  display: block;
  height: 48px;
  margin: 4px 0;
  overflow: hidden;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  word-wrap: break-word;
  color: ${({ theme }) => theme.palette.primaryText};
  padding-right: 20px;
`;
