import React from "react";
import PropTypes from "prop-types";
import { CommentBlock } from "../../../containers/CommentBlock";
import { ActionBlock } from "../../ActionBlock";
import { withTheme, PALETTES } from "../../../containers/ThemeControl";
import { CommentIconGreen, CommentIconGreenWhite } from "../../../images";
import { Container } from "./styled";

const NewComment = ({
  position,
  path,
  fetchCommitDetail,
  committerId,
  setUnSaveComments,
  unSaveComments,
  theme: { activePalette },
}) => (
  <Container className="ignore-click" isVisible>
    <ActionBlock
      type="COMMENT"
      headerTxt="Add a comment:"
      icon={activePalette === PALETTES.DEFAULT ? CommentIconGreen : CommentIconGreenWhite}
    >
      <CommentBlock
        type="NEW"
        committerId={committerId}
        fetchCommitDetail={fetchCommitDetail}
        path={path}
        position={position}
        autoFocus
        setUnSaveComments={setUnSaveComments}
        unSaveComments={unSaveComments}
      />
    </ActionBlock>
  </Container>
);

NewComment.propTypes = {
  position: PropTypes.number,
  path: PropTypes.string,
  fetchCommitDetail: PropTypes.func,
  committerId: PropTypes.number.isRequired,
  setUnSaveComments: PropTypes.func,
  unSaveComments: PropTypes.array,
  theme: PropTypes.object.isRequired,
};

export default withTheme(NewComment);
