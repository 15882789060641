import styled from "styled-components/macro";

export default styled.button`
  width: 21px;
  height: 21px;
  border-radius: 2px;
  visibility: hidden;
  color: white;
  font-size: 18px;
  background: ${({ theme }) => theme.palette.brandColor};
`;
