/* eslint-disable no-param-reassign, no-useless-escape, no-loop-func, no-cond-assign, react-hooks/exhaustive-deps, react/jsx-closing-tag-location */
import React from "react";
import PropTypes from "prop-types";
import { TrelloIcon } from "../../../images";
import { RenderIcon } from "../../CommitTile/styled";
import { Title } from "./styled";

const regex = /\bhttps?:\/\/\S+/gi;

const CommitMessage = ({
  source: commitMessage,
  trelloCardTitle,
}) => {
  const ExtractLinksFromText = () => {
    if (new RegExp("https?:\/\/(www\.)?([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(commitMessage)) {
      let m;
      const NewMessage = [];
      while ((m = regex.exec(commitMessage)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex += 1;
        }

        m.forEach((match) => {
          const content = commitMessage.slice(0, m.index);
          commitMessage = commitMessage.replace(content, '').replace(match, '');
          NewMessage.push(content);
          NewMessage.push(<a className="trelloLink" key={`icon-${match}`} href={match} target="_blank" rel="noopener noreferrer">
            {RenderIcon(TrelloIcon)}
            {' '}
            {trelloCardTitle}
          </a>);
          m = {};
          regex.lastIndex = 0;
        });
      }
      return NewMessage;
    } else {
      return commitMessage;
    }
  };

  return (
    <Title><ExtractLinksFromText /></Title>
  );
};

CommitMessage.propTypes = {
  source: PropTypes.string.isRequired,
  trelloCardTitle: PropTypes.string,
};

export default CommitMessage;
