import styled, { css } from "styled-components/macro";

export default styled.div`
  position: absolute;
  top: -8px;
  left: -8px;
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  border-radius: 8px;
  z-index: 2;

  transition: opacity .2s;

  visibility: hidden;
  opacity: 1;

  ${({ visible }) => visible && css`
    visibility: visible;
    opacity: 1;
    background: ${({ theme }) => theme.palette.deleteOverlayBg};
  `}
`;
