import { connect } from "react-redux";
import { openPopup, closePopup } from "./actions";

const mapStateToProps = ({ Popup }) => ({ Popup });

const mapDispatchToProps = dispatch => ({
  openPopup: payload => dispatch(openPopup(payload)),
  closePopup: () => dispatch(closePopup()),
});

export default Component => connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
