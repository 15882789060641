import {
  SET_CATEGORY_FILTER,
  SET_USER_FILTER,
  SET_TECHNOLOGY_FILTER,
  SET_REPOSITORY_FILTER,
  SET_REPO_ID,
  RESET_FILTERS,
  SET_PAGE,
  SET_STATUS,
  SET_KANBAN_BOARD_CARD_FILTER,
} from "./constants";

export const setCategoryFilter = payload => ({
  type: SET_CATEGORY_FILTER,
  payload,
});

export const setUserFilter = payload => ({
  type: SET_USER_FILTER,
  payload,
});

export const setTechnologyFilter = payload => ({
  type: SET_TECHNOLOGY_FILTER,
  payload,
});

export const setRepositoryFilter = payload => ({
  type: SET_REPOSITORY_FILTER,
  payload,
});

export const setRepoId = payload => ({
  type: SET_REPO_ID,
  payload,
});

export const resetFilters = () => ({
  type: RESET_FILTERS,
});

export const setPage = payload => ({
  type: SET_PAGE,
  payload,
});

export const setStatus = payload => ({
  type: SET_STATUS,
  payload,
});

export const setKanbanBoardCardFilter = payload => ({
  type: SET_KANBAN_BOARD_CARD_FILTER,
  payload,
});
