import React from "react";
import PropTypes from "prop-types";
import Flex from "../Flex";
import { Form, Input, Textarea } from "./styled";

const UpdateForm = ({
  onChange,
  inputTitle,
  inputDescription,
}) => {
  return (
    <Form>
      <Flex column="column" width="100%">
        <Input
          type="text"
          placeholder="title"
          onChange={e => onChange('titleBody', e.target.value)}
          value={inputTitle}
        />
        <Textarea
          value={inputDescription}
          onChange={e => onChange('descriptionBody', e.target.value)}
          placeholder="Description"
          cols="40"
          rows="5"
        />
      </Flex>
    </Form>
  );
};

UpdateForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputDescription: PropTypes.string,
  inputTitle: PropTypes.string,
};

export default UpdateForm;
