import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getGuidelineCategories } from '../api';

const useGetCategories = () => {
  const { guidelineCategoriesList, guidelineCategoriesNames } = useSelector(store => store.guideline);
  useEffect(() => {
    getGuidelineCategories();
  }, []);

  return { guidelineCategoriesList, guidelineCategoriesNames };
};

export default useGetCategories;
