import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import queryString from 'query-string';
import ContentWrapper from "../../components/ContentWrapper";
import { withOrgs } from "../withOrgs";
import { ROUTES } from "../Routes";
import { PathHeader } from "../../components/PathHeader";
import { Loader } from "../../components/Loader";
import { ProjectsView, ProjectsLoading } from "../../components/Projects";
import { renderIcon } from "../../components/ActionBlock/styled";
import { SuccessMsg } from "../ManageProjects/styled";
import {
  DailyProjectIcon, NeedHelpIcon,
} from "../../images";
import { getUserData } from "../../utils/helpers/userData";
import { TitleBar } from './styled';
import { getProjects, saveRepositoryState } from './api';
import {
  setDaily, setNeedHelp, updateDaily, updateNeedHelp, clearProjectsData,
} from "./actions";
import { resetUserFilter } from "../Commits/actions";

const Projects = ({
  orgs: {
    active: { id: orgId, imported },
  },
}) => {
  const [state, setState] = useState({
    loading: true,
    offset: 0,
    hasMore: true,
    updateFlag: false,
  });
  const projectsData = useSelector(store => store.categorizedProjects);
  const dispatchProjectsData = useDispatch();
  const dispatchUserFilter = useDispatch();
  const perPage = 40;
  const {
    loading,
    offset,
    hasMore,
    updateFlag,
  } = state;

  const { showHiddenRepositories = false } = getUserData() || {};
  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    dispatchUserFilter(resetUserFilter());
    dispatchProjectsData(clearProjectsData());
    if (localStorage.getItem('isRepoNotFound')) {
      setErrorMsg('Repository Access Denied!');
      localStorage.removeItem('isRepoNotFound');
    }
    if (localStorage.getItem("categoryFilterLabel")) {
      localStorage.removeItem("categoryFilterLabel");
      localStorage.removeItem("categoryFilterValue");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setState(prevState => ({ ...prevState, loading: true }));
    (async () => {
      try {
        const response = await getProjects(showHiddenRepositories, perPage, offset);
        dispatchProjectsData(setDaily(response.dailyProjects));
        dispatchProjectsData(setNeedHelp(response.needHelpProjects));
        if (
          response.dailyProjects.length % perPage !== 0
          || response.needHelpProjects.length % perPage !== 0
        ) {
          setState(prevState => ({ ...prevState, hasMore: false }));
        }
      } catch (error) {
        console.error(error);
        setState(prevState => ({ ...prevState, loading: false }));
      } finally {
        setState(prevState => ({ ...prevState, loading: false }));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, perPage, offset, showHiddenRepositories]);

  const handleScroll = useCallback(async () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const { scrollHeight } = document.documentElement;
    const { clientHeight } = document.documentElement;
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight - 100;

    if (scrolledToBottom && !loading && hasMore) {
      setState(prevState => ({ ...prevState, loading: true }));

      // Disable scroll
      document.body.style.overflow = 'hidden';

      try {
        const response = await getProjects(
          showHiddenRepositories,
          perPage,
          offset + 1
        );
        dispatchProjectsData(setDaily(response.dailyProjects));
        dispatchProjectsData(setNeedHelp(response.needHelpProjects));
        setState(prevState => ({ ...prevState, offset: prevState.offset + 1 }));
      } catch (error) {
        console.error(error);
      } finally {
        setState(prevState => ({ ...prevState, loading: false }));

        // Enable scroll
        document.body.style.overflow = 'auto';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, hasMore, offset, perPage, showHiddenRepositories]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const generateLink = ({ fullName, name, id }) => {
    const search = queryString.stringify({
      repoId: id,
      repoName: name,
      repoFullName: fullName,
    });
    return { pathname: ROUTES.REPO, search };
  };

  const handleHideRepositoryEvent = async (
    repositoryId,
    payload,
    projectType
  ) => {
    setState(prevState => ({ ...prevState, loading: true }));
    const response = await saveRepositoryState(repositoryId, payload);
    if (response) {
      let projects = [];
      if (projectType === 'daily') {
        if (showHiddenRepositories) {
          projects = projectsData.daily;
          const index = projects.findIndex(e => e.id === repositoryId);
          const project = projects.find(e => e.id === repositoryId);
          project.repostate = payload.isArchive;
          projects[index] = project;
        } else {
          projects = projectsData.daily.filter(e => e.id !== repositoryId);
        }
        dispatchProjectsData(updateDaily(projects));
        setState(prevState => ({ ...prevState, updateFlag: true, loading: false }));
      } else {
        if (showHiddenRepositories) {
          projects = projectsData.needHelp;
          const index = projects.findIndex(e => e.id === repositoryId);
          const project = projects.find(e => e.id === repositoryId);
          project.repostate = payload.isArchive;
          projects[index] = project;
        } else {
          projects = projectsData.needHelp.filter(e => e.id !== repositoryId);
        }
        dispatchProjectsData(updateNeedHelp(projects));
        setState(prevState => ({ ...prevState, updateFlag: true, loading: false }));
      }
      setTimeout(() => {
        setState(prevState => ({ ...prevState, updateFlag: false }));
      }, 5000);
    }
  };

  return (
    <ContentWrapper padding="30px 16px 40px 24px">
      {updateFlag && (<SuccessMsg>Changes Saved</SuccessMsg>)}
      {errorMsg && (<SuccessMsg>{errorMsg}</SuccessMsg>)}
      <PathHeader pathItems={[{ label: "Projects", link: ROUTES.PROJECTS }]} margin="0 0 20px 16px" />
      {imported
        ? (
          <div>
            {projectsData.daily.length > 0
              && (
                <div>
                  <TitleBar>
                    {renderIcon(DailyProjectIcon)}
                    Daily
                  </TitleBar>
                  <ProjectsView
                    onChange={handleHideRepositoryEvent}
                    loading={loading}
                    projects={projectsData.daily}
                    generateLink={generateLink}
                    projectType="daily"
                  />
                </div>
              )}
            {projectsData.needHelp.length > 0
              && (
                <div>
                  <TitleBar>
                    {renderIcon(NeedHelpIcon)}
                    Need help
                  </TitleBar>
                  <ProjectsView
                    onChange={handleHideRepositoryEvent}
                    loading={loading}
                    projects={projectsData.needHelp}
                    generateLink={generateLink}
                    projectType="needHelp"
                  />
                </div>
              )}
            {loading && <Loader loading={loading} />}
          </div>
        )
        : <ProjectsLoading />
      }
    </ContentWrapper>
  );
};

Projects.propTypes = {
  orgs: PropTypes.object.isRequired,
};

export default withOrgs(Projects);
