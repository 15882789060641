import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getTechnology } from '../api';

const useGetTechnologies = () => {
  const { technologyList, technologyNames } = useSelector(store => store.projects);
  useEffect(() => {
    getTechnology();
  }, []);

  return { technologyList, technologyNames };
};

export default useGetTechnologies;
