import styled from "styled-components/macro";
import palettes from "../../ThemeControl/themes/palettes";
const {
  DEFAULT: {
    primaryText, bodyBg,
  },
} = palettes;

export default styled.div`
  min-width: 180px;
  width: auto;
  height: 42px;
  font-size: 14px;
  color: ${primaryText};
  border-radius: 6px;
  border: 2px solid ${bodyBg};
  z-index: 1;
`;
