import { axios, API_URL } from "../../utils/api";

export const postComment = async (uuid, data) => {
  await axios.post(`${API_URL}/commit/${uuid}/comment`, data);
};

export const editComment = async (commentId, data) => {
  await axios.put(`${API_URL}/commit/comment/${commentId}`, data);
};

export const deleteComment = async (uuid, commentId) => {
  await axios.delete(`${API_URL}/commit/${uuid}/comment/${commentId}`);
};
