import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getGuidelines } from '../api';

const useGetGuidelines = () => {
  const { guidelineList, totalCount, filters } = useSelector(store => store.guideline);
  const {
    page, order, sort, codingGuidelineCategoryId,
  } = filters;
  useEffect(() => {
    getGuidelines(filters);
  }, [filters, page, order, sort, codingGuidelineCategoryId]);

  return { guidelineList, totalCount };
};

export default useGetGuidelines;
