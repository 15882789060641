import React from "react";
import PropTypes from "prop-types";
import Flex from "../../Flex";
import { Anchor, ArchiveIcon } from './styled';


const HideProject = ({
  repositoryId, repostate, projectType, Icon, onChange,
}) => (
  <Flex column width="100%" align="center">
    <Anchor onClick={() => onChange(repositoryId, { isArchive: !repostate }, projectType)}>
      {ArchiveIcon(Icon)}
      {!repostate ? 'Hide' : 'Show'}
    </Anchor>
  </Flex>
);

HideProject.propTypes = {
  repositoryId: PropTypes.number.isRequired,
  projectType: PropTypes.string.isRequired,
  repostate: PropTypes.bool.isRequired,
  Icon: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default HideProject;
