import {
  SET_GUIDELINE_INITIAL_STATE, SET_GUIDELINE_LIST, SET_GUIDELINE_ERROR, SET_GUIDELINE_FILTERS, SET_GUIDELINE_CATEGORIES, SET_GUIDELINE_LOADER,
} from "./constants";

export const setGuidelineList = payload => ({
  type: SET_GUIDELINE_LIST,
  payload,
});

export const setApiError = error => ({
  type: SET_GUIDELINE_ERROR,
  error,
});

export const setFilters = filters => ({
  type: SET_GUIDELINE_FILTERS,
  filters,
});

export const setGuidelineCategoriesList = payload => ({
  type: SET_GUIDELINE_CATEGORIES,
  payload,
});

export const setLoader = flag => ({
  type: SET_GUIDELINE_LOADER,
  flag,
});

export const setInitialState = payload => ({
  type: SET_GUIDELINE_INITIAL_STATE,
  payload,
});
