/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import PropTypes from "prop-types";
import { SettingsContainer } from "./styled";

const UserName = ({ userName }) => (
  <SettingsContainer>
    Hi,
    {' '}
    <span>{userName}</span>
  </SettingsContainer>
);

UserName.propTypes = {
  userName: PropTypes.string.isRequired,
};

export default UserName;
