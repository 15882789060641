import React from "react";
import PropTypes from "prop-types";
import { Container, Icon, Label } from "./styled";

const NavSwitch = ({ label, toggleNav, navCollapsed }) => (
  <Container onClick={toggleNav}>
    <Icon className={navCollapsed ? "collapsed" : "expanded"} />
    <Label>{label}</Label>
  </Container>
);

NavSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  navCollapsed: PropTypes.bool.isRequired,
  toggleNav: PropTypes.func.isRequired,
};

export default NavSwitch;
