import styled from "styled-components/macro";
import ContentBlock from "../../../components/ContentBlock";

export default styled(ContentBlock)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.codeHeaderText};
  background: ${({ theme }) => theme.palette.codeHeaderBg};
  box-shadow: none;
`;
