import styled from "styled-components/macro";

export default styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.primaryText};
  margin-bottom: 2px;
`;
