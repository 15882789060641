import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Flex from "../../components/Flex";
import { typeMap } from "./constants";
import { Container, Header, renderIcon } from "./styled";

const CodeLineTab = ({
  children,
  headerText,
  isExpandForced,
  type = "COMMENT",
}) => {
  const [expanded, setExpanded] = useState(true);
  const toggleExpanded = () => setExpanded(prevState => !prevState);

  useEffect(() => {
    if (isExpandForced) setExpanded(true);
  }, [isExpandForced]);

  return (
    <Container>
      <Flex justify="space-between">
        <Header onClick={toggleExpanded} type={type}>
          <Flex>
            {renderIcon(typeMap[type].icon)}
            <p>{headerText}</p>
          </Flex>
          <p>{expanded ? "hide" : "show"}</p>
        </Header>
      </Flex>
      {expanded && children}
    </Container>
  );
};

CodeLineTab.propTypes = {
  children: PropTypes.element.isRequired,
  headerText: PropTypes.string.isRequired,
  isExpandForced: PropTypes.bool,
  type: PropTypes.string,
};

export default CodeLineTab;
