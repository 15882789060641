import styled from "styled-components/macro";

export default styled.div`
  position: absolute;
  width: 100%;
  top: 70px;
  background: ${({ theme }) => theme.palette.primaryBg};
  border: 1px solid ${({ theme }) => theme.palette.dropdownBorder};
  border-top: none;
  border-radius: 0 0 8px 8px;
`;
