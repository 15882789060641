import React, { useState } from "react";
import PropTypes from "prop-types";
import { Table } from "./styled";
import THead from "./THead";
import TBody from "./TBody";

const DataTable = ({
  columns = [],
  rows = [],
  onChangeSort = () => {},
  defaultSort = {},
  ...rest
}) => {
  const [state, setState] = useState(defaultSort);
  const onClickColumn = (column) => {
    const order = (state.column !== column || state.order === 'desc') ? 'asc' : 'desc';
    onChangeSort({ column, order });
    setState({ order, column });
  };

  return (
    <Table {...rest}>
      <THead columns={columns} onClickColumn={onClickColumn} state={state} />
      <TBody columns={columns} rows={rows} />
    </Table>
  );
};

DataTable.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  defaultSort: PropTypes.object,
  onChangeSort: PropTypes.func,
};

export default DataTable;
