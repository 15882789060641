import React from "react";
import PropTypes from "prop-types";
import Loader from "./SpinnerLoader";
import { NoContent } from "./styled";

const Loading = ({
  loading,
  height = "100%",
  size = "medium",
  failed = false,
  failedTxt = "no content",
  children,
  forceLightTheme,
}) => {
  switch (true) {
    case loading:
      return <Loader height={height} forceLightTheme={forceLightTheme} size={size} />;

    case failed:
      return <NoContent height={height} forceLightTheme={forceLightTheme}>{failedTxt}</NoContent>;

    default:
      return children;
  }
};

Loading.propTypes = {
  height: PropTypes.string,
  size: PropTypes.string,
  forceLightTheme: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  failed: PropTypes.bool,
  children: PropTypes.PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  failedTxt: PropTypes.string,
};

export default Loading;
