import React from "react";
import PropTypes from "prop-types";
import { Icon, Button } from "./styled";

export const DropdownButton = ({ onClick, isOpen }) => (
  <Button onClick={onClick} isOpen={isOpen}>
    <Icon />
  </Button>
);

DropdownButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default DropdownButton;
