import React from "react";
import PropTypes from "prop-types";
import { Button } from "./styled";

const Btn = ({
  Icon,
  label,
  type,
  onClick,
}) => (
  <Button
    type={type}
    onClick={onClick}
  >
    {!!Icon && <Icon />}
    {label}
  </Button>
);

Btn.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  Icon: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};

export default Btn;
