import styled from "styled-components/macro";

export default styled.a`
  display: block;
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.mainText};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.brandColor};
  }
  margin: ${({ margin }) => margin || "auto"};
  padding: ${({ padding }) => padding || 0};
`;
