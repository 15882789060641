import Cookies from "js-cookie";
import { getUserData } from "../helpers/userData";
import { getActiveOrgId, getActiveOrgUserRole } from "../helpers/organizationData";

export const getAuthHeaders = () => {
  const { id } = getUserData() || {};
  const token = Cookies.get("token") || "";
  return {
    "x-access-token": token,
    "x-key": id,
    "organization-id": getActiveOrgId(),
    role: getActiveOrgUserRole(),
  };
};
