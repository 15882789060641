export const SET_CATEGORY_FILTER = "src/containers/Commits/SET_CATEGORY_FILTER";
export const SET_USER_FILTER = "src/containers/Commits/SET_USER_FILTER";

export const SET_TECHNOLOGY_FILTER = "src/containers/Commits/SET_TECHNOLOGY_FILTER";
export const SET_REPOSITORY_FILTER = "src/containers/Commits/SET_REPOSITORY_FILTER";

export const SET_REPO_ID = "src/containers/Commits/SET_REPO_ID";
export const RESET_FILTERS = "src/containers/Commits/RESET_FILTERS";
export const RESET_USER_FILTER = "src/containers/Commits/RESET_USER_FILTER";
export const RESET_COMMITS_FILTER = "src/containers/Commits/RESET_COMMITS_FILTER";
export const SET_PAGE = "src/containers/Commits/SET_PAGE";
export const SET_STATUS = "src/containers/Commits/SET_STATUS";
export const SET_KANBAN_BOARD_CARD_FILTER = "src/containers/Commits/SET_KANBAN_BOARD_CARD_FILTER";
export const PER_PAGE_LIMIT = 20;
