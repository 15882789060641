import { isEmpty } from "lodash";
import { authDispatch } from "../../containers/AuthUser";

export const USER_DATA = "userData";

export const setUserData = (userData) => {
  localStorage.setItem([USER_DATA], JSON.stringify(userData));
};

export const getUserData = () => {
  const userData = JSON.parse(localStorage.getItem([USER_DATA])) || {};
  if (isEmpty(userData)) authDispatch(false);
  return userData;
};
