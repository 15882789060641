import React from "react";
import styled from "styled-components";

export default (icon) => {
  const Styled = styled(icon)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    height: 100%;
    transition: width 0.2s, height 0.2s;

    :hover {
      width: 150%;
      height: 150%;
    }
  `;

  return <Styled />;
};
