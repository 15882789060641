import React from "react";
import PropTypes from "prop-types";
import { NavLink, Label, renderIcon } from "./styled";

const Link = ({
  icon,
  label,
  to,
  classToggle,
  ...props
}) => {
  return (
    <li>
      <NavLink
        className={classToggle}
        to={to}
        {...props}
      >
        {renderIcon(icon)}
        <Label className={classToggle}>{label}</Label>
      </NavLink>
    </li>
  );
};

Link.propTypes = {
  icon: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  classToggle: PropTypes.string.isRequired,
};

export default Link;
