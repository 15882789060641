import styled, { css } from "styled-components/macro";
import { TRANSITION_DURATION } from "../constants";
import fadeAnimation from "./fadeAnimation";

const visible = css`
  opacity: 1;
  transform: translateX(-50%) scale(1);
`;

const invisible = css`
  opacity: 0;
  transform: translateX(-50%) scale(0.8);
`;

export default styled.div`
  position: fixed;

  transform: translateX(-50%);
  left: 50%;
  top: 250px;

  transition: all ${TRANSITION_DURATION}ms;

  /* always on top */
  z-index: 3;

  ${fadeAnimation(visible, invisible)}
`;
