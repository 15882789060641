import styled from "styled-components/macro";

export default styled.div`
  margin-right: 20px;
  color: ${({ theme }) => theme.palette.primaryText};
  display: flex;
  gap: 12px;
  
  & > div:nth-child(2) {
    border-radius: 6px;
    padding: 10px 12px;
  }

  & .react-daterange-picker__wrapper {
    border-radius: 6px;
    padding: 7px 12px;
    margin:0;
    background: ${({ theme }) => theme.palette.dropdownBg};
    border:0;
  }

  & .react-calendar {
    background: ${({ theme }) => theme.palette.primaryBg};
  }

  & .react-calendar__navigation__arrow:hover {
    background: ${({ theme }) => theme.palette.brandColor};
  }

  & .react-calendar__navigation__arrow:hover {
    background: ${({ theme }) => theme.palette.brandColor};
  }

  & .react-calendar__navigation button:enabled:hover, 
  &.react-calendar__navigation button:enabled:focus {
    background-color: ${({ theme }) => theme.palette.brandColor};
  }
  & .react-calendar button {
    padding:13px 12px;
  }
  & .react-calendar__tile--now {
    background-color: ${({ theme }) => theme.palette.brandColor};
    color:  ${({ theme }) => theme.palette.textWhite};
  }
  & .react-calendar__tile--now:enabled:hover {
    background-color: ${({ theme }) => theme.palette.brandColor};
    color:  ${({ theme }) => theme.palette.textWhite};
  }
  & .react-daterange-picker__calendar--open .react-calendar {
    border:0;
    box-shadow:${({ theme }) => theme.palette.calenderShadow};
  }
  & .react-calendar button:enabled:hover {
    background: ${({ theme }) => theme.palette.dropdownBg};
    color:  ${({ theme }) => theme.palette.textWhite};
  }
  & .react-calendar__tile--active {
    background-color: ${({ theme }) => theme.palette.brandColor};
    color:  ${({ theme }) => theme.palette.textWhite};
  }
  & .react-calendar__navigation button:enabled:hover, 
  & .react-calendar__navigation button:enabled:focus {
     background-color: ${({ theme }) => theme.palette.brandColor};
  }
  & .react-calendar__tile--hasActive {
    background-color: ${({ theme }) => theme.palette.brandColor};
    color:  ${({ theme }) => theme.palette.textWhite};
  }
  & .react-calendar__tile:enabled:hover, 
  & .react-calendar__tile:enabled:focus,
  & .react-calendar button:enabled:hover {
    background-color: ${({ theme }) => theme.palette.brandColor};
    color:  ${({ theme }) => theme.palette.textWhite};
  }
  & .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: ${({ theme }) => theme.palette.brandColor};
    color:  ${({ theme }) => theme.palette.textWhite};
  }
  & .react-daterange-picker__wrapper {
    height:40px;
  }
  & .react-daterange-picker__button svg {
    stroke: ${({ theme }) => theme.palette.iconColor};
  }
  & .react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon, 
  & .react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon {
    stroke: ${({ theme }) => theme.palette.iconHoverStatae};
  }
  & .react-daterange-picker__inputGroup__input:invalid {
    background: none;
  }
`;
