export const ENV = {
  DEV: 'DEV',
  UAT: 'UAT',
  PROD: 'PROD',
};

export const API_URLS = {
  [ENV.DEV]: "https://uat-reviewee-api.herokuapp.com",
  [ENV.UAT]: "https://uat-reviewee-api.herokuapp.com",
  [ENV.PROD]: "https://api.reviewee.it",
};

export const ORIGINS = {
  [ENV.DEV]: [
    'localhost',
    '192.168',
  ],
  [ENV.UAT]: [
    'uat-reviewee-react',
  ],
  [ENV.PROD]: [
    'production-reviewee-react',
    'app.reviewee.it',
  ],
};
