import React from "react";
import PropTypes from "prop-types";
import Flex from "../../Flex";
import { Status, ParentLink } from "../../CommitTile/styled";
import { formatStatus } from "../../../containers/Commits/api";
import { Sha } from "../../../containers/CommitDetail/styled";
import {
  Avatar,
  TitleText,
  Container,
} from "./styled";

const TitleBar = ({
  sha,
  avatarUrl,
  name,
  date,
  status,
  reviewedBy,
  copyShaToClipboard,
  parentCommits,
  openParentCommitLink,
}) => {
  return (
    <Flex justify="space-between" flex="1">
      <Flex column flex="3" padding="0 16px 0 0">
        <Container>
          <Avatar src={avatarUrl} />
          <TitleText>{`${name} committed ${date}`}</TitleText>
          {(status || status === 0) && (<Status status={status}>{ formatStatus({ status, reviewer: reviewedBy }) }</Status>)}
          {parentCommits.length > 0
            && parentCommits.map(item => (
              <ParentLink key={item.shortSha} onClick={() => openParentCommitLink(item)} title={item.message}>
                {item.shortSha}
              </ParentLink>
            ))}
        </Container>
      </Flex>
      <Flex column flex="1" padding="0 0 0 16px">
        <Sha onClick={() => copyShaToClipboard(sha.substring(0, 7))}>{`sha: ${sha.substring(0, 7)}`}</Sha>
      </Flex>
    </Flex>

  );
};
TitleBar.propTypes = {
  sha: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  copyShaToClipboard: PropTypes.func.isRequired,
  status: PropTypes.number.isRequired,
  openParentCommitLink: PropTypes.func.isRequired,
  parentCommits: PropTypes.array,
  reviewedBy: PropTypes.string,
};

TitleBar.defaultProps = {
  parentCommits: [],
  reviewedBy: "",
};

export default TitleBar;
