import React from "react";
import styled from "styled-components";

export default (icon) => {
  const Styled = styled(icon)`
    width: 20px;
    height: 20px;
    margin-right: 8px;
  `;

  return <Styled />;
};
