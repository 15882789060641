import { axios, API_URL } from "../../utils/api";
import { getUserData } from "../../utils/helpers/userData";

export const getNotifications = async (params) => {
  try {
    const response = await axios.get(`${API_URL}/notification?${params}`);
    const { data: { data = [], meta: { totalPages = 0 } } = {} } = response || {};

    if (response.data.success) {
      return { ...data, totalPages };
    }

    throw new Error("cannot get notifications!!");
  } catch (error) {
    console.error("Notification api: ", error);
    return false;
  }
};

export const seenNotifications = async (notificationLastSeen) => {
  const { id } = getUserData() || {};
  const data = await axios.put(`${API_URL}/member/${id}`, { notificationLastSeen });
  return data;
};
