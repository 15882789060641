import React from "react";
import PropTypes from "prop-types";
import { Container, LinkItem } from "./styled";

export const PathHeader = ({ pathItems = [], margin, padding }) => {
  const renderContent = () => {
    return pathItems.map(({ label = "", link = "#" } = {}, index) => {
      if (index === 0) {
        return (
          <span key={label}>
            <LinkItem exact to={link}>{label}</LinkItem>
          </span>
        );
      }

      return (
        <span key={label}>
          {" / "}
          <LinkItem exact to={link}>{label}</LinkItem>
        </span>
      );
    });
  };

  return (
    <Container margin={margin} padding={padding}>
      {renderContent()}
    </Container>
  );
};

PathHeader.propTypes = {
  pathItems: PropTypes.array,
  margin: PropTypes.string,
  padding: PropTypes.string,
};

export default PathHeader;
