import React, { useReducer } from "react";
import PropTypes from "prop-types";
import Flex from "../Flex";
import {
  UpdateHeading,
  Paragraph,
  RightArrowIcon,
  ReadMoreButton,
} from './styled';

const initialState = {
  isOpen: false,
  readMoreText: "Read more",
};
const reducer = (prevState, updatedProperty) => ({
  ...prevState,
  ...updatedProperty,
});


const Item = ({ title, description, description2 }) => {
  const [state, setState] = useReducer(reducer, initialState);
  const { isOpen, readMoreText } = state;

  const toggleExpandUpdate = () => {
    const readMoreButtonText = isOpen ? "Read Less" : "Read More";
    setState({
      isOpen: !isOpen,
      readMoreText: readMoreButtonText,
    });
  };

  return (
    <Flex className="update-post" column="column" padding="0 10px 0 0" margin="0 0 19px">
      <UpdateHeading>{title}</UpdateHeading>
      <Paragraph>
        {description}
        {description2 && <span>{isOpen ? description2 : '...'}</span>}
      </Paragraph>
      { description2 && (
        <ReadMoreButton onClick={toggleExpandUpdate}>
          {readMoreText}
          <RightArrowIcon />
        </ReadMoreButton>
      )}
    </Flex>
  );
};

Item.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  description2: PropTypes.string,
};

export default Item;
