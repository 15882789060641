import React from "react";
import PropTypes from "prop-types";
import { ListItem, Button, renderIcon } from "./styled";

export const Item = ({
  icon, onClick, buttonShare,
}) => (
  <ListItem>
    <Button ref={buttonShare} onClick={onClick}>
      {renderIcon(icon)}
    </Button>
  </ListItem>
);

Item.propTypes = {
  icon: PropTypes.object,
  onClick: PropTypes.func,
  buttonShare: PropTypes.object,
};
