/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Container } from './styled';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

export const Pagination = ({
  totalRecords,
  pageLimit,
  pageNeighbours,
  currentPage,
  onPageChanged,
}) => {
  const [totalPages, setTotalPages] = useState(Math.ceil(totalRecords / pageLimit));
  const [updatedPage, setUpdatedPage] = useState(currentPage);

  useEffect(() => {
    setTotalPages(Math.ceil(totalRecords / pageLimit));
  }, [totalRecords, pageLimit]);

  const range = (from, to, step = 1) => {
    let i = from;
    const rangeArray = [];

    while (i <= to) {
      rangeArray.push(i);
      i += step;
    }

    return rangeArray;
  };

  const fetchPageNumbers = () => {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = (pageNeighbours * 2) + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, updatedPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, updatedPage + pageNeighbours);

      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = (totalPages - endPage) > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case (hasLeftSpill && !hasRightSpill): {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case (!hasLeftSpill && hasRightSpill): {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case (hasLeftSpill && hasRightSpill):
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  const gotoPage = (page) => {
    const updatePage = Math.max(0, Math.min(page, totalPages));
    const paginationData = {
      currentPage: updatePage,
      totalPages,
      pageLimit,
      totalRecords,
    };

    setUpdatedPage(updatePage);
    onPageChanged(paginationData);
  };

  const handleClick = page => (event) => {
    event.preventDefault();
    gotoPage(page);
  };

  const handleMoveLeft = (event) => {
    event.preventDefault();
    gotoPage(updatedPage - 1);
  };

  const handleMoveRight = (event) => {
    event.preventDefault();
    gotoPage(updatedPage + 1);
  };

  const pages = fetchPageNumbers();

  if (!totalRecords || totalPages === 1) return null;

  return (
    <Container>
      <nav aria-label="Countries Pagination">
        <ul className="pagination">
          {pages.map((page, index) => {
            if (page === LEFT_PAGE) {
              return (
                <li key={index} className="page-item">
                  <a className="page-link" href="#" aria-label="Previous" onClick={handleMoveLeft}>
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only"> Previous</span>
                  </a>
                </li>
              );
            }

            if (page === RIGHT_PAGE) {
              return (
                <li key={index} className="page-item">
                  <a className="page-link" href="#" aria-label="Next" onClick={handleMoveRight}>
                    <span aria-hidden="true">&raquo;</span>
                    <span className="sr-only"> Next</span>
                  </a>
                </li>
              );
            }

            return (
              <li key={index} className={`page-item${updatedPage === page ? ' active' : ''}`}>
                <a className="page-link" href="#" onClick={handleClick(page)}>{page}</a>
              </li>
            );
          })}

        </ul>
      </nav>
    </Container>
  );
};

Pagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  pageNeighbours: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChanged: PropTypes.func,
};

export default Pagination;
