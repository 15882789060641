import { palettes, BREAKPOINTS as breakpoints } from "./themes";
export const PALETTES = Object.keys(palettes).reduce((acc, key) => ({ ...acc, [key]: key }), {});
export const BREAKPOINTS = Object.keys(breakpoints).reduce((acc, key) => ({ ...acc, [key]: key }), {});
export const NAVBAR_WIDTH = {
  LARGE: 248,
  SMALL: 96,
};


export const CHANGE_PALETTE = "src/containers/ThemeControl/themes/CHANGE_PALETTE";
export const SET_SCREEN_SIZE = "src/containers/ThemeControl/themes/SET_SCREEN_SIZE";
export const TOGGLE_NAVBAR_WIDTH = "src/containers/ThemeControl/themes/TOGGLE_NAVBAR_WIDTH";
