import styled from "styled-components/macro";

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  /* FIXME: placeholder color */
  background: ${({ theme }) => theme.palette.primaryBg};
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 3px 3px;
`;
