import styled from "styled-components/macro";

export default styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.palette.primaryText};
  margin: 15px 0px 10px 0px;
`;
