import styled from "styled-components/macro";

export default styled.div`
  background: ${({ theme }) => theme.palette.sidebarBg};
  color: white;
  max-width: 353px;
  width: 100%;
  position: absolute;
  top: 70px;
  font-weight: 500;
  right: 0;
  height: 100vh;
  padding: 17px 14px 17px 24px;
  border-left: 1px solid ${({ theme }) => theme.palette.sidebarBorder};
  & .custom-scroll > div:last-child > div {
    background-color: ${({ theme }) => theme.palette.brandColor} !important;
  }
`;
