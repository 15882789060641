import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from "../Dropdown";
import { DropdownTick } from "../../images";

const Technology = ({
  open, openTechnology, onSelectTechnology, technologyList, label,
}) => (
  <Dropdown
    className="technology-dropdown"
    label={label}
    Icon={DropdownTick}
    data={technologyList}
    handleDropdownClick={openTechnology}
    handleChoiceClick={val => onSelectTechnology(val)}
    isMenuOpen={open}
  />
);

Technology.propTypes = {
  open: PropTypes.bool,
  technologyList: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  openTechnology: PropTypes.func.isRequired,
  onSelectTechnology: PropTypes.func.isRequired,
};

export default Technology;
