import {
  SET_PROJECT_INITIAL_STATE, SET_PROJECT_LIST, SET_PROJECT_ERROR, SET_PROJECT_FILTERS, SET_PROJECT_TECHNOLOGY, UPDATE_PROJECT, SET_PROJECT_LOADER,
} from "./constants";

export const setProjectList = payload => ({
  type: SET_PROJECT_LIST,
  payload,
});

export const setProjectError = error => ({
  type: SET_PROJECT_ERROR,
  error,
});

export const setFilters = filters => ({
  type: SET_PROJECT_FILTERS,
  filters,
});

export const setTechnologyList = payload => ({
  type: SET_PROJECT_TECHNOLOGY,
  payload,
});

export const updateProject = payload => ({
  type: UPDATE_PROJECT,
  payload,
});

export const setLoader = flag => ({
  type: SET_PROJECT_LOADER,
  flag,
});

export const setInitialState = payload => ({
  type: SET_PROJECT_INITIAL_STATE,
  payload,
});
