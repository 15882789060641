import { SET_GUIDELINES } from "./constants";

export const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GUIDELINES:
      return action.payload;

    default: return state;
  }
};
