import React from "react";
import PropTypes from 'prop-types';
import { SuccessSymbol } from "./styled";

const Success = ({ size, color }) => {
  return (
    <SuccessSymbol color={color} size={size}>&#10004;</SuccessSymbol>
  );
};

Success.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

export default Success;
