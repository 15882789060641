import React from "react";
import PropTypes from "prop-types";
import Select from 'react-select';
import { Container } from "./styled";
import "./reactSelect.scss";

const KanbanDropdown = ({
  kanbanBoardCards,
  onChange,
  selectedOption,
}) => {
  return (
    <Container>
      <Select
        value={selectedOption}
        className="react-select-container"
        classNamePrefix="react-select"
        options={kanbanBoardCards}
        onChange={onChange}
      />
    </Container>
  );
};

KanbanDropdown.propTypes = {
  kanbanBoardCards: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedOption: PropTypes.object.isRequired,
};

export default KanbanDropdown;
