import styled from "styled-components/macro";

export default styled.div`
  position: relative;
  padding: 16px;
  border-radius: 0 8px 8px 8px;
  ${({ theme }) => theme.mixins.dropShadow};
  min-height: 190px;

  /* FIXME: placeholder color */
  background: ${({ theme }) => theme.palette.codeHeaderBg}
`;
