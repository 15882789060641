import moment from "moment";
import { ORIGINS, ENV } from './api/constants';

export const getEnv = (origin = window.location.origin) => Object
  .entries(ORIGINS)
  .reduce((acc, [key, array]) => array.some(url => origin.includes(url)) ? key : acc, ENV.DEV);

export const DEVELOPMENT_MODE = (getEnv() === ENV.DEV);

export const PRODUCTION_MODE = (getEnv() === ENV.PROD);

export const formatResponse = ({ data: { data = [] } = {} } = {}) => data;

export const formatResponseWithMeta = ({ data: { data = [], meta = {} } = {} } = {}) => ({ data, meta });

export const formatDate = date => moment(date).format('YYYY-MM-DD');
