import React, { useEffect, useReducer } from "react";
import { compose } from "redux";
import { UpdateSideBar } from "../../components/UpdateSideBar";
import { getUserData, setUserData } from "../../utils/helpers/userData";
import withFeatures from './withFeatures';
import { getFeatures, updateMember } from './api';

const initialState = {
  features: [],
  isOpen: false,
};
const reducer = (prevState, updatedProperty) => ({
  ...prevState,
  ...updatedProperty,
});

export const UpdatesSideBar = () => {
  const [state, setState] = useReducer(reducer, initialState);
  const { features, isOpen } = state;
  const userData = getUserData() || {};

  useEffect(() => {
    fetchLatestUpdates();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLatestUpdates = async () => {
    const response = await getFeatures(userData.updatesLastSeen);
    if (response.length > 0) {
      setState({ features: response, isOpen: true });
      const update = await updateMember(userData.id, { updatesLastSeen: response[0].createdAt });
      if (update) {
        userData.updatesLastSeen = response[0].createdAt;
        setUserData(userData);
      }
    }
  };

  const handleOnClose = () => {
    setState({ isOpen: false });
  };

  return (
    <div>
      { isOpen && <UpdateSideBar features={features} onClose={handleOnClose} />}
    </div>
  );
};

export default compose(withFeatures)(UpdatesSideBar);
