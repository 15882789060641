import styled from "styled-components/macro";

export default styled.p`
  display: flex;
  font-size: 14px;
  line-height: 16px;
  flex: 1;
  justify-content: center;
  color: ${({ theme }) => theme.palette.brandColor};
`;
