import React from 'react';
import ReactCrop from 'react-image-crop';
import PropTypes from 'prop-types';
import Flex from "../Flex";
import { withTheme, PALETTES } from "../../containers/ThemeControl";
import { H3 } from './styled';
import 'react-image-crop/dist/ReactCrop.css';

const CommitImage = ({
  commitImageSrc,
  showOffTitle,
  crop,
  onImageLoaded,
  onCropComplete,
  onCropChange,
  theme: { activePalette },
}) => {
  return (
    <Flex width="100%" column="column">
      <H3>{showOffTitle}</H3>
      { commitImageSrc && (
        <ReactCrop
          src={commitImageSrc}
          crop={crop}
          ruleOfThirds
          onImageLoaded={onImageLoaded}
          onComplete={onCropComplete}
          onChange={onCropChange}
          style={{ background: activePalette === PALETTES.DEFAULT ? "#151718" : "#FFF" }}
        />
      )}
    </Flex>
  );
};

CommitImage.propTypes = {
  commitImageSrc: PropTypes.string,
  showOffTitle: PropTypes.string.isRequired,
  crop: PropTypes.object,
  onImageLoaded: PropTypes.func,
  onCropComplete: PropTypes.func,
  onCropChange: PropTypes.func,
  theme: PropTypes.object.isRequired,
};

export default withTheme(CommitImage);
