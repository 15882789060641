import styled from "styled-components/macro";

export default styled.div`
  width: 89%;
  
  &.code-addition {
    background-color: ${({ theme }) => theme.palette.codeAdditionBg};
  }

  &.code-deletion {
    background-color: ${({ theme }) => theme.palette.codeDeletionBg};
  }
`;
