import React from 'react';
import PropTypes from "prop-types";
import { Route } from 'react-router-dom';
import { getActiveOrgUserRole } from "../../utils/helpers/organizationData";
import { NoAccess } from "../../components/NoAccess";
import { ROLES } from './constants';

const PrivateRoute = ({
  component: Component,
  role = ROLES.member,
  ...rest
}) => {
  const isAuthenticated = (!role || role.toLowerCase() === (getActiveOrgUserRole() || "").toLowerCase());
  return (
    <Route
      {...rest}
      render={props => isAuthenticated ? (
        <Component {...props} />
      ) : (
        <NoAccess />
      )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.func.isRequired,
  ]),
  role: PropTypes.string,
};

export default PrivateRoute;
