import styled from "styled-components/macro";

export default styled.div`
  display: flex;
  align-items: center;
  min-width: 224px;
  border-radius: 8px 8px 0 0;
  padding: 16px;
  background: ${({ type, theme }) => type === "SHARE" ? theme.palette.shareHeaderBg : theme.palette.commentHeaderBg};

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;
