import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import Flex from "../Flex";
import {
  TopHeading,
  SideBar,
  CloseIcon,
} from './styled';
import './styled/ScrollBar.scss';
import Item from './Item';

const UpdateSideBar = ({ features, onClose }) => {
  const [updates, setUpdates] = useState([]);

  useEffect(() => {
    setUpdates(features);
  }, [features]);

  return (
    <SideBar>
      <Flex column="column">
        <Flex margin="0 0 33px" justify="space-between" align="center" width="100%">
          <TopHeading>What’s new?</TopHeading>
          <CloseIcon onClick={onClose} />
        </Flex>
        <Scrollbars className="custom-scroll" autoHeight autoHeightMax={800}>
          {updates && updates.map(update => (
            <Item
              key={update.id}
              title={update.title}
              description={update.description}
              description2={update.description2}
            />
          ))}
        </Scrollbars>
      </Flex>
    </SideBar>
  );
};

UpdateSideBar.propTypes = {
  features: PropTypes.array,
  onClose: PropTypes.func,
};

export default UpdateSideBar;
