import styled from "styled-components/macro";

export default styled.span`
  color: ${({ theme }) => theme.palette.formText};
  margin-right: 12px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.primaryText};
  }
`;
