import React from "react";
import styled from "styled-components";

export default (icon) => {
  const Styled = styled(icon)`
    width: 14px;
    height: 14px;
    top: 2px;
    right: 2px;
    position: relative;
  `;

  return <Styled />;
};
