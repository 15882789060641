import styled from "styled-components/macro";

export default styled.div`
  color: ${({ theme }) => theme.palette.secondaryText};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: right;
  width: 12%;
`;
