/* eslint-disable max-len */
import styled from "styled-components/macro";

export default styled.span`
  font-family: "Fira Code", Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
  vertical-align: middle;
  width: 100%;

  & pre, code {
    white-space: pre-wrap;
    word-break: normal;
  }
`;
