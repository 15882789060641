/* eslint-disable react/no-array-index-key, react-hooks/exhaustive-deps */
import React, { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import InfiniteScroll from "react-infinite-scroller";

import { Popup, withPopup } from "../Popup";
import { Loader } from "../../components/Loader";
import ContentWrapper from "../../components/ContentWrapper";

import {
  getAllCommits,
  getTechnology,
} from "./api";
import CommitGroup from "./CommitGroup";
import withCommitsFilters from "./withCommitsFilters";
import checkRepoId from "./checkRepoId";
import checkCommitPage from "./checkPage";
import { PathHeader } from "../../components/PathHeader";
import { FilterBox } from "../Commits/styled";
import { Container } from "../../components/Dropdown/styled";
import { Dropdown } from "../../components/Dropdown";
import { DropdownTick } from "../../images";
import KanbanDropdown from "../Commits/KanbanDropdown";
import Flex from "../../components/Flex";
import { ROUTES } from "../Routes";
import checkCommitStatus from "../Commits/checkStatus";
import { getProjects } from "../Projects/api";
import { resetCommitsFilter } from "../Commits/actions";

const initialState = {
  commitList: [],
  loading: true,
  users: [],
  technologyList: [],
  repositoryList: [],
  kanbanBoardCards: [],
  page: 1,
  hasMoreCommits: true,
  isRepositoriesMenuOpen: false,
  isTechnologiesMenuOpen: false,
  lastCommitGroupDate: null,
  loadFilters: false,
  kanbanFlag: false,
  pageNumber: 1,
};

const Commits = ({
  location: { search },
  openPopup,
  setTechnologyFilter,
  setRepositoryFilter,
  setKanbanBoardCardFilter,
  commitListFilters: {
    repositoryFilter, cardFilter, technologyFilter,
  },
}) => {
  const reducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });
  const [state, setState] = useReducer(reducer, initialState);
  const {
    commitList,
    loading,
    technologyList,
    repositoryList,
    kanbanBoardCards,
    page,
    isRepositoriesMenuOpen,
    isTechnologiesMenuOpen,
    lastCommitGroupDate,
    loadFilters,
    kanbanFlag,
    hasMoreCommits,
  } = state;
  const dispatchCommitsFilter = useDispatch();

  const {
    repoId, repoName, repoFullName,
  } = queryString.parse(search);
  const toggleRepositoryMenu = () => {
    setState({ isRepositoriesMenuOpen: !isRepositoriesMenuOpen });
  };
  const handleCategoriesFilterChange = (value) => {
    checkCommitStatus(value.value);
    setState({
      commitList: [],
      page: 1,
      loading: true,
      loadFilters: true,
      lastCommitGroupDate: null,
      hasMoreCommits: true,
    });
    setRepositoryFilter(value);
  };
  const handleCardFilterChange = (value) => {
    setState({
      commitList: [],
      page: 1,
      loading: true,
      loadFilters: true,
      lastCommitGroupDate: null,
      hasMoreCommits: true,
    });
    setKanbanBoardCardFilter(value);
  };
  const handleTechnologyFilterChange = (value) => {
    setState({
      commitList: [],
      page: 1,
      loading: true,
      loadFilters: true,
      lastCommitGroupDate: null,
      hasMoreCommits: true,
    });
    setTechnologyFilter(value);
  };
  const toggleTechnologiesMenu = () => {
    setState({ isTechnologiesMenuOpen: !isTechnologiesMenuOpen });
  };
  useEffect(() => {
    dispatchCommitsFilter(resetCommitsFilter());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    localStorage.setItem('previousPage', window.location.pathname);
  }, []);


  useEffect(() => {
    checkRepoId(repoId);
    (async () => {
      try {
        const [technologies, repositories] = await Promise.all([
          getTechnology(),
          getProjects(false, 100, 0),
        ]);
        setState({
          technologyList: [{ label: 'All Stacks', value: 0 }].concat(technologies.map(({ name, id }) => {
            return {
              label: name,
              value: id,
            };
          })),
          repositoryList: [{ label: 'All Repositories', value: 0 }].concat(repositories.dailyProjects.map(({ name, id }) => {
            return {
              label: name,
              value: id,
            };
          })),
        });
      } catch (error) {
        console.error(error);
      }
    })();
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [repoId, repoFullName]);

  const handleOutsideClick = (event) => {
    if (![...event.target.classList].includes('users-dropdown')) {
      setState({ isTechnologiesMenuOpen: false });
    }

    if (![...event.target.classList].includes('categories-dropdown')) {
      setState({ isRepositoriesMenuOpen: false });
    }
  };

  useEffect(() => {
    (async () => {
      await loadAllCommits(
        technologyFilter.value,
        repositoryFilter.value,
        page,
      );
    })();
  }, [repositoryFilter, technologyFilter, cardFilter]);


  const loadAllCommits = async (
    technologyFilterValue = technologyFilter.value,
    repositoryFilterValue = repositoryFilter.value,
    commitPage = page,
  ) => {
    setState({ loadFilters: false });
    try {
      const {
        commitGroups: response,
        totalPages,
      } = await getAllCommits(
        technologyFilterValue,
        repositoryFilterValue,
        commitPage,
      );
      let previousCommitGroupDate = "";
      if (commitPage === totalPages) {
        setState({ hasMoreCommits: false });
      }
      if (response.length > 0) {
        previousCommitGroupDate = response[response.length - 1].date;
        if (lastCommitGroupDate === response[0].date) {
          response[0].date = "";
        }
      }
      checkCommitPage(page);
      setState({
        commitList: commitList.concat(response),
        page: commitPage + 1,
        lastCommitGroupDate: previousCommitGroupDate,
        loading: false,
      });
    } catch (error) {
      console.error(error);
      setState({
        commitList: [],
        loading: false,
        page: 1,
      });
    }
  };


  const loadMore = (num) => {
    if (num < 2 && !loadFilters) {
      loadAllCommits();
    }
  };

  return (
    <ContentWrapper>
      <Popup />
      <Flex justify="space-between">
        <PathHeader pathItems={[{ label: "Commits", link: ROUTES.ALLCOMMITS }]} margin="0 0 20px 16px" />
        <FilterBox>
          <Container>
            <Dropdown
              inputLabel="Filter by:"
              className="categories-dropdown"
              label={repositoryFilter.label}
              Icon={DropdownTick}
              data={repositoryList}
              handleDropdownClick={toggleRepositoryMenu}
              handleChoiceClick={handleCategoriesFilterChange}
              isMenuOpen={isRepositoriesMenuOpen}
            />
            <Dropdown
              className="users-dropdown"
              label={technologyFilter.label}
              Icon={DropdownTick}
              data={technologyList}
              handleDropdownClick={toggleTechnologiesMenu}
              handleChoiceClick={handleTechnologyFilterChange}
              isMenuOpen={isTechnologiesMenuOpen}
            />
          </Container>
          {kanbanFlag && (
            <KanbanDropdown
              kanbanBoardCards={kanbanBoardCards}
              label="Select trello card"
              onChange={handleCardFilterChange}
              selectedOption={cardFilter}
            />
          )}
        </FilterBox>
      </Flex>

      <Loader key="loader1" loading={loading} failed={!loading && !commitList.length} height="80%">
        {commitList && commitList.map((commitGroupData, index) => (
          <CommitGroup
            {...commitGroupData}
            repoName={repoName}
            repoFullName={repoFullName}
            repoId={repoId}
            key={index}
            openPopup={openPopup}
          />
        ))}
      </Loader>
      <InfiniteScroll
        key={page}
        pageStart={0}
        loadMore={loadMore}
        hasMore={hasMoreCommits}
        loader={(!loading && commitList.length) ? <Loader key="loader2" loading={!loading} /> : ""}
        useWindow={true}
      >
        <></>
      </InfiniteScroll>
    </ContentWrapper>
  );
};

Commits.propTypes = {
  location: PropTypes.object.isRequired,
  openPopup: PropTypes.func.isRequired,
  setTechnologyFilter: PropTypes.func.isRequired,
  setRepositoryFilter: PropTypes.func.isRequired,
  setKanbanBoardCardFilter: PropTypes.func.isRequired,
  commitListFilters: PropTypes.shape({
    userFilter: PropTypes.object.isRequired,
    technologyFilter: PropTypes.object.isRequired,
    repositoryFilter: PropTypes.object.isRequired,
    cardFilter: PropTypes.object.isRequired,
  }).isRequired,
};

export default compose(withPopup, withCommitsFilters)(Commits);
