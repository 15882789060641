import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

export default styled(NavLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  height: 48px;
  padding: 0 16px;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.palette.dropdownBorder};
  color: ${({ theme }) => theme.palette.primaryText};

  :hover {
    background: ${({ theme }) => theme.palette.dropdownHover};
  }

  :first-child {
    border-top: none;
  }

  :last-child {
    border-radius: 0 0 8px 8px;
  }
`;
