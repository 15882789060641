import { createGlobalStyle } from "styled-components";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import reset from "./reset";

export default createGlobalStyle`
  ${reset};
  code {
    font-family:
      "Fira Code",
      Consolas,
      "Andale Mono WT",
      "Andale Mono",
      "Lucida Console",
      "Lucida Sans Typewriter",
      "DejaVu Sans Mono",
      "Bitstream Vera Sans Mono",
      "Liberation Mono",
      "Nimbus Mono L",
      Monaco,
      "Courier New",
      Courier,
      monospace;
  }

  * {
    box-sizing: border-box;
    outline: none;
  }

  body {
    background: ${({ theme }) => theme.palette.bodyBg};
    font-family:
      Ubuntu,
      -apple-system,
      BlinkMacSystemFont,
      “Roboto”,
      “Droid Sans”,
      “Helvetica Neue”,
      Helvetica,
      Arial,
      sans-serif;
  }

  button,
  [type="button"],
  [type="submit"] {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    color: inherit;
    font: inherit;

    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;
