import React from "react";
import { createRoot } from 'react-dom/client';
import { Router } from "react-router-dom";
import HttpsRedirect from 'react-https-redirect';
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";

import { ThemeProvider } from "./containers/ThemeControl";
import { store } from "./store";
import { AuthUser } from "./containers/AuthUser";
import history from "./utils/history";

import GlobalStyle from "./GlobalStyle";
import * as serviceWorker from "./serviceWorker";

const ROOT_NODE = document.getElementById("root");

const root = createRoot(ROOT_NODE);
root.render(
  <CookiesProvider>
    <Router history={history}>
      <Provider store={store}>
        <ThemeProvider>
          <HttpsRedirect>
            <GlobalStyle />
            <AuthUser />
          </HttpsRedirect>
        </ThemeProvider>
      </Provider>
    </Router>
  </CookiesProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
