import styled from "styled-components/macro";

export default styled.input`
  background: ${({ theme }) => theme.palette.formControlBg};
  padding: 16px;
  border: none;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  /* White */
  color: ${({ theme }) => theme.palette.primaryText};;
`;
