// ProjectsView.js
import React from "react";
import PropTypes from "prop-types";
import { Loader } from "../../Loader";
import { ProjectTile } from "../ProjectTile";
import { ProjectTileList } from "./styled";

const ProjectsView = ({
  projects,
  generateLink,
  onChange,
  projectType,
  isLoadingMore,
}) => {
  return (
    <ProjectTileList>
      {projects.map(projectData => (
        <ProjectTile
          {...projectData}
          onChange={onChange}
          key={projectData.id}
          linkTo={generateLink(projectData)}
          projectType={projectType}
        />
      ))}
      {isLoadingMore && <Loader />}
    </ProjectTileList>
  );
};


ProjectsView.propTypes = {
  projects: PropTypes.array.isRequired,
  generateLink: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  projectType: PropTypes.string.isRequired,
  isLoadingMore: PropTypes.bool,
};

export default ProjectsView;
