import React from "react";
import styled from "styled-components/macro";

export default (icon) => {
  const Styled = styled(icon)`
    height: 20px;
    width: 20px;
    margin-right: 16px;
  `;

  return <Styled />;
};
