import React from "react";
import { Container } from "./styled";
const LastActionsContainer = () => {
  return (
    <Container padding="0px">
      Last Actions
    </Container>
  );
};

export default LastActionsContainer;
