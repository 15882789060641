import React from 'react';
import PropTypes from 'prop-types';
import Countdown, { formatTimeDelta } from 'react-countdown';
import moment from 'moment';
import Timer from './styled/Timer';

const CountDown = ({ dateTime }) => {
  if (!dateTime) {
    return '';
  }

  let expireDateTime = moment(dateTime).add(48, 'hours');
  const day = expireDateTime.format('ddd');
  if (day === 'Sun' || day === 'Sat') {
    expireDateTime = moment(dateTime).add(96, 'hours');
  }

  const renderer = ({
    days,
    hours,
    minutes,
    completed,
  }) => {
    const formatedTime = formatTimeDelta({
      hours, minutes, days,
    }, {
      daysInHours: true, zeroPadTime: 2,
    });

    if (completed) {
      return '';
    } else {
      // eslint-disable-next-line react/jsx-one-expression-per-line
      return <Timer title="System Reject pending time">{formatedTime.hours}:{formatedTime.minutes}</Timer>;
    }
  };

  return (
    <Countdown
      intervalDelay={60000}
      date={expireDateTime.valueOf()}
      renderer={renderer}
    />
  );
};

CountDown.propTypes = {
  dateTime: PropTypes.string.isRequired,
};

export default CountDown;
