import React from "react";
import PropTypes from "prop-types";
import { Container } from "./styled";

const Options = ({ children }) => (
  <Container className="ignore-click">
    { children }
  </Container>
);

Options.propTypes = {
  children: PropTypes.any,
};

export default Options;
