import styled from "styled-components/macro";

export default styled.div`
  width: 100%;
  border-radius: 2px;
  border: 1px solid red;
  background: #f44336;
  boxSizing: border-box;
  color: white;
  fontSize: 14px;
  padding: 15px 10px;
  margin: 0px 0px 30px;
  position: relative;
  & .closeIcon {
    position: absolute;
    max-width: 16px;
    width: 100%;
    right: 20px;
    cursor: pointer;
    & svg path {
      stroke: white;
    }
  };
`;
