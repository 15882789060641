export const DATA_KEY = "organizationData";

export const setOrganizationData = (organizationData) => {
  localStorage.setItem([DATA_KEY], JSON.stringify(organizationData[0] || {}));
};

export const getOrganizationData = () => {
  const userData = JSON.parse(localStorage.getItem([DATA_KEY])) || {};
  return userData;
};

export const getActiveOrgId = () => {
  const activeOrgId = localStorage.getItem("activeOrgId");
  if (activeOrgId) {
    return activeOrgId;
  }

  const userData = JSON.parse(localStorage.getItem([DATA_KEY])) || {};
  return userData.id || null;
};

export const getActiveOrgUserRole = () => {
  const activeOrgRole = localStorage.getItem("activeOrgRole");
  if (activeOrgRole) {
    return activeOrgRole;
  }

  const userData = JSON.parse(localStorage.getItem([DATA_KEY])) || {};
  return userData.role || 'member';
};
