import React, { useReducer, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { compose } from "redux";
import Flex from "../../components/Flex";
import ContentWrapper from "../../components/ContentWrapper";
import { PathHeader } from "../../components/PathHeader";
import { ROUTES } from "../Routes";
import { Container } from "../../components/Dropdown/styled";
import { Loader } from "../../components/Loader";
import { NewUpdate } from "../../components/UpdateTile";
import { getUserData } from "../../utils/helpers/userData";
import { withPopup, Popup } from "../Popup";
import {
  Button,
} from "./styled";
import {
  getFeatures, saveFeature, updateFeature, deleteFeature,
} from "./api";
import UpdateGroup from "./UpdateGroup";
import {
  UPDATE_DISMISS_MESSAGE,
  NEW_UPDATE_TITLE,
  EDIT_UPDATE_TITLE,
  UPDATE_DISMISS_TITLE,
  UPDATE_DELETE_TITLE,
  UPDATE_DELETE_MESSAGE,
} from "./constants";
const actions = {
  Edit: "edit",
  Delete: "delete",
  Add: "add",
};

const Updates = ({ openPopup }) => {
  const iconContainer = useRef(null);
  const pathLinks = [
    {
      label: "Updates",
      link: ROUTES.UPDATES,
    },
  ];
  const initialState = {
    loading: true,
    progress: false,
    featureGroups: [],
    openNewUpdate: false,
    showActionPopup: false,
    titleBody: "",
    descriptionBody: "",
    enableSubmitButton: false,
    popupTitle: "",
    updateId: null,
    actionPopupTitle: UPDATE_DISMISS_TITLE,
    actionPopupMessage: UPDATE_DISMISS_MESSAGE,
    actionType: "",
  };
  const reducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });
  const [state, setState] = useReducer(reducer, initialState);
  const {
    loading,
    featureGroups,
    openNewUpdate,
    showActionPopup,
    titleBody,
    descriptionBody,
    enableSubmitButton,
    progress,
    popupTitle,
    updateId,
    actionPopupMessage,
    actionPopupTitle,
    actionType,
  } = state;

  const {
    id: loggedId = "", photo: authorAvatar = "", name: authorName = "",
  } = getUserData() || {};

  useEffect(() => {
    (async () => {
      const response = await getFeatures();
      setState({ featureGroups: response, loading: false });
    })();
  }, []);

  const handleMouseInOut = (value, featureId, authorId) => {
    const element = document.getElementsByClassName(`iconContainer-${featureId}`)[0];
    const visibility = value && loggedId === authorId.toString() ? "visible" : "hidden";
    element.style.visibility = visibility;
  };

  const handleNewUpdateClick = (isOpen) => {
    setState({
      openNewUpdate: isOpen,
      titleBody: "",
      descriptionBody: "",
      popupTitle: NEW_UPDATE_TITLE,
      updateId: null,
      actionType: actions.Add,
    });
  };

  const handleOnCancel = (isClose) => {
    setState({
      showActionPopup: isClose,
      actionPopupTitle: UPDATE_DISMISS_TITLE,
      actionPopupMessage: UPDATE_DISMISS_MESSAGE,
    });
  };

  const handleOnChange = (text, value) => {
    const newTitle = text === 'titleBody' ? value : titleBody;
    const newDescription = text === 'descriptionBody' ? value : descriptionBody;
    const enableButton = newTitle.length > 0 && newDescription.length > 0;
    setState({ [text]: value, enableSubmitButton: enableButton });
  };

  const saveFeatureDetails = async () => {
    const updateGroups = featureGroups;
    const data = {
      title: titleBody,
      description: descriptionBody,
      authorId: loggedId,
    };
    try {
      const newRecord = await saveFeature(data);
      const postedAt = moment(new Date()).format("MMMM DD, YYYY, H:mm");
      if (updateGroups.length > 0) {
        const newUpdateMonth = new Date().toLocaleString('default', { month: 'long' });
        const newUpdateDetail = {
          id: newRecord.id,
          title: titleBody,
          description: descriptionBody,
          authorId: loggedId,
          postedAt,
          authorName,
          authorAvatar,
        };

        if (newUpdateMonth === updateGroups[0].date) {
          updateGroups[0].features.unshift(newUpdateDetail);
        } else {
          const newUpdateGroup = {
            date: newUpdateMonth,
            features: [newUpdateDetail],
          };
          updateGroups.unshift(newUpdateGroup);
        }
      }
      setState({
        progress: false,
        titleBody: "",
        descriptionBody: "",
        enableSubmitButton: false,
        openNewUpdate: false,
        featureGroups: updateGroups,
        actionType: "",
      });
      openPopup("Update added!!");
    } catch (error) {
      console.error(error);
    }
  };

  const updateFeatureDetails = async () => {
    const data = {
      title: titleBody,
      description: descriptionBody,
    };
    try {
      await updateFeature(updateId, data);
      const newUpdateGroups = featureGroups.map((featureGroup) => {
        return {
          date: featureGroup.date,
          features: featureGroup.features.map(feature => feature.id === updateId
            ? { ...feature, title: titleBody, description: descriptionBody }
            : feature),
        };
      });
      setState({
        progress: false,
        titleBody: "",
        descriptionBody: "",
        enableSubmitButton: false,
        openNewUpdate: false,
        featureGroups: newUpdateGroups,
        updateId: null,
        actionType: "",
      });
      openPopup("Feature updated!!");
    } catch (error) {
      console.error(error);
    }
  };

  const handlePublishUpdateClick = async () => {
    setState({ progress: true });
    if (updateId === null) {
      saveFeatureDetails();
    } else {
      updateFeatureDetails();
    }
  };

  const handleEditClick = (id, title, description) => {
    setState({
      titleBody: title,
      descriptionBody: description,
      enableSubmitButton: true,
      openNewUpdate: true,
      popupTitle: EDIT_UPDATE_TITLE,
      updateId: id,
      actionType: actions.Edit,
    });
  };

  const handleDeleteClick = (id) => {
    setState({
      updateId: id,
      showActionPopup: true,
      actionPopupTitle: UPDATE_DELETE_TITLE,
      actionPopupMessage: UPDATE_DELETE_MESSAGE,
      actionType: actions.Delete,
    });
  };

  const handleOnDismissUpdate = async () => {
    if (actionType === actions.Delete) {
      setState({
        showActionPopup: false,
        actionType: "",
      });
      const element = document.getElementsByClassName(`overlay-${updateId}`)[0];
      element.style.visibility = "visible";
      await deleteFeature(updateId);
      const newUpdateGroups = featureGroups.map((featureGroup) => {
        return {
          date: featureGroup.date,
          features: featureGroup.features.filter(feature => feature.id !== updateId),
        };
      });
      setState({
        featureGroups: newUpdateGroups,
      });
    }

    setState({
      showActionPopup: false,
      openNewUpdate: false,
      titleBody: "",
      descriptionBody: "",
      updateId: null,
    });
  };

  return (
    <ContentWrapper className="relative">
      <Flex justify="space-between">
        <PathHeader pathItems={pathLinks} />
        <Container>
          <Button onClick={() => handleNewUpdateClick(true)}>+ Add new update</Button>
        </Container>
      </Flex>
      <Loader loading={loading} failed={!loading && !featureGroups.length} height="80%">
        {featureGroups && featureGroups.map(featureGroup => (
          <UpdateGroup
            {...featureGroup}
            key={featureGroup.date}
            onMouseInOut={handleMouseInOut}
            iconContainer={iconContainer}
            onEdit={handleEditClick}
            onDelete={handleDeleteClick}
          />
        ))}
      </Loader>
      <NewUpdate
        openNewUpdate={openNewUpdate}
        progress={progress}
        onClose={handleNewUpdateClick}
        onSubmit={handlePublishUpdateClick}
        onCancel={handleOnCancel}
        showActionPopup={showActionPopup}
        onDismissUpdate={handleOnDismissUpdate}
        onChange={handleOnChange}
        enableSubmitButton={enableSubmitButton}
        inputTitle={titleBody}
        inputDescription={descriptionBody}
        popupTitle={popupTitle}
        actionPopupTitle={actionPopupTitle}
        actionPopupMessage={actionPopupMessage}
      />
      <Popup />
    </ContentWrapper>
  );
};

Updates.propTypes = {
  openPopup: PropTypes.func.isRequired,
};

export default compose(
  withPopup,
)(Updates);
