import styled from "styled-components/macro";

export default styled.textarea`
  background: ${({ theme }) => theme.palette.formControlBg};
  padding: 16px;
  width: 100%;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  font-family: Ubuntu, -apple-system, BlinkMacSystemFont, “Roboto”, “Droid Sans”, “Helvetica Neue”, Helvetica, Arial, sans-serif;

  /* White */
  color: ${({ theme }) => theme.palette.primaryText};;
`;
