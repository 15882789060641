import React from "react";
import styled from "styled-components";

export default (icon) => {
  const Styled = styled(icon)`
    width: 40px;
    height: 30px;
    top: 3px;
    left: 3px;
    position: relative;
  `;

  return <Styled />;
};
