import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getProjects } from '../api';

const useGetProjectRows = () => {
  const { projectList, totalCount, filters } = useSelector(store => store.projects);
  const { page, order, sort } = filters;
  useEffect(() => {
    getProjects(filters);
  }, [filters, page, order, sort]);

  return { projectList, totalCount };
};

export default useGetProjectRows;
