import styled from "styled-components/macro";

export default styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  padding: 0 80px 0 30px;
  &.collapsed {
    padding: 17.5px 24px;
  }
`;
