/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import { Icon } from "./styled";

const THead = ({
  columns = [],
  onClickColumn,
  className = "",
  state,
  ...restProps
}) => {
  const ASC_ICON = <Icon>&#9652;</Icon>;
  const DESC_ICON = <Icon>&#9662;</Icon>;
  const SORT_ICON = (
    <>
      <Icon>&#9652;</Icon>
      <Icon>&#9662;</Icon>
    </>
  );

  return (
    <thead {...restProps}>
      <tr>
        {
          columns.map(({
            key = '', title = '', sort = false, ...rest
          }) => (
            <th
              key={key}
              className={`${className} ${sort ? "sort" : ""} ${state.column === key ? "active" : ""}`}
              onClick={() => sort && onClickColumn(key)}
              {...rest}
            >
              <div className="icon-container">
                { title }
                { sort && (
                  <div className="sort-icons">
                    { state.column === key ? (state.order === 'asc' ? ASC_ICON : DESC_ICON) : SORT_ICON }
                  </div>
                )}
              </div>
            </th>
          ))
        }
      </tr>
    </thead>
  );
};

THead.propTypes = {
  columns: PropTypes.array,
  className: PropTypes.string,
  state: PropTypes.object.isRequired,
  onClickColumn: PropTypes.func.isRequired,
};

export default THead;
