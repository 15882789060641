import React from "react";
import PropTypes from "prop-types";
import { SHARE } from "../../../containers/CommitDetail/constants";
import { withTheme, PALETTES } from "../../../containers/ThemeControl";
import {
  CommentIconGreen, ShareIconBlack, CommentIconWhiteGreen, ShareIconGold,
} from "../../../images";
import { Container } from "./styled";
import { Item } from "./Item";

// TODO: refactor Context Menu into its own container
const ContextMenu = ({
  column,
  toggleActionBlock,
  actionType,
  buttonShare,
  theme: { activePalette },
}) => (
  <Container column={column} className="ignore-click">
    <Item
      icon={activePalette === PALETTES.DEFAULT ? CommentIconGreen : CommentIconWhiteGreen}
      onClick={() => toggleActionBlock(actionType.comment)}
      buttonShare={buttonShare}
    />
    <Item
      icon={activePalette === PALETTES.DEFAULT ? ShareIconBlack : ShareIconGold}
      onClick={() => toggleActionBlock(SHARE)}
      buttonShare={buttonShare}
    />
  </Container>
);

ContextMenu.propTypes = {
  column: PropTypes.bool,
  toggleActionBlock: PropTypes.func,
  actionType: PropTypes.object.isRequired,
  buttonShare: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

export default withTheme(ContextMenu);
