import { axios, API_URL } from "../../utils/api";

export const getFeatures = async (updatesLastSeen) => {
  try {
    const response = await axios.get(`${API_URL}/feature`, {
      params: {
        updatesLastSeen,
      },
    });
    const { data: { data = [] } = {} } = response || {};

    if (response.data.success) {
      return data;
    }

    throw new Error("cannot get features!!");
  } catch (error) {
    console.error("Update last scene api: ", error);
    return [];
  }
};

export const updateMember = async (id, data) => {
  const response = await axios.put(`${API_URL}/member/${id}`, data);

  return response.status === 204;
};
