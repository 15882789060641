import styled from "styled-components/macro";

export default styled.div`
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background: ${({ theme }) => theme.palette.mainText};
  z-index: 9;
  opacity: 0.8;
  height: 100vh;
`;
