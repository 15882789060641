import styled from "styled-components/macro";
import palettes from "../../../containers/ThemeControl/themes/palettes";
const {
  LIGHT: { primaryText },
} = palettes;

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${({ height }) => height || "100%"};

  font-size: 48px;
  color: ${({ forceLightTheme, theme }) => forceLightTheme ? primaryText : theme.palette.primaryText};
`;
