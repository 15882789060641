import React from "react";
import { Container, P } from "./styled";

export default () => {
  const content = ["Page not found", "(╯'□')╯︵ 404"];

  return (
    <Container>
      {content.map((item, index) => (
        <P key={index}>{item}</P> // eslint-disable-line react/no-array-index-key
      ))}
    </Container>
  );
};
