import styled from "styled-components/macro";

export default styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  line-height: 21px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.code};

  &.code-expandable {
    color: ${({ theme }) => theme.palette.codeTabline};
    background: ${({ theme }) => theme.palette.codeTablineBg};

    & code {
      padding: 0 8px;
    }
  }

  &:not(.code-expandable):hover {
    & .action-btn {
      visibility: visible;
    }
  }
`;
