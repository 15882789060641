import styled from "styled-components/macro";

export default styled.button`
  height: 20px;
  width: 20px;
  svg * {
    stroke: ${({ theme }) => theme.palette.secondaryText};
  }
  
  &:hover {
    svg * { stroke: ${({ theme }) => theme.palette.primaryText}; }
  }
`;
