import Styled from "styled-components/macro";
import ContentBlock from "../../../ContentBlock";

export default Styled(ContentBlock)`
  margin: 16px;
  height: 370px;
  width: 352px;
  top:208px;
  left: 1048.28px;
  color: white;  
  justify-content: space-between;
  border-radius: 8px;
  :hover {
    background: ${({ theme }) => theme.palette.primaryBgHover};
  }

  .switch {
    width: 35px;
    height: 16px;
  }
  .slider::before {
    height: 13px;
    width: 13px;
    left: 1px;
  }
`;
