import { AUTHORIZE_USER } from "./constants";

export const initialState = {
  isAuthorized: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTHORIZE_USER:
      return { ...state, isAuthorized: action.payload };

    default: return { ...state };
  }
};
