import { connect } from "react-redux";
import { setActiveOrg } from "./actions";

const mapStateToProps = ({ orgs }) => ({ orgs });

const mapDispatchToProps = dispatch => ({
  setActiveOrg: payload => dispatch(setActiveOrg(payload)),
});

export default Component => connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
