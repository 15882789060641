import queryString from "query-string";
import { axios, API_URL } from "../../utils/api";
import { getUserData } from "../../utils/helpers/userData";
import { ROUTES } from "../Routes";
import { PER_PAGE_LIMIT } from "./constants";

const generateCommitLink = ({
  sha, commitUuid: uuid,
}, repoName, repoFullName, repoId) => {
  const search = queryString.stringify({
    sha,
    repoName,
    repoFullName,
    uuid,
    repoId,
  });

  return { pathname: ROUTES.COMMIT, search };
};

const generateParentsLinks = ({ parentCommits = [] }, repoName, repoFullName, repoId) => {
  return parentCommits.map(({
    sha, uuid, shortSha,
  }) => {
    return {
      link: generateCommitLink({
        sha, commitUuid: uuid, shortSha,
      }, repoName, repoFullName, repoId),
      label: shortSha,
    };
  });
};

export const formatStatus = ({ status, reviewer }) => {
  switch (status) {
    case 0:
      return "waiting";
    case 1:
      return `accepted ${reviewer}`;
    case 2:
      return `rejected ${reviewer}`;
    case 3:
      return `passed ${reviewer}`;
    default:
      return "";
  }
};

export const formatResponseData = (data, repoName, repoFullName, repoId) => {
  return {
    formattedStatus: formatStatus(data),
    linkTo: generateCommitLink(data, repoName, repoFullName, repoId),
    parentsLinks: generateParentsLinks(data, repoName, repoFullName, repoId),
    ...data,
  };
};

export const formatUsersData = (data) => {
  const result = data.map(({ id: value, login: label }) => ({ label, value }));

  return [{ label: "All", value: "" }, ...result];
};

export const getCommits = async (
  repositoryId,
  status,
  MembersFilter,
  kanbanBoardCardFilter,
  page,
) => {
  const { id: teamMemberId = "" } = getUserData() || {};
  const responseData = await axios.get(`${API_URL}/repository/${repositoryId}/commits`, {
    params: {
      teamMemberId,
      status,
      MembersFilter,
      kanbanBoardCardFilter,
      sort: "desc",
      page,
      perPage: PER_PAGE_LIMIT,
    },
  });
  const { data: { data = [], meta: { totalPages = 0 } } = {} } = responseData;
  return { commitGroups: data, totalPages };
};

export const getContributors = async (repoFullName) => {
  const responseData = await axios.get(`${API_URL}/repository/${repoFullName}/contributors`);

  const { data: { data = [] } = {} } = responseData;
  return formatUsersData(data);
};

export const getKanbanBoardCards = async (repositoryId) => {
  const responseData = await axios.get(`${API_URL}/repository/${repositoryId}/kanbanBoardCards`);

  const { data: { data = [] } = {} } = responseData;
  return [{ label: "All", value: "" }, ...data];
};

export const getRepository = async (repositoryId) => {
  const responseData = await axios.get(`${API_URL}/repository/${repositoryId}`);

  const { data: { data = {} } = {} } = responseData;
  return data;
};
