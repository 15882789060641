import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withCookies } from "react-cookie";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { ProfileItems } from "../../components/ProfileItems";
import withAuth from "../AuthUser/withAuth";

const ProfileDropDown = ({
  setToggleOpen,
  cookies,
  setAuthorization,
}) => {
  const handleOutsideClick = (event) => {
    const path = event.path || (event.composedPath && event.composedPath()) || [];
    const check = [...path].some(({ classList = [] }) => {
      return [...classList].some((item) => {
        return item === "ignore-click";
      });
    });
    if (!check) setToggleOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogOutClick = () => {
    cookies.remove("token");
    setAuthorization(false);
  };

  return (
    <ProfileItems
      onSubmit={handleLogOutClick}
    />
  );
};

ProfileDropDown.propTypes = {
  setToggleOpen: PropTypes.func,
  cookies: PropTypes.object.isRequired,
  setAuthorization: PropTypes.func,
};

export default compose(
  withRouter,
  withCookies,
  withAuth,
)(ProfileDropDown);
