import styled from "styled-components/macro";

export default styled.td`
    position: absolute;
    z-index: 1;
    left: 0;
    margin: 22px 0 0 21px;
    width: 100%;
    visibility: ${({ isVisible }) => isVisible ? "visible" : "hidden"}
  `;
