/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { BellIcon } from "./styled";
export const SET_PROJECT_LIST = "src/containers/ManageProjects/SET_PROJECT_LIST";
export const SET_PROJECT_ERROR = "src/containers/ManageProjects/SET_PROJECT_ERROR";
export const SET_PROJECT_FILTERS = "src/containers/ManageProjects/SET_PROJECT_FILTERS";
export const SET_PROJECT_TECHNOLOGY = "src/containers/ManageProjects/SET_PROJECT_TECHNOLOGY";
export const UPDATE_PROJECT = "src/containers/ManageProjects/UPDATE_PROJECT";
export const SET_PROJECT_LOADER = "src/containers/ManageProjects/SET_PROJECT_LOADER";
export const SET_PROJECT_INITIAL_STATE = "src/containers/ManageProjects/SET_PROJECT_INITIAL_STATE";

export const PER_PAGE_LIMIT = 20;
export const PAGE_NEIGHBOURS = 3;
const slack = (<span><BellIcon />Slack</span>);
const email = (<span><BellIcon />Email</span>);

export const tableHeadColumns = [
  {
    key: 'name', title: 'Project name', width: '22%', sort: true,
  },
  {
    key: 'technologyId', title: 'Technology', width: '10%',
  },
  {
    key: 'channelName', title: 'Slack channel', width: '10%',
  },
  {
    key: 'slackNotificationsEnabled', title: slack, width: '9%', sort: true,
  },
  {
    key: 'emailNotificationEnabled', title: email, width: '9%', sort: true,
  },
  {
    key: 'enableKanbanCardFilter', title: 'Trello filter', width: '11%', sort: true,
  },
  {
    key: 'isArchived', title: 'Archive', width: '9%', sort: true,
  },
];
