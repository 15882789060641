import styled from "styled-components/macro";

export default styled.li`
  position: relative;
  width: 64px;
  height: 64px;
  :last-child {
    margin: 0;
  }
`;
