import nivedan from 'nivedan';
import { authDispatch } from "../../containers/AuthUser";
import { getAuthHeaders } from './headers';

const handleErros = (error) => {
  const { response: { status } = {} } = error || {};
  /* eslint-disable default-case */
  switch (status) {
    case 403:
      authDispatch(false);
      break;
    case 401:
      authDispatch(false);
      break;
  }
  /* eslint-enable default-case */

  return Promise.reject(error);
};

nivedan.middleware.request.use(
  (request) => {
    request.headers = getAuthHeaders();
    return request;
  },
  error => handleErros(error)
);

// global request handling
nivedan.middleware.response.use(
  response => response,
  error => handleErros(error)
);

export default nivedan;
