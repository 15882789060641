export const SET_GUIDELINE_LIST = "src/containers/GuideLines/SET_GUIDELINE_LIST";
export const SET_GUIDELINE_ERROR = "src/containers/GuideLines/SET_GUIDELINE_ERROR";
export const SET_GUIDELINE_FILTERS = "src/containers/GuideLines/SET_GUIDELINE_FILTERS";
export const SET_GUIDELINE_CATEGORIES = "src/containers/GuideLines/SET_GUIDELINE_CATEGORIES";
export const UPDATE_PROJECT = "src/containers/GuideLines/UPDATE_PROJECT";
export const SET_GUIDELINE_LOADER = "src/containers/GuideLines/SET_GUIDELINE_LOADER";
export const SET_GUIDELINE_INITIAL_STATE = "src/containers/GuideLines/SET_GUIDELINE_INITIAL_STATE";

export const PER_PAGE_LIMIT = 20;
export const PAGE_NEIGHBOURS = 3;

export const tableHeadColumns = [
  {
    key: 'category', title: 'Category', width: '15%',
  },
  {
    key: 'label', title: 'Lable', width: '25%',
  },
  {
    key: 'link', title: 'Link', width: '60%',
  },
];
