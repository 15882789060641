import styled from "styled-components/macro";

export default styled.div`
  color: ${({ theme }) => theme.palette.dropdownText};
  text-align: left;
  font-size: 14px;
  line-height: 16px;
  
  padding: 16px 33px 16px 16px;
  border: 1px solid ${({ theme }) => theme.palette.dropdownBorder};
  background: ${({ isActive, theme }) => isActive && theme.palette.dropdownHover};

  cursor: pointer;
  
  :hover {
    background: ${({ theme }) => theme.palette.dropdownHover};
  }
  
  :not(:first-child) {
    border-top: none;  
  }
  
  :last-child {
    border-radius: 0 0 8px 8px;
  }
`;
