import {
  SET_ORGS, SET_ACTIVE_ORG, SET_MEMBERS_ORG, SET_MEMBERS_INITIAL_STATE,
} from "./constants";

export const setOrganizations = payload => ({
  type: SET_ORGS,
  payload,
});

export const setActiveOrg = payload => ({
  type: SET_ACTIVE_ORG,
  payload,
});

export const setMemberList = payload => ({
  type: SET_MEMBERS_ORG,
  payload,
});

export const setInitialState = payload => ({
  type: SET_MEMBERS_INITIAL_STATE,
  payload,
});
