/* eslint-disable react/no-array-index-key, react-hooks/exhaustive-deps */
import React, { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import queryString from "query-string";
import InfiniteScroll from "react-infinite-scroller";
import Flex from "../../components/Flex";
import { ROUTES } from "../Routes";

import { Popup, withPopup } from "../Popup";
import { Loader } from "../../components/Loader";
import ContentWrapper from "../../components/ContentWrapper";
import { PathHeader } from "../../components/PathHeader";
import { Dropdown } from "../../components/Dropdown";
import { Container } from "../../components/Dropdown/styled";
import { DropdownTick } from "../../images";

import {
  getCommits,
  getContributors,
  getKanbanBoardCards,
  getRepository,
} from "./api";
import CommitGroup from "./CommitGroup";
import filterOptions from './filterOptions';
import withCommitsFilters from "./withCommitsFilters";
import checkRepoId from "./checkRepoId";
import checkCommitPage from "./checkPage";
import checkCommitStatus from "./checkStatus";
import KanbanDropdown from "./KanbanDropdown";
import { FilterBox } from "./styled";

const initialState = {
  commitList: [],
  loading: true,
  users: [],
  kanbanBoardCards: [],
  page: 1,
  hasMoreCommits: true,
  isCategoriesMenuOpen: false,
  isUsersMenuOpen: false,
  lastCommitGroupDate: null,
  loadFilters: false,
  kanbanFlag: false,
};

const Commits = ({
  location: { search },
  openPopup,
  setUserFilter,
  setCategoryFilter,
  setKanbanBoardCardFilter,
  commitListFilters: { userFilter, categoryFilter, cardFilter },
  history,
}) => {
  const reducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });
  const [state, setState] = useReducer(reducer, initialState);
  const {
    commitList,
    loading,
    users,
    kanbanBoardCards,
    page,
    isCategoriesMenuOpen,
    isUsersMenuOpen,
    lastCommitGroupDate,
    loadFilters,
    kanbanFlag,
    hasMoreCommits,
  } = state;

  const {
    repoId, repoName, repoFullName,
  } = queryString.parse(search);

  const pathLinks = [
    {
      label: "Projects",
      link: ROUTES.PROJECTS,
    },
    {
      label: repoName,
      link: {
        pathname: ROUTES.REPO,
        search: queryString.stringify({
          repoFullName, repoName, repoId,
        }),
      },
    },
  ];

  useEffect(() => {
    localStorage.setItem('previousPage', window.location.pathname);
  }, []);

  useEffect(() => {
    checkRepoId(repoId);
    (async () => {
      try {
        const [repository, contributors, cards] = await Promise.all([
          getRepository(repoId),
          getContributors(repoFullName),
          getKanbanBoardCards(repoId),
        ]);
        setState({
          kanbanFlag: repository.enableKanbanCardFilter,
          users: contributors,
          kanbanBoardCards: cards,
        });
      } catch (error) {
        if (error.message === 'Request failed with status code 404') {
          localStorage.setItem('isRepoNotFound', true);
          history.push(ROUTES.PROJECTS);
        }
      }
    })();
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [repoId, repoFullName]);

  const handleOutsideClick = (event) => {
    if (![...event.target.classList].includes('users-dropdown')) {
      setState({ isUsersMenuOpen: false });
    }

    if (![...event.target.classList].includes('categories-dropdown')) {
      setState({ isCategoriesMenuOpen: false });
    }
  };

  const toggleCategoriesMenu = () => {
    setState({ isCategoriesMenuOpen: !isCategoriesMenuOpen });
  };

  const toggleUsersMenu = () => {
    setState({ isUsersMenuOpen: !isUsersMenuOpen });
  };

  useEffect(() => {
    (async () => {
      await loadCommits(
        categoryFilter.value,
        userFilter.value,
        cardFilter.value,
        page,
      );
    })();
  }, [categoryFilter, userFilter, cardFilter]);

  const handleCategoriesFilterChange = (value) => {
    checkCommitStatus(value.value);
    setState({
      commitList: [],
      page: 1,
      loading: true,
      loadFilters: true,
      lastCommitGroupDate: null,
      hasMoreCommits: true,
    });
    setCategoryFilter(value);
  };

  const handleUsersFilterChange = (value) => {
    setState({
      commitList: [],
      page: 1,
      loading: true,
      loadFilters: true,
      lastCommitGroupDate: null,
      hasMoreCommits: true,
    });
    setUserFilter(value);
  };

  const handleCardFilterChange = (value) => {
    setState({
      commitList: [],
      page: 1,
      loading: true,
      loadFilters: true,
      lastCommitGroupDate: null,
      hasMoreCommits: true,
    });
    setKanbanBoardCardFilter(value);
  };

  const loadCommits = async (
    categoryFilterValue = categoryFilter.value,
    userFilterValue = userFilter.value,
    cardFilterValue = cardFilter.value,
    commitPage = page,
  ) => {
    setState({ loadFilters: false });
    try {
      const {
        commitGroups: response,
        totalPages,
      } = await getCommits(
        repoId,
        categoryFilterValue,
        userFilterValue,
        cardFilterValue,
        commitPage,
      );
      let previousCommitGroupDate = "";
      if (commitPage === totalPages) {
        setState({ hasMoreCommits: false });
      }
      if (response.length > 0) {
        previousCommitGroupDate = response[response.length - 1].date;
        if (lastCommitGroupDate === response[0].date) {
          response[0].date = "";
        }
      }
      checkCommitPage(page);
      setState({
        commitList: commitList.concat(response),
        page: commitPage + 1,
        lastCommitGroupDate: previousCommitGroupDate,
        loading: false,
      });
    } catch (error) {
      console.error(error);
      setState({
        commitList: [],
        loading: false,
        page: 1,
      });
    }
  };

  const loadMore = (num) => {
    if (num < 2 && !loadFilters) {
      loadCommits();
    }
  };

  return (
    <ContentWrapper>
      <Popup />
      <Flex justify="space-between">
        <PathHeader pathItems={pathLinks} />
        <FilterBox>
          <Container>
            <Dropdown
              inputLabel="Filter by:"
              className="categories-dropdown"
              label={categoryFilter.label}
              Icon={DropdownTick}
              data={filterOptions}
              handleDropdownClick={toggleCategoriesMenu}
              handleChoiceClick={handleCategoriesFilterChange}
              isMenuOpen={isCategoriesMenuOpen}
            />
            <Dropdown
              className="users-dropdown"
              label={userFilter.label}
              Icon={DropdownTick}
              data={users}
              handleDropdownClick={toggleUsersMenu}
              handleChoiceClick={handleUsersFilterChange}
              isMenuOpen={isUsersMenuOpen}
            />
          </Container>
          {kanbanFlag && (
            <KanbanDropdown
              kanbanBoardCards={kanbanBoardCards}
              label="Select trello card"
              onChange={handleCardFilterChange}
              selectedOption={cardFilter}
            />
          )}
        </FilterBox>
      </Flex>
      <Loader key="loader1" loading={loading} failed={!loading && !commitList.length} height="80%">
        {commitList && commitList.map((commitGroupData, index) => (
          <CommitGroup
            {...commitGroupData}
            repoName={repoName}
            repoFullName={repoFullName}
            repoId={repoId}
            key={index}
            openPopup={openPopup}
          />
        ))}
      </Loader>
      <InfiniteScroll
        key={page}
        pageStart={0}
        loadMore={loadMore}
        hasMore={hasMoreCommits}
        loader={(!loading && commitList.length) ? <Loader key="loader2" loading={!loading} /> : ""}
        useWindow={true}
      >
        <></>
      </InfiniteScroll>
    </ContentWrapper>
  );
};

Commits.propTypes = {
  location: PropTypes.object.isRequired,
  openPopup: PropTypes.func.isRequired,
  setUserFilter: PropTypes.func.isRequired,
  setCategoryFilter: PropTypes.func.isRequired,
  setKanbanBoardCardFilter: PropTypes.func.isRequired,
  commitListFilters: PropTypes.shape({
    userFilter: PropTypes.object.isRequired,
    categoryFilter: PropTypes.object.isRequired,
    cardFilter: PropTypes.object.isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(withPopup, withCommitsFilters)(Commits);
