/* eslint-disable import/no-cycle */
import React from "react";
import PropTypes from "prop-types";
import Category from "./Category";
import Rule from "./Rule";

const Options = ({ options, onSelect }) => {
  return options.map(({
    content,
    label,
    link,
    id,
  }) => {
    switch (true) {
      case !!(content && content.length):
        return <Category key={id} label={label} content={content} onSelect={onSelect} />;

      case !!link:
        return <Rule key={id} label={label} link={link} onSelect={onSelect} />;

      default:
        return null;
    }
  });
};

Options.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.array,
      link: PropTypes.string,
    })
  ).isRequired,
};

export default Options;
