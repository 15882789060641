/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, useReducer, useRef,
} from "react";
import PropTypes from "prop-types";
import * as htmlToImage from 'html-to-image';
import { compose } from "redux";
import { isEmpty } from "lodash";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { getUserData } from "../../utils/helpers/userData";
import { ROUTES } from "../Routes";
import { withCommitsFilters, checkRepoId } from "../Commits";
import { withPopup, Popup } from "../Popup";
import { getGuidelines } from "../GuidelinesDropdown";
import Flex from "../../components/Flex";
import ContentWrapper from "../../components/ContentWrapper";
import { PathHeader } from "../../components/PathHeader";
import { Loader } from "../../components/Loader";
import { CommitDetailLayout, CommitTitle } from "../../components/CommitDetailLayout";
import { formatCommitTitle } from "../../utils/helpers/formatCommitTitle";
import { copyStringToClipboard } from "../../utils/helpers/copyStringToClipboard";
import { SHARE } from "./constants";
import {
  getCommit,
  acceptCommit,
  rejectCommit,
  markAsFixed,
  getNextCommit,
  getNextCommitN,
} from "./api";

const CommitDetail = ({
  location: { search },
  history,
  openPopup,
  commitListFilters: { status, page },
}) => {
  const buttonShare = useRef(null);
  const containerShowOff = useRef(null);

  const { id = "", enableHighlighter = true } = getUserData() || {};

  const initialState = {
    loading: true,
    commitData: {},
    actionBlock: { path: "", position: -1, type: "" },
    commitImageUrl: null,
    trelloCardTitle: null,
    previousPage: '/projects/repository',
  };

  const reducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const [state, setState] = useReducer(reducer, initialState);
  const {
    loading, commitData, actionBlock, commitImageUrl, trelloCardTitle, previousPage,
  } = state;

  const {
    repoName,
    uuid,
    repoFullName,
    sha = "",
    repoId,
  } = queryString.parse(search);
  let mounted = true;

  const pathLinks = [
    {
      label: "Projects",
      link: ROUTES.PROJECTS,
    },
    {
      label: repoName,
      link: {
        pathname: ROUTES.REPO,
        search: queryString.stringify({
          repoName,
          repoFullName,
          repoId,
        }),
      },
    },
  ];

  const fetchCommitDetail = async () => {
    const response = await getCommit(repoId, uuid, enableHighlighter);
    if (mounted) {
      setState({
        commitData: response,
        actionBlock: { path: "", position: -1, type: "" },
        trelloCardTitle: response.kanbanBoardCard ? response.kanbanBoardCard.name : "",
      });
    }
  };

  const fetchNextCommit = async (searchQuery) => {
    if (searchQuery) {
      history.replace({ pathname: ROUTES.COMMIT, search: queryString.stringify(searchQuery) });
    } else {
      history.push(ROUTES.PROJECTS);
    }
  };

  const onAccept = async () => {
    openPopup("Accepted");
    setState({
      commitData: {},
      loading: true,
    });
    const response = await acceptCommit(uuid);
    if (response.status === 204) {
      let nextSearchQuery = '';
      if (previousPage === '/commits') {
        nextSearchQuery = await getNextCommitN();
      } else {
        nextSearchQuery = await getNextCommit(repoId, uuid, status, page);
      }
      fetchNextCommit(nextSearchQuery);
    }
  };

  const onReject = async () => {
    openPopup("Rejected!");
    setState({ loading: true });
    const response = await rejectCommit(uuid);
    if (response.status === 204) {
      let nextSearchQuery = '';
      if (previousPage === '/commits') {
        nextSearchQuery = await getNextCommitN();
      } else {
        nextSearchQuery = await getNextCommit(repoId, uuid, status, page);
      }
      fetchNextCommit(nextSearchQuery);
    }
  };

  const onMarkAsFixed = async () => {
    openPopup("Marked as fixed!");
    const response = await markAsFixed(uuid);
    if (response) {
      if (previousPage === '/commits') {
        const nextSearchQuery = await getNextCommitN();
        return fetchNextCommit(nextSearchQuery);
      }
      history.replace({
        pathname: ROUTES.REPO,
        search: queryString.stringify({
          repoName,
          repoFullName,
          repoId,
        }),
      });
    }
  };

  const shouldActionBlockOpen = (path, position, type) => (
    path === actionBlock.path
    && position === actionBlock.position
    && type === actionBlock.type
  );

  const toggleActionBlock = (path, position, type) => {
    if (type === SHARE) {
      const element = (buttonShare.current).closest('.code-block');
      htmlToImage.toPng(element)
        .then((dataUrl) => {
          setState({ commitImageUrl: dataUrl });
        })
        .catch((error) => {
          console.error('oops, something went wrong!', error);
        });
    }
    const commitLineActionBlock = !shouldActionBlockOpen(path, position, type) ? { path, position, type } : { path: "", position: -1, type: "" };
    setState({ actionBlock: commitLineActionBlock });
  };

  const handleOutsideClick = (event) => {
    // FIXME: mat not work with all browsers - consider using composedPath polyfill
    const path = event.path || (event.composedPath && event.composedPath());
    const check = [...path].some(({ classList = [] }) => {
      return [...classList].some((item) => {
        return item === "ignore-click";
      });
    });
    if (!check) {
      setState({
        actionBlock: { path: "", position: -1, type: "" },
        commitImageUrl: null,
      });
      if (containerShowOff.current) containerShowOff.current.innerText = "";
    }
  };

  const scrollToFileBlock = () => {
    const { hash } = window.location;
    if (!hash) return;

    const elementId = hash.substring(1);
    const fileBlockElement = document.getElementById(elementId);

    fileBlockElement.scrollIntoView();
    window.scrollBy(0, -150);
  };

  useEffect(() => {
    setState({ previousPage: localStorage.getItem('previousPage') });
    checkRepoId(repoId);
    document.addEventListener("click", handleOutsideClick);

    return () => {
      mounted = false;
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      setState({ loading: true });
    }

    (async () => {
      try {
        await Promise.all([
          fetchCommitDetail(),
          getGuidelines(),
        ]);
        if (mounted) {
          setState({ loading: false });
          scrollToFileBlock();
        }
      } catch (error) {
        console.error(error);
        if (mounted) setState({ loading: false });
      }
    })();
  }, [search]);

  const isCommitAuthor = (authorId = "") => {
    return id.toString() === authorId.toString();
  };

  const copyFileLink = (fileIndex) => {
    const { hash, href } = window.location;
    if (hash) {
      copyStringToClipboard(`${href.substring(0, href.lastIndexOf('#'))}#file-${fileIndex}`);
    } else {
      copyStringToClipboard(`${href}#file-${fileIndex}`);
    }
    openPopup("link copied!");
  };

  const openFileLink = (fileIndex) => {
    if (commitData.files[fileIndex] && commitData.files[fileIndex].fileName) {
      const link = encodeURI(`${ROUTES.VIEW_FILE}?repoId=${repoId}&repoFullName=${repoFullName}&sha=${sha}&filePath=${commitData.files[fileIndex].fileName}`);
      window.open(link, '_blank');
    }
  };

  const openParentCommitLink = (parentCommit) => {
    // eslint-disable-next-line max-len
    const link = encodeURI(`${ROUTES.COMMIT}?repoId=${repoId}&repoFullName=${repoFullName}&repoName=${repoName}&sha=${parentCommit.sha}&uuid=${parentCommit.uuid}`);
    window.open(link, '_blank');
  };

  const handleShowOffCancelClick = () => {
    containerShowOff.current.innerText = "";
    setState({ commitImageUrl: null });
  };

  const copyShaToClipboard = (string) => {
    copyStringToClipboard(string || "");
    openPopup("sha copied!");
  };

  return (
    <ContentWrapper>
      <Popup />
      <Flex justify="space-between" flex="1">
        <Flex column flex="3" padding="0 16px 0 0">
          <PathHeader pathItems={pathLinks} />
          <CommitTitle trelloCardTitle={trelloCardTitle} commitTitle={formatCommitTitle(commitData.message || '')} />
        </Flex>
      </Flex>
      <Loader loading={loading} failed={!loading && isEmpty(commitData)} height="80%">
        <CommitDetailLayout
          {...commitData}
          shouldActionBlockOpen={shouldActionBlockOpen}
          toggleActionBlock={toggleActionBlock}
          fetchCommitDetail={fetchCommitDetail}
          onAccept={onAccept}
          onReject={onReject}
          onMarkAsFixed={onMarkAsFixed}
          isCommitAuthor={isCommitAuthor(commitData.teamMemberId)}
          copyFileLink={copyFileLink}
          commitImageUrl={commitImageUrl}
          buttonShare={buttonShare}
          onCancelShowOff={handleShowOffCancelClick}
          containerShowOff={containerShowOff}
          setState={setState}
          openFileLink={openFileLink}
          copyShaToClipboard={copyShaToClipboard}
          openParentCommitLink={openParentCommitLink}
          enableHighlighter={enableHighlighter}
          enablePreviousCommitButton={!!commitData.previousCommit}
          enableNextCommitButton={!!commitData.nextCommit}
          openPopup={openPopup}
        />
      </Loader>
    </ContentWrapper>
  );
};

CommitDetail.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  openPopup: PropTypes.func.isRequired,
  commitListFilters: PropTypes.shape({
    status: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
  }).isRequired,
};

export default compose(
  withRouter,
  withPopup,
  withCommitsFilters,
)(CommitDetail);
