import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ThemeProvider as Theme } from "styled-components";
import { isEqual } from "lodash";
import { connect } from "react-redux";
import { BREAKPOINTS } from "./themes";
import { setScreenSize as setScreenSizeAction } from "./actions";

const ThemeProvider = ({ theme, children, setScreenSize }) => {
  useEffect(() => {
    handleBreakpoints();
    window.addEventListener("resize", handleBreakpoints);
    return () => window.removeEventListener("resize", handleBreakpoints);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBreakpoints = () => {
    const { innerWidth } = window;
    const { screenSizeMap } = theme;
    const screenSize = Object.entries(BREAKPOINTS).reduce((acc, [key, value]) => {
      if (innerWidth < value) return { ...acc, [key]: true };
      return { ...acc, [key]: false };
    }, {});

    if (!isEqual(screenSizeMap, screenSize)) {
      setScreenSize(screenSize);
    }
  };

  return <Theme theme={theme}>{children}</Theme>;
};

ThemeProvider.propTypes = {
  theme: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  setScreenSize: PropTypes.func.isRequired,
};

const mapStateToProps = ({ theme }) => ({ theme });

const mapDispatchToProps = dispatch => ({
  setScreenSize: screenSizeMap => dispatch(setScreenSizeAction(screenSizeMap)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThemeProvider);
