import styled from "styled-components/macro";

export default styled.button` 
  color: white;
  text-transform: capitalize;
  
  width: 288px;
  border-radius: 8px;
  padding: 15px;
  margin-left: 21px;
  :first-child {
    margin-left: 0;
  }
  
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
   
  background: ${(props) => {
    switch (props.type) {
      case "accept": return props.theme.palette.acceptButtonBg;
      case "reject": return props.theme.palette.rejectButtonBg;
      case "mark": return props.theme.palette.markButtonBg;
      default: return "none";
    }
  }};
  
  svg {
    height: 14px;
    margin-right: 13px;
  }
`;
