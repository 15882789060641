import { connect } from "react-redux";
import { changePalette, toggleNavbar } from "./actions";

const mapStateToProps = ({ theme }) => ({ theme });

const mapDispatchToProps = dispatch => ({
  changePalette: theme => dispatch(changePalette(theme)),
  toggleNavbar: () => dispatch(toggleNavbar()),
});

export default Component => connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
