import styled from "styled-components/macro";

export default styled.div`
  position: absolute;
  right: 0;
  border-radius: 0px 8px 0px 36px;
  background: ${({ theme }) => theme.palette.secondaryBg};
  height: 41px;
  width: 41px;
  justify-content: center;
  display: flex;
`;
