import styled from "styled-components/macro";

export default styled.button`
  color: ${({ theme }) => theme.palette.primaryText};
  border: 1px solid rgba(113, 126, 138, 0.3);
  border-radius: 6px;
  font-size: 10px;
  line-height: 13px;
  display: flex;
  align-items: center;
  padding: 5px 8px;
  margin: 8px 0 0;
  transition: all 0.3s ease-in-out 0s;
  & svg path {
    stroke: ${({ theme }) => theme.palette.primaryText};
  }
  &:hover {
    background: ${({ theme }) => theme.palette.sidebarButtonBgHover};
    color: #FFFFFF;
    & svg path {
      stroke: #FFFFFF;
    }
  }
  
`;
