import React from "react";
import PropTypes from "prop-types";
import { AvatarContainer, AvatarImage } from "./styled";

const Avatar = ({ avatarIcon }) => (
  <AvatarContainer>
    <AvatarImage src={avatarIcon} alt="" />
  </AvatarContainer>
);

Avatar.propTypes = {
  avatarIcon: PropTypes.string.isRequired,
};

export default Avatar;
