import styled from "styled-components/macro";

export default styled.table`
  width: 100%;
  border-spacing: 5px;
  border: 1px solid black;
  & td, th {
    text-align: left;
    border: 1px solid black;
    border-collapse: collapse;
    padding: 15px;
  };
  & th {
    background-color: gray;
    color: white;
    position: relative;
    .sort-icons {
      position: absolute;
      right: 5px;
      bottom: 0px;
      width: 10px;
      & div {
        height:12px;
      }
    }
    svg {
      width: 13px;
      margin-right: 4px;
    }
  };
  & th.sort {
    cursor: pointer;
  }
  & th.active {
    color: #fff;
  }
  & tr:nth-child(even) {
    background-color: #eee;
  }
  & tr:nth-child(odd) {
   background-color: #fff;
  }

  & .icon-container {
    display: inline;
    position: relative;
    .sort-icons {
      position: absolute;
      right: -20px;
      bottom: 0px;
      width: 10px;
      & div {
        height:12px;
      }
    }
  }
`;
