/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import {
  Container as CodeLineContainer, LineNumber, Content,
} from "../CommitDetailLayout/CodeLine/styled";
import CodeMirror from "../CommitDetailLayout/CodeLine/CodeMirror";
import { CodeBlock as CodeBlockContainer } from "../CommitDetailLayout/FileBlock/styled";
import { withTheme, PALETTES } from "../../containers/ThemeControl";

const CodeBlock = ({
  fileLines,
  enableHighlighter,
  theme: { activePalette },
}) => (
  <CodeBlockContainer>
    {
      fileLines.map(({ content, line_no }) => (
        <CodeLineContainer key={line_no}>
          <LineNumber style={{ width: "4%" }}>
            <code>{line_no}</code>
          </LineNumber>
          <Content>
            {enableHighlighter
              ? (
                <CodeMirror
                  value={content}
                  theme={activePalette === PALETTES.DEFAULT ? "material" : "paraiso-light"}
                  mode="javascript"
                />
              )
              : <code>{content}</code>}
          </Content>
        </CodeLineContainer>
      ))
    }
  </CodeBlockContainer>
);

CodeBlock.propTypes = {
  fileLines: PropTypes.array.isRequired,
  enableHighlighter: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(CodeBlock);
