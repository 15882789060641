import React from "react";
import PropTypes from "prop-types";
import { Container, DesktopLogo, MobileLogo } from "./styled";

const NavLogo = ({ navCollapsed, classToggle }) => (
  <Container className={classToggle}>
    {navCollapsed ? <MobileLogo /> : <DesktopLogo />}
  </Container>
);

NavLogo.propTypes = {
  navCollapsed: PropTypes.bool.isRequired,
  classToggle: PropTypes.string.isRequired,
};

export default NavLogo;
