import React from "react";
import PropTypes from "prop-types";
import { CONTEXT_MENU, COMMENT } from "../../../containers/CommitDetail/constants";
import { ContextMenu } from "../ContextMenu";
import { PlusSign } from "../../../images";
import { Cell, Button, ContextMenuWrapper } from "./styled";

const ActionBtn = ({
  className,
  modifier,
  toggleActionBlock,
  shouldActionBlockOpen,
  actionType,
  buttonShare,
}) => (
  <Cell className={modifier}>
    <Button
      className={`${className} ignore-click`}
      onClick={() => toggleActionBlock(CONTEXT_MENU)}
    >
      <PlusSign />
    </Button>
    {shouldActionBlockOpen(COMMENT) && (
      <ContextMenuWrapper>
        {/* FIXME: adjust onClick handling */}
        <ContextMenu
          column
          toggleActionBlock={toggleActionBlock}
          actionType={actionType}
          buttonShare={buttonShare}
        />
      </ContextMenuWrapper>
    )}
  </Cell>
);

ActionBtn.propTypes = {
  className: PropTypes.string.isRequired,
  modifier: PropTypes.string,
  toggleActionBlock: PropTypes.func,
  shouldActionBlockOpen: PropTypes.func,
  actionType: PropTypes.object.isRequired,
  buttonShare: PropTypes.object,
};

export default ActionBtn;
