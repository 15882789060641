/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import PropTypes from "prop-types";
import Flex from "../Flex";
import { EditIcon, BinIcon } from "../../images";
import { Avatar } from "../CommitTile/styled";
import { renderIcon } from "../ShareCommit/styled";
import { Overlay } from "../Comment/styled";
import {
  Title,
  Paragraph,
  Username,
  CreatedDate,
  Button,
  IconContainer,
} from "./styled";

const UpdateTile = ({
  id,
  title = "",
  description = "",
  postedAt = "",
  authorName = "",
  authorAvatar = "",
  isVisible = false,
  onEdit,
  onDelete,
}) => {
  return (
    <Flex column="column" width="100%" justify="space-between" className="relative">
      <Overlay className={`overlay-${id}`} />
      <Flex align="center" width="100%">
        <Flex align="center" width="100%">
          <Avatar src={authorAvatar} alt="user avatar" />
          <Flex justify="space-between" width="100%">
            <div>
              <Username>{authorName}</Username>
              <CreatedDate>{postedAt}</CreatedDate>
            </div>
            <IconContainer className={`iconContainer-${id}`} isVisible={isVisible}>
              <Button
                className="actionButton"
                onClick={() => onEdit(id, title, description)}
              >
                {renderIcon(EditIcon)}Edit
              </Button>
              <Button
                className="actionButton"
                onClick={() => onDelete(id)}
              >
                {renderIcon(BinIcon)}Delete
              </Button>
            </IconContainer>
          </Flex>
        </Flex>
      </Flex>
      <Flex column="column">
        <Title>{title}</Title>
        <Paragraph>{description}</Paragraph>
      </Flex>
    </Flex>
  );
};

UpdateTile.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  postedAt: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  authorAvatar: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isVisible: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default UpdateTile;
