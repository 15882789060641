import React from "react";
import PropTypes from "prop-types";
import Flex from "../../components/Flex";
import { UpdateTile } from "../../components/UpdateTile";
import {
  Month,
  UpdateContainer,
} from "./styled";

const UpdateGroup = ({
  date = "",
  features = [],
  onMouseInOut,
  onEdit,
  onDelete,
}) => {
  return (
    <div>
      {features.length > 0 && (
        <Flex margin="20px 0 0">
          <Month>{date}</Month>
          <Flex column="column" width="100%">
            {features && features.map(feature => (
              <UpdateContainer
                key={feature.id}
                onMouseOver={() => onMouseInOut(true, feature.id, feature.authorId)}
                onMouseOut={() => onMouseInOut(false, feature.id, feature.authorId)}
              >
                <UpdateTile
                  {...feature}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              </UpdateContainer>
            ))}
          </Flex>
        </Flex>
      )}
    </div>
  );
};

UpdateGroup.propTypes = {
  date: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
  onMouseInOut: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default UpdateGroup;
