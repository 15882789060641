import { axios, API_URL } from "../../utils/api";

export const getFileLines = async (sha, repositoryId, repoFullName, filePath, enableHighlighter) => {
  return axios.get(`${API_URL}/repository/${repoFullName}/commit/${sha}`, {
    params: {
      filePath,
      repositoryId,
      enableHighlighter,
    },
  });
};
