import React from "react";
import PropTypes from "prop-types";
import Flex from "../../../components/Flex";
import GuidelineCategories from "../../../components/Guidelines/GuidelineCategories";
import { Form, Input } from "./styled";

const AddForm = ({
  onChange,
  label = '',
  link = '',
  catId = 0,
  isOpenDrowDown,
  openDropDown,
  guidelineCategoriesList,
  guidelineCategoriesNames,
}) => {
  return (
    <Form>
      <Flex column="column" width="100%">
        <GuidelineCategories
          open={isOpenDrowDown}
          openDropDown={() => openDropDown(!isOpenDrowDown)}
          onSelectCategory={({ value }) => onChange('catId', value)}
          guidelineCategoriesList={guidelineCategoriesList}
          label={guidelineCategoriesNames[catId] || "Select Category"}
        />
        <Input
          type="text"
          placeholder="lable"
          onChange={({ target: { value } }) => onChange('label', value)}
          value={label}
        />
        <Input
          type="text"
          placeholder="link"
          onChange={({ target: { value } }) => onChange('link', value)}
          value={link}
        />
      </Flex>
    </Form>
  );
};

AddForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  openDropDown: PropTypes.func.isRequired,
  link: PropTypes.string,
  label: PropTypes.string,
  catId: PropTypes.number,
  guidelineCategoriesList: PropTypes.array.isRequired,
  guidelineCategoriesNames: PropTypes.object.isRequired,
  isOpenDrowDown: PropTypes.bool,
};

export default AddForm;
