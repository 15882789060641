import regexp from "markdown-it-regexp";

export default (usersList) => {
  const parser = (match) => {
    if (usersList.some(item => item === match[1])) {
      return `<span class="mention">@${match[1]}</span>`;
    }
    return `@${match[1]}`;
  };
  return regexp(/@(\w+)/, parser);
};
