import React from "react";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import { copyStringToClipboard } from "../../utils/helpers/copyStringToClipboard";
import Flex from "../Flex";
import { CommitTileStatus } from "../CommitTileStatus";
import { CountDown } from "../CountDown";
import {
  CommentStatusIcon, ShareStatusIcon, GuidelinesLinkStatusIcon, LikeStatusIcon,
} from "../../images";
import CommitCiStatus from "./CommitCiStatus";
import CommitMessage from "./CommitMessage";
import {
  CommitLink, Avatar, Status, Hash, ParentLink, Container, Info, RepoTitle,
} from "./styled";

const CommitTile = ({
  history, avatarUrl, fullMessage, authorName, commitTime, shortSha, formattedStatus, status,
  ciName, ciStatus, ciBuildUrl, commentsCount, linkTo, haveParentCommits, parentsLinks, openPopup, commitDate, trelloCardName, repositoryName,
}) => {
  const handleHashClick = (event, string) => {
    event.preventDefault();
    event.stopPropagation();
    copyStringToClipboard(string);
    openPopup("sha copied!");
  };

  const redirect = (e, link) => {
    e.preventDefault();
    if (link) {
      history.push(`${link.pathname}?${link.search}`);
    }
  };

  return (
    <CommitLink>
      <Container row padding="16px" align="center" justify="flex-start">
        <Flex width="100%" maxWidth="550px" justify="space-between" align="center">
          <Flex align="center">
            <Avatar src={avatarUrl} alt="user avatar" />
            <Flex justify="space-between">
              <Flex column justify="space-between">
                <CommitMessage source={fullMessage} commitLink={linkTo} trelloCardName={trelloCardName} />
                <Flex align="center">
                  <Info>{`${authorName} ${commitTime}`}</Info>
                  { (ciName && ciStatus) && (<CommitCiStatus ciBuildUrl={ciBuildUrl} ciStatus={ciStatus} ciName={ciName} />) }
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex margin="0 0 0 16px">
          <Status status={status}>{formattedStatus}</Status>
          {haveParentCommits
            && parentsLinks.map(({ link, label }) => (
              <ParentLink key={label} onClick={(e) => { redirect(e, link); }}>
                {label}
              </ParentLink>
            ))}
          {(status === 0 && commitDate) && (<CountDown dateTime={commitDate} />)}
        </Flex>

        <Flex margin="0 0 0 50px" column justify="space-between">
          <Flex align="center">
            <RepoTitle>{`${repositoryName}`}</RepoTitle>
          </Flex>
        </Flex>

        <Flex justify="flex-end" align="center" flex="1">
          <Flex justify="flex-end">
            {false && <CommitTileStatus Icon={GuidelinesLinkStatusIcon} text={1} />}
            {!!commentsCount && <CommitTileStatus Icon={CommentStatusIcon} text={commentsCount} />}
            {false && <CommitTileStatus Icon={LikeStatusIcon} text={5} />}
            {false && <CommitTileStatus Icon={ShareStatusIcon} text={1} />}
            <Hash onClick={event => handleHashClick(event, shortSha)}>{shortSha}</Hash>
          </Flex>
        </Flex>
      </Container>
    </CommitLink>
  );
};

CommitTile.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  fullMessage: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  commitTime: PropTypes.string.isRequired,
  ciName: PropTypes.string,
  ciStatus: PropTypes.string,
  ciBuildUrl: PropTypes.string,
  shortSha: PropTypes.string.isRequired,
  linkTo: PropTypes.object.isRequired,
  formattedStatus: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  commentsCount: PropTypes.number.isRequired,
  haveParentCommits: PropTypes.bool,
  parentsLinks: PropTypes.array,
  openPopup: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  commitDate: PropTypes.string.isRequired,
  trelloCardName: PropTypes.string,
  repositoryName: PropTypes.string,
};

export default withRouter(CommitTile);
