import styled from "styled-components/macro";

export default styled.div`
  position: absolute;
  top: 70px;
  right: 0px;
  max-width: 550px;
  width: auto;
  border: 1px solid rgba(22,25,28,0.3);
  border-top: none;
  background: ${({ theme }) => theme.palette.sidebarBg};
  height: 100vh;
  padding-bottom: 70px;
  & .custom-scroll > div:last-child > div {
    background-color: ${({ theme }) => theme.palette.brandColor} !important;
  }
`;
