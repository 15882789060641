import React, { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import queryString from "query-string";
import { compose } from "redux";
import { ROUTES } from "../Routes";
import { Loader } from "../../components/Loader";
import { formatCommitTitle } from "../../utils/helpers/formatCommitTitle";
import ContentWrapper from "../../components/ContentWrapper";
import { CodeBlock } from "../../components/ViewFile";
import { getUserData } from "../../utils/helpers/userData";
import { Text, Emoji } from "../../components/WorkInProgress/styled";
import { getFileLines } from "./api";
import {
  Container,
  TitleText,
  TileContainer,
  Avatar,
  FileName,
  Header,
  NotFoundContainer,
} from "./styled";

const initialState = {
  loading: true,
  file: {},
  commit: { repository: {} },
  fileNotFound: false,
};

const CommitDetail = ({ location: { search } }) => {
  const { id, enableHighlighter = true } = getUserData() || {};
  const reducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });
  const [state, setState] = useReducer(reducer, initialState);
  const {
    loading,
    file: { fileLines = [], size },
    commit: {
      uuid = '',
      message = '',
      repository: { name = '' },
      teamMember = {},
    },
    fileNotFound,
  } = state;

  const {
    repoId,
    repoFullName,
    sha,
    filePath,
  } = queryString.parse(search);

  useEffect(() => {
    (async () => {
      try {
        fetchFileLines();
      } catch (error) {
        console.error(error);
        setState({ loading: false });
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFileLines = async () => {
    setState({ loading: true });
    try {
      const {
        data: { success = false, data = {} },
      } = await getFileLines(sha, repoId, repoFullName, filePath, enableHighlighter);
      if (success) {
        setState({
          file: data.file,
          commit: data.commit,
          loading: false,
        });
      }
    } catch (error) {
      setState({ fileNotFound: true, loading: false });
    }
  };

  const projectLink = `${ROUTES.REPO}?${queryString.stringify({
    repoName: name,
    repoFullName,
    repoId,
  })}`;
  const commitPageLink = `${ROUTES.COMMIT}?${queryString.stringify({
    teamMemberId: id,
    repoName: name,
    repoFullName,
    repoId,
    message,
    sha,
    uuid,
  })}`;

  return (
    <ContentWrapper>
      <Loader loading={loading} height="80%">
        <FileName>
          <Link to={projectLink}>{name}</Link>
          <span>/</span>
          <span>{filePath}</span>
        </FileName>
        {
          teamMember.login && (
            <TileContainer>
              <Avatar src={teamMember.avatarUrl} />
              <TitleText>
                {teamMember.login}
                <Link to={commitPageLink}>{formatCommitTitle(message || '')}</Link>
              </TitleText>
            </TileContainer>
          )
        }
        {!fileNotFound && (
          <Container>
            <Header>
              <span>{`${fileLines.length} lines | ${size}`}</span>
            </Header>
            <CodeBlock fileLines={fileLines} enableHighlighter={enableHighlighter} />
          </Container>
        )}
        {fileNotFound && (
          <NotFoundContainer>
            <Emoji>{"¯\\_(ツ)_/¯"}</Emoji>
            <Text>File not found!!</Text>
          </NotFoundContainer>
        )}
      </Loader>
    </ContentWrapper>
  );
};

CommitDetail.propTypes = {
  location: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
)(CommitDetail);
