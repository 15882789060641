import { OPEN_POPUP, CLOSE_POPUP } from "./constants";

export const openPopup = payload => ({
  type: OPEN_POPUP,
  payload,
});

export const closePopup = () => ({
  type: CLOSE_POPUP,
});
