import React, { useState } from "react";
import PropTypes from "prop-types";
import Flex from "../../../components/Flex";
import { CloseIcon } from "../../../images";
import {
  CommentFooter,
  Button,
  Loader,
} from "../../../components/ShareCommit/styled";
import {
  CloseButton, Container, H3, Header, Popup, renderIcon, Cancel,
} from "./styled";
import Form from "./Form";
import useGetCategories from "../hooks/categories";
import { SuccessMsg } from "../styled";

const AddGuideline = ({
  heading,
  onCancel = () => {},
  onSubmit = () => {},
  progress,
}) => {
  const { guidelineCategoriesList, guidelineCategoriesNames } = useGetCategories();
  const [state, setState] = useState({
    catId: null, label: '', link: '', isOpenDrowDown: false, addNewFlag: false,
  });
  const {
    catId, label, link, addNewFlag,
  } = state;
  let enableSubmitButton = true;
  if (!label || !link || !catId) {
    enableSubmitButton = false;
  }

  const onChange = (text, value) => {
    setState(stateObj => ({ ...stateObj, [text]: value }));
  };

  const openDropDown = (flag) => {
    setState(stateObj => ({ ...stateObj, isOpenDrowDown: flag }));
  };

  const submit = () => {
    onSubmit({ catId, label, link });
    setState(stateObj => ({ ...stateObj, addNewFlag: true }));
    setTimeout(() => {
      setState(stateObj => ({ ...stateObj, addNewFlag: false }));
    }, 5000);
  };

  return (
    <Container data-test="Container" className="ignore-click">
      { addNewFlag && (<SuccessMsg>New entry saved successfully</SuccessMsg>) }
      <Popup width="66%">
        <Header>
          <H3>{heading}</H3>
          <CloseButton onClick={onCancel}>{renderIcon(CloseIcon)}</CloseButton>
        </Header>
        <Flex width="100%" padding="22px 22px 0px 22px">
          <Form
            onChange={onChange}
            openDropDown={openDropDown}
            guidelineCategoriesList={guidelineCategoriesList}
            guidelineCategoriesNames={guidelineCategoriesNames}
            {...state}
          />
        </Flex>
        <CommentFooter>
          <Flex width="100%" align="center" justify="flex-end" padding="0px 22px 16px 0px">
            <Cancel onClick={onCancel}>Cancel</Cancel>
            <Button data-test="submitButton" onClick={submit} disabled={!enableSubmitButton}>Submit</Button>
            {progress && <Loader />}
          </Flex>
        </CommentFooter>
      </Popup>
    </Container>
  );
};

AddGuideline.propTypes = {
  progress: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
};

export default AddGuideline;
