import {
  SET_CATEGORY_FILTER,
  SET_USER_FILTER,
  SET_REPO_ID,
  RESET_FILTERS,
  RESET_USER_FILTER,
  RESET_COMMITS_FILTER,
  SET_PAGE,
  SET_STATUS,
  SET_KANBAN_BOARD_CARD_FILTER,
  SET_TECHNOLOGY_FILTER,
  SET_REPOSITORY_FILTER,
} from "./constants";

const defaultUserFilter = {
  label: localStorage.getItem("userFilterLabel") || "Author",
  value: localStorage.getItem("userFilterValue") || "",
};
const defaultTechnologyFilter = {
  label: localStorage.getItem("technologyFilterLabel") || "All Stacks",
  value: localStorage.getItem("technologyFilterValue") || "",
};
const defaultRepositoryFilter = {
  label: localStorage.getItem("repositoryFilterLabel") || "All Repositories",
  value: localStorage.getItem("repositoryFilterValue") || "",
};
const defaultCategoryFilter = {
  label: localStorage.getItem("categoryFilterLabel") || "Rejected | To be reviewed",
  value: localStorage.getItem("categoryFilterValue") || 1,
};
const defaultCardFilter = {
  label: localStorage.getItem("cardFilterLabel") || "All",
  value: localStorage.getItem("cardFilterValue") || "",
};

export const initialState = {
  userFilter: defaultUserFilter,
  categoryFilter: defaultCategoryFilter,
  technologyFilter: defaultTechnologyFilter,
  repositoryFilter: defaultRepositoryFilter,
  cardFilter: defaultCardFilter,
  repoId: null,
  status: 1,
  page: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORY_FILTER:
      localStorage.setItem("categoryFilterLabel", action.payload.label);
      localStorage.setItem("categoryFilterValue", action.payload.value);
      return { ...state, categoryFilter: action.payload };

    case SET_USER_FILTER:
      localStorage.setItem("userFilterLabel", action.payload.label);
      localStorage.setItem("userFilterValue", action.payload.value);
      return { ...state, userFilter: action.payload };

    case SET_TECHNOLOGY_FILTER:
      localStorage.setItem("technologyFilterLabel", action.payload.label);
      localStorage.setItem("technologyFilterValue", action.payload.value);
      return { ...state, technologyFilter: action.payload };
    case SET_REPOSITORY_FILTER:
      localStorage.setItem("repositoryFilterLabel", action.payload.label);
      localStorage.setItem("repositoryFilterValue", action.payload.value);
      return { ...state, repositoryFilter: action.payload };
    case SET_KANBAN_BOARD_CARD_FILTER:
      localStorage.setItem("cardFilterLabel", action.payload.label);
      localStorage.setItem("cardFilterValue", action.payload.value);
      return { ...state, cardFilter: action.payload };

    case SET_REPO_ID:
      return { ...state, repoId: action.payload };

    case RESET_FILTERS:
      return { ...initialState, repoId: state.repoId };

    case RESET_USER_FILTER:
      localStorage.removeItem('userFilterLabel');
      localStorage.removeItem('userFilterValue');
      return {
        ...initialState,
        repoId: state.repoId,
      };

    case RESET_COMMITS_FILTER:
      localStorage.removeItem('repositoryFilterLabel');
      localStorage.removeItem('repositoryFilterValue');
      return {
        ...initialState,
        repositoryFilter: {
          label: 'All Repositories',
          value: '',
        },
      };
    case SET_STATUS:
      return { ...state, status: action.payload };

    case SET_PAGE:
      return { ...state, page: action.payload };

    default:
      return state;
  }
};
