import styled from "styled-components/macro";

export default styled.div`
  width: 100%;
  & .pagination li a {
    font-size: 14px !important;
  }
  & table {
    font-size: 14px;
    border-spacing: 0px 10px;
    border-collapse: unset;
    border: none;
    background: ${({ theme }) => theme.palette.bodyBg};
    margin-top: 16px;
    & td, th {
      text-align: left;
      border: none;
      border-collapse: collapse;
    };
    & th {
      padding: 0 16px;
      background: ${({ theme }) => theme.palette.bodyBg};
      color: ${({ theme }) => theme.palette.mainText};
    };
    & th.active {
      color: ${({ theme }) => theme.palette.brandColor};
      & .sort-icons div {
        height: 20px;
      }
    }
    & tr {
      background: none !important;
    }
    & td {
      padding: 16px;
      background: ${({ theme }) => theme.palette.primaryBg};
      color: ${({ theme }) => theme.palette.primaryText};
      & .channel {
        width: 200px;
        background: ${({ theme }) => theme.palette.dropdownSecondaryBg};
        padding: 12px;
        border: 0px;
        border-radius: 6px;
      }
      & .channel {
        color: ${({ theme }) => theme.palette.primaryText};
      }
      & .technology-dropdown {
        background: ${({ theme }) => theme.palette.dropdownSecondaryBg};
        margin: 0px;
        min-width: 150px;
      }
    };
    & td:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    & td:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    & td input {
      font-size: 14px;
      & :focus {
        color: ${({ theme }) => theme.palette.primaryText}
      }
    }
  }
`;
