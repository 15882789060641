/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import queryString from "query-string";
import Flex from "../Flex";
import { ROUTES } from "../../containers/Routes";
import {
  Avatar, Title, Info, Container, ActiveDot,
} from "./styled";

const NotificationTile = ({
  sender,
  repository,
  commit,
  createdAt,
  notificationLastSeen,
  repositoryId,
  notificationClose,
  type,
}) => {
  const linkTo = {
    pathname: ROUTES.COMMIT,
    search: queryString.stringify({
      sha: commit.sha,
      repoName: repository.name,
      repoFullName: repository.fullName,
      uuid: commit.uuid,
      repoId: repositoryId,
    }),
  };
  const [notificationMessage, setNotificationMessage] = useState("");

  useEffect(() => {
    const notificationType = type === "Comment" ? 'commented on commit' : 'rejected a commit';
    setNotificationMessage(`${sender.login} ${notificationType} ${commit.shortSha} in ${repository.name}`);
  }, [type]);

  return (
    <Container>
      <Flex width="100%" maxWidth="550px" justify="space-between" align="start">
        <Avatar src={sender.avatarUrl} alt="user avatar" />
        <Flex justify="space-between" className="flex-grow">
          <Link to={linkTo} onClick={notificationClose}>
            <Flex column justify="space-between">
              <Flex row justify="flex-end">
                <Title className={(!notificationLastSeen || notificationLastSeen < createdAt) ? 'active' : ''}>
                  {notificationMessage}
                </Title>
              </Flex>
              <Info>{moment(createdAt).format("MMMM DD, HH:mm")}</Info>
            </Flex>
          </Link>
        </Flex>
        <ActiveDot className={(!notificationLastSeen || notificationLastSeen < createdAt) ? "active" : ""} />
      </Flex>
    </Container>
  );
};

NotificationTile.propTypes = {
  repositoryId: PropTypes.number.isRequired,
  sender: PropTypes.object.isRequired,
  repository: PropTypes.object.isRequired,
  commit: PropTypes.object.isRequired,
  notificationLastSeen: PropTypes.string.isRequired,
  notificationClose: PropTypes.func.isRequired,
  createdAt: PropTypes.string,
  type: PropTypes.string,
};

export default NotificationTile;
