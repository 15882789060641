/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import PropTypes from "prop-types";
import { LoginButton } from "../../LoginButton";
import {
  Header,
  SubHeader,
  Text,
  Link,
} from "../styled";

const Login = ({ startAuth }) => (
  <>
    <Header large>Sign in</Header>
    <SubHeader large>with your GitHub account&#58;</SubHeader>
    <LoginButton margin="34px 0 18px" onClick={startAuth}>
      Sign in with GitHub
    </LoginButton>
    <Text>don&#39;t have GitHub account?</Text>
    <Link
      margin="8px 0 0"
      href="https://github.com/join?source=header-home"
      target="_blank"
    >
      Create account
    </Link>
  </>
);

Login.propTypes = {
  startAuth: PropTypes.func.isRequired,
};

export default Login;
