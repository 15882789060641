import styled from "styled-components/macro";
import palettes from "../containers/ThemeControl/themes/palettes";
const {
  LIGHT: { primaryBg },
} = palettes;

export default styled.div`
  display: flex;
  flex-direction: ${({ row }) => row ? "row" : "column"};
  justify-content: ${({ justify }) => justify || "flex-start"};
  align-items: ${({ align }) => align || "flex-start"};
  padding: ${({ padding }) => padding || "32px"};
  border-radius: 8px;
  background: ${({ light, theme }) => light ? primaryBg : theme.palette.primaryBg};
  ${({ noShadow }) => noShadow ? "" : "box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);"}
`;
