import styled from "styled-components/macro";

export default styled.button`
  /* FIXME: placeholder color */
  background: ${({ theme }) => theme.palette.acceptButtonBg};
  font-weight: 500;
  line-height: 18px;
  color: white;
  padding: 9px 18px;
  border-radius: 8px;
  opacity: 1;
  position: relative;
  visibility: ${({ isVisible }) => isVisible ? "visible" : "hidden"};
  &:disabled {
    opacity: 0.25;
  }
`;
