import React from "react";
import PropTypes from "prop-types";
import { CommentBlock } from "../../../containers/CommentBlock";
import { CodeLineTab } from "../../../containers/CodeLineTab";
import { CommentsContainer } from "./styled";

const InlineCommentsList = ({
  comments = [],
  path,
  position,
  fetchCommitDetail,
  addComment,
  committerId,
}) => {
  const setHeaderText = (length = 0) => {
    switch (length) {
      case 0: return "no comments";
      case 1: return `${length} comment`;
      default: return `${length} comments`;
    }
  };

  return (
    <CodeLineTab
      headerText={setHeaderText(comments.length)}
      isExpandForced={addComment}
    >
      <CommentsContainer>
        {comments.map(data => (
          <CommentBlock
            {...data}
            key={data.id}
            fetchCommitDetail={fetchCommitDetail}
          />
        ))}
        <CommentBlock
          path={path}
          position={position}
          fetchCommitDetail={fetchCommitDetail}
          committerId={committerId}
          type="NEW"
          // TODO: handle focus here
        />
      </CommentsContainer>
    </CodeLineTab>
  );
};

InlineCommentsList.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object),
  path: PropTypes.string,
  position: PropTypes.number,
  fetchCommitDetail: PropTypes.func,
  addComment: PropTypes.bool.isRequired,
  committerId: PropTypes.number,
};

export default InlineCommentsList;
