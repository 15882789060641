import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Choice,
  ChoicesWrapper,
  InputLabel,
} from "./styled";

const Dropdown = ({
  label,
  Icon,
  handleDropdownClick,
  isMenuOpen,
  data,
  handleChoiceClick,
  className,
  inputLabel,
}) => (
  <>
    {inputLabel && <InputLabel>{inputLabel}</InputLabel>}
    <Button className={className} onClick={handleDropdownClick} open={!!isMenuOpen}>
      {label}
      <Icon />
      {isMenuOpen && (
        <ChoicesWrapper>
          {data.map(item => (
            <Choice
              isActive={item.label === label}
              onClick={() => item.label !== label ? handleChoiceClick(item) : null}
              key={item.value || item.label}
            >
              {item.label}
            </Choice>
          ))}
        </ChoicesWrapper>
      )}
    </Button>
  </>
);

Dropdown.propTypes = {
  inputLabel: PropTypes.string,
  label: PropTypes.string.isRequired,
  Icon: PropTypes.object.isRequired,
  handleDropdownClick: PropTypes.func.isRequired,
  handleChoiceClick: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.func.isRequired,
  ]),
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default Dropdown;
