import React from "react";
import PropTypes from "prop-types";
import { Container, Avatar } from "./styled";

const Comment = ({ children, avatar, className }) => (
  <Container className={className}>
    <Avatar src={avatar} />
    {children}
  </Container>
);

Comment.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  avatar: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Comment;
