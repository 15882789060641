/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { NotificationBlock } from "../../components/NotificationBlock";
import { subscribeForRealTimeEvent } from "../../utils/helpers/eventListner";
import { getUserData } from "../../utils/helpers/userData";
import { getNotifications, seenNotifications } from "./api";

const perPageSize = 20;
const initialState = {
  notifications: [],
  offSet: 1,
  hasMore: false,
  loading: true,
  notificationLastSeen: '',
};

const reducer = (prevState, updatedProperty) => ({
  ...prevState,
  ...updatedProperty,
});
export const Notification = ({ notificationActive, setNotificationFlag, notificationClose }) => {
  const [state, setState] = useReducer(reducer, initialState);
  const [newNotification, setNewNotification] = useState(false);
  const {
    notifications,
    notificationLastSeen,
    loading,
    offSet,
    hasMore,
  } = state;

  useEffect(() => {
    const { id } = getUserData() || {};
    const eventSource = subscribeForRealTimeEvent(id);
    if (eventSource) {
      eventSource.addEventListener('Notification', updateNewNotification);
    }

    document.addEventListener('click', handleNotificationOutsideClick);

    return () => {
      eventSource.close();
      document.removeEventListener("click", handleNotificationOutsideClick);
    };
  }, []);

  const handleNotificationOutsideClick = (event) => {
    const path = event.path || (event.composedPath && event.composedPath()) || [];
    const check = [...path].some(({ classList = [] }) => {
      return [...classList].some((item) => {
        return item === "notificationArea";
      });
    });

    if (!check) {
      notificationClose();
    }
  };

  const updateNewNotification = () => {
    setNewNotification(true);
  };

  useEffect(() => {
    if (newNotification) {
      fetchNewNotification();
    }
  }, [newNotification]);

  const fetchNewNotification = async () => {
    const perPage = offSet > 1 ? perPageSize * offSet : perPageSize;
    const response = await getNotifications(`perPage=${perPage}&page=1`);
    setState({ notifications: response.notifications });
    setState({
      notifications: response.notifications,
      hasMore: (!hasMore && response.totalPages > 1) ? true : hasMore,
      notificationLastSeen: response.notificationLastSeen || '',
    });
    setNewNotification(false);
    setNotificationFlag(1);
  };

  useEffect(() => {
    let sentLastSeen = false;
    if (offSet === 1 && (notificationActive || !notifications.length)) {
      sentLastSeen = true;
      (async () => {
        const lastSeen = await fetchNotifications(offSet);
        if (lastSeen) {
          seenNotifications(lastSeen);
        }
      })();
    }

    if (!sentLastSeen && notifications.length) {
      seenNotifications(notifications[0].createdAt);
    }
  }, [notificationActive]);

  const fetchNotifications = async (nextPage) => {
    const params = `perPage=${perPageSize}&page=${nextPage || 1}`;
    setState({ loading: (nextPage > 1) });
    const response = await getNotifications(params);
    if (!response) {
      return 0;
    }
    setState({
      notifications: nextPage > offSet ? notifications.concat(response.notifications) : response.notifications,
      hasMore: response.totalPages > nextPage,
      offSet: nextPage,
      loading: false,
      notificationLastSeen: response.notificationLastSeen || '',
    });

    if (response.notifications.length && response.notifications[0].createdAt > response.notificationLastSeen) {
      setNotificationFlag(1);
    }

    return response.notifications.length ? response.notifications[0].createdAt : 0;
  };

  return (
    notificationActive && notifications.length
      ? (
        <NotificationBlock
          loading={loading}
          hasMore={hasMore}
          viewMore={() => fetchNotifications(offSet + 1)}
          notifications={notifications}
          notificationLastSeen={notificationLastSeen}
          notificationClose={notificationClose}
        />
      )
      : ""
  );
};

Notification.propTypes = {
  notificationActive: PropTypes.bool.isRequired,
  setNotificationFlag: PropTypes.func.isRequired,
  notificationClose: PropTypes.func.isRequired,
};

export default Notification;
