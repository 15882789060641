import React from "react";
import PropTypes from "prop-types";
import { StyledLi, LiSpan } from "./styled";

const Rule = ({ label, link, onSelect }) => (
  <StyledLi>
    <button type="button" onClick={() => onSelect(link, label)}>
      <LiSpan>{label}</LiSpan>
    </button>
  </StyledLi>
);

Rule.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Rule;
