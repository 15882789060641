import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { ROUTES } from "../Routes";
import ContentWrapper from "../../components/ContentWrapper";
import { PathHeader } from "../../components/PathHeader";
import { Pagination } from "../../components/Pagination/Pagination";
import PageLoader from "../../components/PageLoader";
import { Table } from "../../components/Insights";
import Flex from "../../components/Flex";
import { Dropdown } from "../../components/Dropdown";

import { DropdownTick } from "../../images";

import useGetProjectRows from "./hooks/projectsInsights";
import { tableHeadColumns, PER_PAGE_LIMIT, PAGE_NEIGHBOURS } from "./constants";
import { setFilters } from "./actions";
import { getProjectsInsights, getTechnology } from "./api";
import {
  TableContainer,
  SearchContainer,
  Input,
  DateRangeContainer,
} from "./styled";
import ApiError from "./ApiError";
import { formatDate } from "../../utils/common";

const ProjectsInsights = () => {
  const { filters, loader } = useSelector(store => store.projectsInsights);
  const dispatch = useDispatch();

  const [technologyList, setTechnologyList] = useState([]);
  const [dateRangeValue, setDateRange] = useState([null, null]);
  const [isTechnologiesMenuOpen, setIsTechnologiesMenuOpen] = useState(false);

  const { projectList, totalCount } = useGetProjectRows();

  useEffect(() => {
    (async () => {
      try {
        const technologies = await getTechnology();
        setTechnologyList([{ label: 'All Stacks', value: 0 }].concat(technologies.map(({ name, id }) => {
          return {
            label: name,
            value: id,
          };
        })));
      } catch (error) {
        console.error(error);
      }
    })();
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const onPageChanged = ({ currentPage }) => {
    if (filters.page !== currentPage) {
      filters.page = currentPage;
      dispatch(setFilters(filters));
      window.scroll(0, 100);
    }
  };

  const onChangeSort = ({ column, order }) => {
    if (filters.order !== column) {
      filters.order = column;
      filters.sort = 'asc';
      dispatch(setFilters(filters));
    } else if (filters.sort !== order) {
      filters.sort = order;
      dispatch(setFilters(filters));
    }
  };

  const onSearchProject = async (key, value) => {
    if (key === 'Enter') {
      if (value.trim().length >= 2) {
        Object.assign(filters, {
          search: value.trim(), page: 1, order: 'name', sort: 'asc',
        });
      } else {
        Object.assign(filters, {
          search: '', page: 1, order: 'name', sort: 'asc',
        });
      }
      dispatch(setFilters(filters));
      await getProjectsInsights(filters);
    }
  };

  const onChangeDateRange = async (item) => {
    let toDate = '';
    let fromDate = '';
    if (item && item.length === 2) {
      const [startDate, endDate] = item;
      fromDate = formatDate(startDate);
      toDate = formatDate(endDate);
    }
    Object.assign(filters, {
      search: '', page: 1, order: 'name', sort: 'asc', fromDate, toDate,
    });

    setDateRange([fromDate, toDate]);
    dispatch(setFilters(filters));
    await getProjectsInsights(filters);
  };

  const toggleTechnologiesMenu = () => {
    setIsTechnologiesMenuOpen({ isTechnologiesMenuOpen: !isTechnologiesMenuOpen });
  };

  const handleTechnologyFilterChange = async (technologyFilter) => {
    Object.assign(filters, {
      search: '', page: 1, order: 'name', sort: 'asc', technologyFilter,
    });
    dispatch(setFilters(filters));
    await getProjectsInsights(filters);
  };

  const handleOutsideClick = (event) => {
    if (![...event.target.classList].includes('technologies-dropdown')) {
      setIsTechnologiesMenuOpen(false);
    }
  };

  return (
    <ContentWrapper data-test="ContentWrapper">
      <Flex justify="space-between">
        <PathHeader pathItems={[{ label: "Insights", link: ROUTES.INSIGHTS }]} />
        <SearchContainer>
          <Flex justify="space-between">
            <DateRangeContainer>
              <DateRangePicker className="pickerContainer" onChange={onChangeDateRange} value={dateRangeValue} />
              <Dropdown
                className="technologies-dropdown"
                label={filters.technologyFilter.label}
                Icon={DropdownTick}
                data={technologyList}
                handleDropdownClick={toggleTechnologiesMenu}
                handleChoiceClick={handleTechnologyFilterChange}
                isMenuOpen={isTechnologiesMenuOpen}
              />
            </DateRangeContainer>
            <Input
              type="text"
              placeholder="Search for project"
              onKeyDown={({ key, target: { value } }) => onSearchProject(key, value)}
            />
          </Flex>
        </SearchContainer>
      </Flex>
      {(!projectList || loader) && <PageLoader />}
      <TableContainer>
        <ApiError />
        {projectList && (
          <Table
            data-test="insights-table"
            projectList={projectList}
            tableHeadColumns={tableHeadColumns}
            onChangeSort={onChangeSort}
          />
        )}
        {(!!totalCount) && (
          <Pagination
            currentPage={filters.page}
            totalRecords={totalCount}
            pageLimit={PER_PAGE_LIMIT}
            pageNeighbours={PAGE_NEIGHBOURS}
            onPageChanged={onPageChanged}
          />
        )}
      </TableContainer>
    </ContentWrapper>
  );
};

export default ProjectsInsights;
