/* eslint-disable react/jsx-one-expression-per-line, no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { ROUTES } from "../../containers/Routes";
import { AccountOption, Option, LinkItem } from './styled';

const ProfileItems = ({
  onSubmit,
}) => {
  return (
    <AccountOption className="ignore-click">
      <LinkItem exact to={ROUTES.SETTINGS}>Settings</LinkItem>
      <Option onClick={onSubmit}>Log Out</Option>
    </AccountOption>
  );
};

ProfileItems.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ProfileItems;
