export const copyStringToClipboard = (string = "") => {
  // Create new element
  const element = document.createElement('textarea');

  // Set value (string to be copied)
  element.value = string;

  // Set non-editable to avoid focus and move outside of view
  element.setAttribute('readonly', '');
  element.style = { position: 'absolute', left: '-9999px' };
  document.body.appendChild(element);

  // Select text inside element
  element.select();

  // Copy text to clipboard
  document.execCommand('copy');

  // Remove temporary element
  document.body.removeChild(element);
};
