import styled from "styled-components/macro";

export default styled('a')`
  align-self: center;
  padding: 6px;
  margin-left: 16px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 14px;
  color: white;
  cursor: pointer;
  background: ${({ theme }) => theme.palette.commitPassed};
`;
