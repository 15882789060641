import { axios, API_URL } from "../../utils/api";

export const getProjects = async (showHiddenRepositories, perPage, offSet) => {
  const responseData = await axios.get(
    `${API_URL}/member/repositories`, {
      params: {
        showHiddenRepositories,
        perPage,
        page: offSet,
      },
    }
  );

  const { data: { data = {} } = {} } = responseData;

  return data;
};

export const saveRepositoryState = async (repositoryId, data) => {
  const response = await axios.post(`${API_URL}/repository/${repositoryId}/state`, data);

  return response.status === 201;
};
