import styled from "styled-components/macro";
import Input from "react-autosize-textarea";
import { commentField } from "./styles";

export default styled(Input)`
  ${commentField}
  width: 100%;
  padding-right: 32px;
  resize: none;
  outline: none;
  border: 2px solid transparent;
  

  :focus {
    /* FIXME: adjust color role */
    border-color: ${({ theme }) => theme.palette.acceptButtonBg};
  }
`;
