import { store } from "../../store";
import { resetFilters, setRepoId } from "./actions";

export default (repoId) => {
  const { commitListFilters: { repoId: stateRepoId } = {} } = store.getState() || {};
  if (stateRepoId !== repoId) {
    store.dispatch(setRepoId(repoId));
    store.dispatch(resetFilters());
  }
};
