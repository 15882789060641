import React from 'react';
import { PropTypes } from 'prop-types';
import { DataTable } from "../../containers/DataTable";
import { ToggleSwitch } from "../ToggleSwitch";
import Technology from './Technology';

const Table = ({
  projectList,
  tableHeadColumns,
  onChangeSort,
  openTechnology,
  technologyId,
  technologyList,
  technologyNames,
  onChange,
  channelName,
  setChannelName,
}) => {
  const rows = [];
  const saveChannelName = ({ target: { value } }, id) => {
    if (channelName !== null && channelName !== value) {
      onChange(id, { channelName: value });
    }
    setChannelName(null);
  };

  const onChangeInput = (value) => {
    if (channelName === null) {
      setChannelName(value || '');
    }
  };

  const onPressEnter = ({ target: { value }, which }, id) => {
    if (which === 13) {
      saveChannelName({ target: { value } }, id);
    }
  };

  if (!projectList || !projectList.length) {
    rows.push({
      name: { value: "No record found.", colSpan: 6 },
    });
  } else {
    projectList.forEach((item) => {
      rows.push({
        name: { value: item.name },
        channelName: {
          value: (
            <input
              key={item.id}
              className="channel"
              type="text"
              defaultValue={item.channelName}
              onChange={() => onChangeInput(item.channelName)}
              onBlur={e => saveChannelName(e, item.id)}
              onKeyPress={e => onPressEnter(e, item.id)}
            />
          ),
        },
        technologyId: {
          value: (
            <Technology
              open={technologyId === item.id}
              openTechnology={() => openTechnology(item.id)}
              onSelectTechnology={({ value }) => onChange(item.id, { technologyId: value })}
              technologyList={technologyList}
              label={technologyNames[item.technologyId] || "Select Technology"}
            />
          ),
        },
        slackNotificationsEnabled: {
          value: (
            <ToggleSwitch
              onChange={() => onChange(item.id, { slackNotificationsEnabled: !item.slackNotificationsEnabled })}
              checked={item.slackNotificationsEnabled}
            />
          ),
        },
        emailNotificationEnabled: {
          value: (
            <ToggleSwitch
              onChange={() => onChange(item.id, { emailNotificationEnabled: !item.emailNotificationEnabled })}
              checked={item.emailNotificationEnabled}
            />
          ),
        },
        enableKanbanCardFilter: {
          value: (
            <ToggleSwitch
              onChange={() => onChange(item.id, { enableKanbanCardFilter: !item.enableKanbanCardFilter })}
              checked={item.enableKanbanCardFilter}
            />
          ),
        },
        isArchived: {
          value: (
            <ToggleSwitch
              onChange={() => onChange(item.id, { isArchived: !item.isArchived })}
              checked={item.isArchived}
            />
          ),
        },
      });
    });
  }

  return (
    <DataTable
      rows={rows}
      columns={tableHeadColumns}
      onChangeSort={onChangeSort}
      defaultSort={{ column: 'name', order: 'asc' }}
    />
  );
};

Table.propTypes = {
  projectList: PropTypes.array.isRequired,
  technologyList: PropTypes.array.isRequired,
  technologyNames: PropTypes.object.isRequired,
  tableHeadColumns: PropTypes.array.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  openTechnology: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setChannelName: PropTypes.func.isRequired,
  technologyId: PropTypes.number,
  channelName: PropTypes.string,
};


export default Table;
