import { fromPairs } from "lodash";
import { axios, API_URL } from "../../utils/api";
import { store } from "../../store";
import { setGuidelines } from "./actions";

export const formatResponse = (data) => {
  return data.map((item) => {
    const formatted = Object.entries(item).map(([key, value]) => {
      switch (key) {
        case "codingGuidelineRules":
          return ["content", value];

        default:
          return [key, value];
      }
    });
    return fromPairs(formatted);
  });
};

export const getGuidelines = async () => {
  const response = await axios.get(`${API_URL}/codingGuideline`);

  const { data: { data = [] } = {} } = response || {};
  if (response.data.success) {
    return store.dispatch(
      setGuidelines(formatResponse(data)),
    );
  }

  throw new Error("cannot get coding guidelines");
};
