import { axios, API_URL } from "../../utils/api";
import { formatResponseWithMeta, formatResponse } from "../../utils/common";
import { store } from "../../store";
import {
  setProjectList, setTechnologyList, setProjectError, setLoader,
} from "./actions";
import { PER_PAGE_LIMIT } from "./constants";

export const getProjects = async ({
  page = 1,
  sort,
  order,
  name = '',
}) => {
  try {
    store.dispatch(setLoader(true));
    const params = {
      page,
      perPage: PER_PAGE_LIMIT,
      order,
      sort,
      name,
    };
    const response = await axios.get(`${API_URL}/repositories`, { params });

    if (response.status === 200) {
      const { data, meta: { totalCount = 0 } } = formatResponseWithMeta(response);
      store.dispatch(setProjectList({ projectList: data, totalCount }));
      return data;
    }

    store.dispatch(setLoader(false));
    return false;
  } catch (error) {
    store.dispatch(setProjectError("API failed."));
    return error;
  }
};

export const getDropDownOption = (dataArray) => {
  if (!dataArray || !dataArray.length) {
    return [];
  }

  const technologyList = [];
  const technologyNames = {};
  dataArray.forEach((item) => {
    technologyList.push({ label: item.name, value: item.id });
    technologyNames[item.id] = item.name;
  });
  return { technologyList, technologyNames };
};

export const getTechnology = async () => {
  try {
    const response = await axios.get(`${API_URL}/technology`);

    if (response.status === 200) {
      const { technologyList, technologyNames } = getDropDownOption(formatResponse(response));
      store.dispatch(setTechnologyList({ technologyList, technologyNames }));
      return { technologyList, technologyNames };
    }

    return false;
  } catch (error) {
    store.dispatch(setProjectError("Get technology API failed."));
    store.dispatch(setLoader(false));
    return error;
  }
};

export const updateProject = async (repositoryId, data) => {
  try {
    store.dispatch(setLoader(true));
    return await axios.put(`${API_URL}/repository/${repositoryId}/update`, data);
  } catch (error) {
    store.dispatch(setProjectError("Update project API failed."));
    store.dispatch(setLoader(false));
    return false;
  }
};
