import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

export default styled(NavLink)`
  font-weight: 500;
  font-size: 36px;
  color: ${({ theme }) => theme.palette.mainText};

  &.active,
  &:hover {
    color: ${({ theme }) => theme.palette.primaryText};
  }
`;
