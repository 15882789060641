import React from "react";
import PropTypes from 'prop-types';
import { NotificationsIcon, NotificationsIconContainer } from "./styled";

const Notifications = ({ setToggleOpen, newNotificationFlag }) => {
  return (
    <NotificationsIconContainer className="notificationArea" onClick={setToggleOpen}>
      <NotificationsIcon />
      { newNotificationFlag > 0 && (<span className="dot"></span>) }
    </NotificationsIconContainer>
  );
};

Notifications.propTypes = {
  setToggleOpen: PropTypes.func.isRequired,
  newNotificationFlag: PropTypes.number.isRequired,
};

export default Notifications;
