import styled from "styled-components/macro";
import { commentField } from "./styles";

export default styled.div`
  ${commentField}
    /* set bottom margins */
    & > * {
    margin-bottom: 12px;
    :last-child {
      margin-bottom: 0;
    }
  }

  /* inline code */
  code {
    position: relative;
    display: inline-block;
    padding: 0 3px;
    border-radius: 4px;
    color: ${({ theme }) => theme.palette.primaryText};
  
    /* FIXME: placeholder color */
    background: ${({ theme }) => theme.palette.deleteOverlayBg};
  }

  /* block code */
  pre code {
    display: block;
    min-width: 100px;
    padding: 8px;
  }

  /* quotes */
  blockquote {
    display: block;
    /* FIXME: placeholder color */
    border-left: solid 2px ${({ theme }) => theme.palette.codeBorder};
    padding-left: 8px;
    color: ${({ theme }) => theme.palette.deleteOverlayBg};
  }

  /* link */
  a {
    color: ${({ theme }) => theme.palette.commentHeaderBg};
    text-decoration: underline;
  }

  /* bold */
  strong {
    font-weight: 900;
  }

  /* italic */
  em {
    font-style: italic;
  }

  .mention {
    font-weight: 900;
  }
`;
