import styled, { css } from "styled-components/macro";
import { triangleStyles } from "./styles";

const colorVariant = color => css`
  path {
    fill: ${color};
  }
  border: 1px solid ${color};
`;

export default styled.button`
  position: relative;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  /* FIXME: placeholder color */
  ${colorVariant(({ theme }) => theme.palette.guideColor)}
  padding: 3px;

  :hover {
  /* FIXME: placeholder color */
  ${colorVariant(({ theme }) => theme.palette.guideHover)}
  }

  ${({ isOpen }) => isOpen
    && css`
  /* FIXME: placeholder color */
  ${colorVariant(({ theme }) => theme.palette.guideColor)}
      :after {
        ${triangleStyles}
      }
    `};
`;
