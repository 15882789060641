import React from "react";
import PropTypes from "prop-types";
import { StyledDropdown } from "./styled";

import Options from "./Options";

const Dropdown = ({ options, onSelect }) => (
  <StyledDropdown>
    <Options options={options} onSelect={onSelect} />
  </StyledDropdown>
);

Dropdown.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Dropdown;
