import React from "react";
import PropTypes from "prop-types";
import Nav from "../Nav";
import { TopPanel } from "../TopPanel";
import { withHotJar } from "../../utils/hotjar";

import { Container, MainContent } from "./styled";

export const MainLayout = ({ children }) => (
  <Container>
    <Nav />
    <TopPanel />
    <MainContent>
      {children}
    </MainContent>
  </Container>
);

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default withHotJar(MainLayout);
