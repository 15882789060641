import styled from "styled-components/macro";

export default styled.p`
  color: ${({ theme }) => theme.palette.primaryText};
  padding-bottom: 12px;
  a {
    color: ${({ theme }) => theme.palette.linkColor};
  }
  :first-child {
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
  };
  :last-of-type {
    padding-bottom: 0;
  }
`;
