import React from "react";
import PropTypes from "prop-types";
import Flex from "../Flex";
import { CloseIcon } from "../../images";
import {
  CommentFooter,
  Button,
  Loader,
} from "../ShareCommit/styled";
import { Cancel } from "../../containers/Guidelines/AddGuideline/styled";
import {
  Container, H3, Header, renderIcon, Popup, CloseButton,
} from "./styled";
import UpdateForm from "./UpdateForm";

const NewUpdate = ({
  openNewUpdate,
  progress,
  enableSubmitButton,
  onSubmit,
  onCancel,
  onClose,
  showActionPopup,
  onDismissUpdate,
  onChange,
  inputTitle,
  inputDescription,
  popupTitle,
  actionPopupTitle,
  actionPopupMessage,
}) => {
  return (
    <Flex width="100%">
      { openNewUpdate && (
        <Container className="ignore-click">
          <Popup width="66%">
            <Header>
              <H3>{popupTitle}</H3>
              <CloseButton onClick={() => onClose(false)}>{renderIcon(CloseIcon)}</CloseButton>
            </Header>
            <Flex width="100%" padding="22px">
              <UpdateForm
                onChange={onChange}
                inputTitle={inputTitle}
                inputDescription={inputDescription}
              />
            </Flex>
            <CommentFooter>
              <Flex width="100%" align="center" justify="flex-end" padding="0px 22px 16px 0px">
                <Cancel onClick={() => onCancel(true)}>Cancel</Cancel>
                <Button onClick={onSubmit} disabled={!enableSubmitButton}>Submit</Button>
                {progress && <Loader />}
              </Flex>
            </CommentFooter>
          </Popup>
        </Container>
      )}

      {showActionPopup && (
        <Container className="ignore-click">
          <Popup width="372px">
            <Header>
              <H3>{actionPopupTitle}</H3>
              <CloseButton onClick={() => onCancel(false)}>{renderIcon(CloseIcon)}</CloseButton>
            </Header>
            <Flex width="100%" padding="22px">
              <H3>{actionPopupMessage}</H3>
            </Flex>
            <CommentFooter>
              <Flex width="100%" align="center" justify="flex-end" padding="0px 22px 16px 0px">
                <Cancel onClick={() => onCancel(false)}>Cancel</Cancel>
                <Button onClick={onDismissUpdate}>Delete</Button>
              </Flex>
            </CommentFooter>
          </Popup>
        </Container>

      )}
    </Flex>
  );
};

NewUpdate.propTypes = {
  openNewUpdate: PropTypes.bool.isRequired,
  progress: PropTypes.bool.isRequired,
  enableSubmitButton: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  showActionPopup: PropTypes.bool.isRequired,
  onDismissUpdate: PropTypes.func,
  onChange: PropTypes.func,
  inputDescription: PropTypes.string,
  inputTitle: PropTypes.string,
  popupTitle: PropTypes.string,
  actionPopupTitle: PropTypes.string.isRequired,
  actionPopupMessage: PropTypes.string.isRequired,
};

export default NewUpdate;
