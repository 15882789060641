import { css } from "styled-components/macro";

export const commentField = css`
  position: relative;
  top: 2px;
  border-radius: 18px;
  min-height: 36px;
  font-size: 14px;
  line-height: 21px;
  padding: 6px 12px;
  
  /* FIXME: placeholder color */
  color: ${({ theme }) => theme.palette.primaryText};
  background: ${({ theme }) => theme.palette.secondaryBg};
`;
