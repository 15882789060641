import styled from "styled-components/macro";
import { Arrow } from "../../../images";

export default styled(Arrow)`
  width: 24px;
  height: 24px;
  margin: 0 18px 0 32px;
  &.collapsed {
    transform: rotate(180deg);
  };
`;
