import { axios, API_URL } from "../../utils/api";
import { formatResponseWithMeta, formatResponse } from "../../utils/common";
import { store } from "../../store";
import {
  setGuidelineList, setGuidelineCategoriesList, setApiError, setLoader,
} from "./actions";
import { PER_PAGE_LIMIT } from "./constants";

export const getGuidelines = async ({
  page = 1, sort, order, codingGuidelineCategoryId,
}) => {
  try {
    store.dispatch(setLoader(true));
    const params = {
      page,
      perPage: PER_PAGE_LIMIT,
      order,
      sort,
    };
    if (codingGuidelineCategoryId) {
      params.codingGuidelineCategoryId = codingGuidelineCategoryId;
    }

    const response = await axios.get(`${API_URL}/codingGuidelineRules`, { params });

    if (response.status === 200) {
      const { data, meta: { totalCount = 0 } } = formatResponseWithMeta(response);
      store.dispatch(setGuidelineList({ guidelineList: data, totalCount }));
      return data;
    }

    store.dispatch(setLoader(false));
    return false;
  } catch (error) {
    store.dispatch(setGuidelineList({ guidelineList: [], totalCount: 0 }));
    store.dispatch(setApiError("API failed."));
    return error;
  }
};

export const getDropDownOption = (dataArray) => {
  if (!dataArray || !dataArray.length) {
    return [];
  }

  const guidelineCategoriesList = [];
  const guidelineCategoriesNames = {};
  dataArray.forEach((item) => {
    guidelineCategoriesList.push({ label: item.label, value: item.id });
    guidelineCategoriesNames[item.id] = item.label;
  });
  return { guidelineCategoriesList, guidelineCategoriesNames };
};

export const getGuidelineCategories = async () => {
  try {
    const response = await axios.get(`${API_URL}/codingGuidelineCategories`);

    if (response.status === 200) {
      const { guidelineCategoriesList, guidelineCategoriesNames } = getDropDownOption(formatResponse(response));
      store.dispatch(setGuidelineCategoriesList({ guidelineCategoriesList, guidelineCategoriesNames }));
      return { guidelineCategoriesList, guidelineCategoriesNames };
    }

    return false;
  } catch (error) {
    store.dispatch(setApiError("Get guidelines categories API failed."));
    store.dispatch(setLoader(false));
    return error;
  }
};

export const updateGuideline = async (id, data) => {
  try {
    store.dispatch(setLoader(true));
    return await axios.put(`${API_URL}/codingGuidelineRule/${id}`, data);
  } catch (error) {
    store.dispatch(setApiError("Update guideline API failed."));
    store.dispatch(setLoader(false));
    return false;
  }
};

export const addNewGuideline = async (categoryId, payload) => {
  try {
    store.dispatch(setLoader(true));
    return await axios.post(`${API_URL}/codingGuidelineCategory/${categoryId}/codingGuidelineRule`, payload);
  } catch (error) {
    store.dispatch(setApiError("Add new guideline rule API failed."));
    store.dispatch(setLoader(false));
    return false;
  }
};
