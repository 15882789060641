/* eslint-disable react/jsx-one-expression-per-line */
export const SET_PROJECT_LIST = "src/containers/ProjectsInsights/SET_PROJECT_LIST";
export const SET_PROJECT_ERROR = "src/containers/ProjectsInsights/SET_PROJECT_ERROR";
export const SET_PROJECT_FILTERS = "src/containers/ProjectsInsights/SET_PROJECT_FILTERS";
export const SET_PROJECT_LOADER = "src/containers/ProjectsInsights/SET_PROJECT_LOADER";
export const SET_PROJECT_INITIAL_STATE = "src/containers/ProjectsInsights/SET_PROJECT_INITIAL_STATE";

export const PER_PAGE_LIMIT = 20;
export const PAGE_NEIGHBOURS = 3;

export const tableHeadColumns = [
  {
    key: 'name', title: 'Projects', width: '40%', sort: true,
  },
  {
    key: 'systemRejectedCommitsCount', title: 'Total System Rejected (%)', width: '30%', sort: true,
  },
  {
    key: 'rejectedCommitsCount', title: 'Total Rejected (%)', width: '30%', sort: true,
  },
];
