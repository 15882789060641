import styled from "styled-components/macro";

export default styled.ul`
  display: flex;
  
  & li {
    padding: 0 15px;
    display: flex;
    align-items: center;
  }
  .notification-col {
    position: relative;
  }
  .notification-col .dot {
    background: ${({ theme }) => theme.palette.brandColor};
    content: '';
    height: 10px;
    position: absolute;
    width: 10px;
    border-radius: 50%;
    top: 9px;
    right: 17px;
  }
  .notification-col.active{
    background: ${({ theme }) => theme.palette.notificationActive};
  }
`;
