import React from "react";
import { Loader } from "../Loader";
import { LoaderContainer } from "./styled";

const PageLoader = () => (
  <LoaderContainer data-test="LoaderContainer">
    <Loader loading={true} />
  </LoaderContainer>
);

export default PageLoader;
