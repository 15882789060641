import React from "react";
import PropTypes from "prop-types";
import { CommitTile } from "../../components/CommitTile";
import Flex from "../../components/Flex";
import { withOrgs } from "../withOrgs";
import { Date, Container, CommitCount } from "./styled";
import { formatResponseData } from "./api";
import { userRole } from "../Nav/constants";

const CommitGroup = ({
  date = "",
  commits = [],
  repoName = "",
  repoFullName = "",
  repoId = "",
  openPopup,
  commitsPerDate,
  orgs: {
    active: { role = "" } = {},
  } = {},
}) => {
  return (
    <div>
      {date && (
        <Container row padding="16px" align="center" justify="flex-start">
          <Flex justify="space-between" align="center" flex="1">
            <Date>{date}</Date>
            {role === userRole.Admin && (
              <CommitCount>
                Total Commits:&nbsp;
                {commitsPerDate}
              </CommitCount>
            )}
          </Flex>
        </Container>
      )}
      {commits.map(commitData => (
        <CommitTile
          key={commitData.shortSha}
          {...formatResponseData(commitData, repoName, repoFullName, repoId)}
          openPopup={openPopup}
        />
      ))}
    </div>
  );
};

CommitGroup.propTypes = {
  date: PropTypes.string.isRequired,
  commits: PropTypes.array.isRequired,
  repoName: PropTypes.string.isRequired,
  repoFullName: PropTypes.string.isRequired,
  repoId: PropTypes.string.isRequired,
  openPopup: PropTypes.func.isRequired,
  commitsPerDate: PropTypes.number.isRequired,
  orgs: PropTypes.object.isRequired,
};

export default withOrgs(CommitGroup);
