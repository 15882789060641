import {
  CHANGE_PALETTE,
  PALETTES,
  SET_SCREEN_SIZE,
  BREAKPOINTS,
  TOGGLE_NAVBAR_WIDTH,
  NAVBAR_WIDTH,
} from "./constants";
import { palettes, mixins } from "./themes";

const validatePalette = palette => Object.values(PALETTES).some(item => item === palette);
const screenSizeMap = Object.keys(BREAKPOINTS).reduce((acc, key) => ({ ...acc, [key]: false }), {});
const defaultPalette = localStorage.getItem("theme") || PALETTES.DEFAULT;
const defaultNavbarWidth = localStorage.getItem("navbarWidth") || "LARGE";
document.documentElement.setAttribute('theme', defaultPalette);

export const initialState = {
  activePalette: defaultPalette,
  palette: palettes[defaultPalette],
  mixins: mixins[defaultPalette],
  navbar: {
    width: defaultNavbarWidth === "LARGE" ? NAVBAR_WIDTH.LARGE : NAVBAR_WIDTH.SMALL,
    activeWidth: defaultNavbarWidth,
  },
  screenSizeMap,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PALETTE:
      if (validatePalette(action.palette)) {
        localStorage.setItem("theme", action.palette);
        document.documentElement.setAttribute('theme', action.palette);
        return {
          ...state,
          activePalette: action.palette,
          palette: palettes[action.palette],
          mixins: mixins[action.palette],
        };
      }
      console.error(
        `${action.palette} isn't a valid palette, please choose from ${Object.values(PALETTES).join(", ")}`
      );
      return state;

    case SET_SCREEN_SIZE:
      return { ...state, screenSizeMap: action.screenSizeMap };

    case TOGGLE_NAVBAR_WIDTH: {
      if (state.navbar.width === NAVBAR_WIDTH.LARGE) {
        localStorage.setItem("navbarWidth", "SMALL");
        return {
          ...state,
          navbar: {
            width: NAVBAR_WIDTH.SMALL,
            activeWidth: "SMALL",
          },
        };
      } else {
        localStorage.setItem("navbarWidth", "LARGE");
        return {
          ...state,
          navbar: {
            width: NAVBAR_WIDTH.LARGE,
            activeWidth: "LARGE",
          },
        };
      }
    }

    default:
      return state;
  }
};
