import styled from "styled-components/macro";

export default styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  height: 76px;

  color: ${({ theme }) => theme.palette.sidebarItem};
  & svg path {
    stroke: ${({ theme }) => theme.palette.sidebarItem};
  }

  &:hover {
    & svg path {
      stroke: ${({ theme }) => theme.palette.sidebarItemHover};
    }
    color: ${({ theme }) => theme.palette.sidebarItemHover};
  }
`;
