import styled from "styled-components/macro";
import { MEDIA } from "../../ThemeControl";

export default styled.div`
  display: flex;
  min-height: 100vh;

  /* block responsivness at SM breakpoint */
  /* we are not supporting mobile devices */
  min-width: ${MEDIA.SM};
`;
