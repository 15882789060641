import styled from "styled-components/macro";

export default styled.div`
  text-align: left;
  vertical-align: top;
  color: ${({ theme }) => theme.palette.lineNo};
  user-select: none;
  padding: 2px 8px;
  width: 40%

  // &:nth-child(3) {
  //   padding-left: 16px;
  // }
`;
