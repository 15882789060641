import React from "react";
import styled from "styled-components/macro";

export default (icon) => {
  const Component = styled(icon)`
    height: 24px;
    width: 24px;
    margin: 0 24px 0 32px;
    
    path {
      stroke: none;
    }
  `;

  return <Component />;
};
