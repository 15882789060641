import styled from "styled-components/macro";

export default styled.div`
  min-width: 300px;
  width: auto;
  height: 42px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primaryText};
  border-radius: 6px;
  z-index: 1;
`;
