export default [
  { label: "All", value: 0 },
  { label: "Rejected | To be reviewed", value: 1 },
  { label: "To be reviewed", value: 2 },
  { label: "Rejected", value: 3 },
  { label: "Accepted", value: 4 },
  { label: "Rejected by developers", value: 5 },
  { label: "Rejected by system", value: 6 },
  { label: "Mark as fixed", value: 7 },
];
