import styled from "styled-components/macro";

export default styled.div`
  display: flex;
  align-items: center;
  
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.secondaryText};
  justify-content: flex-end;
`;
