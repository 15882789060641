import { css } from "styled-components/macro";

export default (visible, invisible) => css`
  ${({ state }) => {
    switch (state) {
      case "enter":
        return invisible;

      case "entered":
        return visible;

      case "exiting":
        return visible;

      case "exited":
        return invisible;

      default:
        return "";
    }
  }};
`;
