import React from "react";
import PropTypes from "prop-types";
import Flex from "../Flex";
import {
  Container, Header, Content, renderIcon,
} from "./styled";

const ActionBlock = ({
  type, headerTxt, icon, children,
}) => (
  <Container>
    <Flex>
      <Header type={type}>
        {renderIcon(icon)}
        {headerTxt}
      </Header>
      <div />
    </Flex>
    <Content>{children}</Content>
  </Container>
);

ActionBlock.propTypes = {
  type: PropTypes.string,
  headerTxt: PropTypes.string,
  icon: PropTypes.object,
  children: PropTypes.any,
};

export default ActionBlock;
