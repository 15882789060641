/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { RejectBtnTick } from "../../images";
import { ErrorContainer } from './styled';

const ErrorMessage = ({ message = '', onCancel = () => {} }) => {
  return (
    <ErrorContainer data-test="ErrorComponent">
      {message}
      <span className="closeIcon" onClick={onCancel} data-test="closeIcon">
        <RejectBtnTick data-test="RejectBtnTick" />
      </span>
    </ErrorContainer>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  onCancel: PropTypes.func,
};

export default ErrorMessage;
