import styled from "styled-components/macro";

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.primaryBg};
  width: 32px;
  height: 32px;
  border-radius: 8px;
`;
