import styled, { css } from "styled-components/macro";

export default styled.div`
  margin-bottom: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.codeBg};

  @keyframes blink {
    0% { box-shadow: 0 0 7.5px 10px transparent; }
    10% { box-shadow: 0 0 0 3px ${({ theme }) => theme.palette.brandColor}; }  
    90% { box-shadow: 0 0 0 3px ${({ theme }) => theme.palette.brandColor}; }
    100% { box-shadow: 0 0 7.5px 10px transparent; }
  }

  ${({ blink }) => {
    if (!blink) return;
    return css`
      animation-name: blink;
      animation-duration: 5s;
      animation-delay: 1.25s;
    `;
  }}
  
  & .custom-scroll > div:nth-child(2) > div {
    background-color: ${({ theme }) => theme.palette.brandColor} !important;
  }
`;
