import styled from "styled-components";

export default styled.div`
  margin-left: 16px;

  & > button {
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.palette.primaryText};
    margin-left: 16px;

    :first-child {
      margin-left: 0;
    }
  }
`;
