import React, { useEffect, useReducer } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import PropTypes from "prop-types";
import Flex from "../Flex";
import '../../GlobalStyle/mention.scss';

import {
  CommentFooter,
  Button,
  Loader,
  Instructions,
  OptionsBlock,
} from "./styled";

const CommentBody = ({
  value,
  placeholder,
  loading,
  onSubmit,
  children,
  organizationMembers,
  inputCommentRef,
  autoFocus,
  buttonValue,
  position,
  setUnSaveComments,
  unSaveComments = [],
}) => {
  const initialState = {
    suggestions: [],
    editorValue: '',
  };

  const reducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const [state, setState] = useReducer(reducer, initialState);
  const { suggestions, editorValue } = state;

  useEffect(() => {
    setState({ editorValue: value });
  }, [value]);

  useEffect(() => {
    if (unSaveComments) {
      const inlineComment = unSaveComments.find(x => x.position === position);
      if (inlineComment) {
        setState({ editorValue: inlineComment.value });
      }
    }
  }, [position, unSaveComments]);

  useEffect(() => {
    setState({ suggestions: organizationMembers });
  }, [organizationMembers]);

  const renderSuggestion = (suggestion,
    search,
    highlightedDisplay,
    index,
    focused) => {
    return (
      <div className={`user ${focused ? 'focused' : ''}`}>
        {highlightedDisplay}
      </div>
    );
  };

  const onChange = (e) => {
    if (setUnSaveComments && unSaveComments) {
      const elementsIndex = unSaveComments.findIndex(x => x.position === position);
      if (elementsIndex >= 0) {
        // eslint-disable-next-line no-param-reassign
        unSaveComments[elementsIndex].value = e.target.value;
      } else {
        unSaveComments.push({
          position,
          value: e.target.value,
        });
      }
      setUnSaveComments(unSaveComments);
    }
    setState({ editorValue: e.target.value });
  };

  const onPressEnter = ({ which, shiftKey }) => {
    if (which === 13 && shiftKey === false) {
      onSubmit();
    }
  };

  return (
    <Flex column width="100%">
      <Flex width="100%" position="relative">
        <MentionsInput
          placeholder={placeholder}
          className="mention-comment-col"
          value={editorValue}
          onChange={onChange}
          inputRef={inputCommentRef}
          autoFocus={autoFocus}
          onKeyPress={e => onPressEnter(e)}
        >
          <Mention
            className="rc-editor-text"
            displayTransform={display => `@${display}`}
            trigger="@"
            data={suggestions}
            renderSuggestion={renderSuggestion}
          />
        </MentionsInput>
        <OptionsBlock>
          {children}
        </OptionsBlock>
      </Flex>
      <CommentFooter>
        <Flex>
          <Button isVisible={!!editorValue} onClick={onSubmit} disabled={loading}>{buttonValue}</Button>
          {loading && <Loader />}
        </Flex>
        <Instructions>**bold1** _italics_ `code` ```performatted``` &gt;quote</Instructions>
      </CommentFooter>
    </Flex>
  );
};

CommentBody.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.any,
  organizationMembers: PropTypes.array.isRequired,
  inputCommentRef: PropTypes.object,
  autoFocus: PropTypes.bool,
  buttonValue: PropTypes.string.isRequired,
  position: PropTypes.number,
  setUnSaveComments: PropTypes.func,
  unSaveComments: PropTypes.array,
};

export default CommentBody;
