export default {
  brandColor: "#ED663F",
  bodyBg: "#F5F6FA",
  primaryBg: "#FFFFFF",
  secondaryBg: "#E4E6EF",
  primaryBgHover: "#CCCED6",
  primaryText: "#31394D",
  mainText: "#B1B5C8",
  secondaryText: "#748AA1",
  infoText: "#748AA1",

  sidebarBg: "#FFFFFF",
  sidebarBorder: "#CCCED6",
  sidebarButtonBgHover: "#ED663F",

  linkBorder: "#E3E5EC",
  linkBg: "transparent",
  guideColor: "#B1B5C8",
  guideHover: "#ED663F",
  statusTileBorder: "#CCCED6",
  paginationText: "#B1B5C8",
  paginationHoverText: "#FFFFFF",
  instructionText: "#31394D",
  loaderBg: "#B1B5C8",
  notificationActive: "#CCCED6",

  dropdownText: "#31394D",
  dropdownBg: "#CCCED6",
  dropdownChoiceBG: "#FFFFFF",
  dropdownBorder: "#DBDEEB",
  dropdownHover: "#CCCED6",
  overlayBg: "rgba(22, 25, 28, 0.5)",
  dropdownSecondaryBg: "#CCCED6",

  sidebarItemHover: "#020833",

  paletteSwitch: "#748AA1",
  topPanelBorder: "#DBDEEB",

  code: "#020931",
  codeBg: "#FFFFFF",
  codeTabline: "rgba(27,31,35,.7)",
  codeTablineBg: "#f0f7ff",
  codeHeaderBg: "#CCCED6;",
  codeHeaderText: "#FFFFFF",
  codeAddition: "#e4ffe9",
  codeDeletion: "#ffeaeb",
  codeAdditionBg: "#EAFFEA",
  codeAdditionActionBg: "#CDFFD8",
  codeDeletionActionBg: '#FFDCE0',
  codeDeletionBg: '#FFECEC',
  lineHover: "rgba(0, 0, 0, 0.1)",

  formHeaderBg: "#B1B5C8",
  formBg: "#FFFFFF",
  formControlBg: "#CCCED6",
  formText: "#CCCED6",

  linkColor: "#0366D6",
  deleteOverlayBg: "rgba(0, 0, 0, 0.2)",

  codeBorder: "rgba(0, 0, 0, 0.4)",
  calenderShadow: "rgba(0, 0, 0, 0.2) 0px 1px 4px",
  textWhite: "#FFFFFF",
  iconHoverStatae: "#020833",
  iconColor: "#545F69",
};
