import styled from "styled-components/macro";


export default styled.p`
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;

  /* FIXME: placeholder color */
  color: ${({ theme }) => theme.palette.instructionText};
  align-self: flex-start;
  justify-self: self-end;
`;
