import React from "react";
import PropTypes, { oneOfType } from "prop-types";
import ContentBlock from "../ContentBlock";
import { Container, Logo } from "./styled";

const Layout = ({ children }) => (
  <Container>
    <Logo />
    <ContentBlock noShadow padding="47px 54px 64px" align="center">
      {children}
    </ContentBlock>
  </Container>
);

Layout.propTypes = {
  children: oneOfType([PropTypes.element, PropTypes.node]),
};

export default Layout;
