import React from "react";
import PropTypes from "prop-types";
import { Container, Spinner } from "./styled";

const Loader = ({ height = "100%", forceLightTheme, size = "medium" }) => (
  <Container height={height} forceLightTheme={forceLightTheme}>
    <Spinner size={size} forceLightTheme={forceLightTheme} />
  </Container>
);

Loader.propTypes = {
  forceLightTheme: PropTypes.bool,
  height: PropTypes.string,
  size: PropTypes.string,
};

export default Loader;
