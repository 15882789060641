/* eslint-disable no-param-reassign, no-loop-func, no-cond-assign,no-useless-escape, react-hooks/exhaustive-deps, react/jsx-closing-tag-location,  react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { TrelloIcon } from "../../images";
import { Title, RenderIcon } from "./styled";

const regex = /\bhttps?:\/\/\S+/gi;

const CommitMessage = ({
  source: commitMessage,
  commitLink: pathLink,
  trelloCardName,
}) => {
  const ExtractLinksFromText = () => {
    const commitUrl = `${pathLink.pathname}?${pathLink.search}`;
    if (new RegExp("https?:\/\/(www\.)?([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(commitMessage)) {
      let m;
      const NewMessage = [];
      while ((m = regex.exec(commitMessage)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex += 1;
        }

        m.forEach((match, index) => {
          const content = commitMessage.slice(0, m.index);
          commitMessage = commitMessage.replace(content, '').replace(match, '');
          NewMessage.push(<Link key={index} to={commitUrl}>{content}</Link>);
          NewMessage.push(<br></br>);
          NewMessage.push(<a className="trelloLink" key={`icon-${commitUrl}`} href={match} target="_blank" rel="noopener noreferrer">
            {RenderIcon(TrelloIcon)}
            {' '}
            {trelloCardName}
          </a>);
          m = {};
          regex.lastIndex = 0;
        });
      }
      return NewMessage;
    } else {
      return <Link to={commitUrl}>{commitMessage}</Link>;
    }
  };

  return (
    <Title><ExtractLinksFromText /></Title>
  );
};

CommitMessage.propTypes = {
  source: PropTypes.string.isRequired,
  commitLink: PropTypes.object.isRequired,
  trelloCardName: PropTypes.string,
};

export default CommitMessage;
