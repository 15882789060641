import styled from "styled-components/macro";

export default styled.input`
  background: ${({ theme }) => theme.palette.dropdownSecondaryBg};
  padding: 12px;
  border: 0px;
  border-radius: 6px;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  /* identical to box height */

  /* White */
  color: ${({ theme }) => theme.palette.primaryText};;
`;
