import styled from "styled-components/macro";

export default styled.div`
  display: flex;
  margin: 12px 0 6px 0px;
  align-items: center;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.secondaryText};
  justify-content: flex-end;
`;
