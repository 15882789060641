import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentWrapper from "../../components/ContentWrapper";
import { Pagination } from "../../components/Pagination/Pagination";
import PageLoader from "../../components/PageLoader";
import { Table, Header } from "../../components/Guidelines";
import useGetGuidelines from "./hooks/guidelines";
import useGetCategories from "./hooks/categories";
import AddGuideline from "./AddGuideline/AddGuideline";
import { tableHeadColumns, PER_PAGE_LIMIT, PAGE_NEIGHBOURS } from "./constants";
import { setFilters } from "./actions";
import { updateGuideline, getGuidelines, addNewGuideline } from "./api";
import { TableContainer, SuccessMsg } from "./styled";
import ApiError from "./ApiError";

const Guidelines = () => {
  const { filters, loader } = useSelector(store => store.guideline);
  const dispatch = useDispatch();
  const { guidelineList, totalCount } = useGetGuidelines();
  const { guidelineCategoriesList, guidelineCategoriesNames } = useGetCategories();
  const initialState = {
    openNewUpdate: false,
    categoryId: 0,
    updateFlag: false,
    inputValue: null,
    isOpenCategory: false,
    selectedCategory: 0,
  };
  const [{
    categoryId, updateFlag, inputValue, openNewUpdate, isOpenCategory, selectedCategory,
  }, setState] = useState(initialState);

  const setInputValue = (value) => {
    setState(state => ({ ...state, inputValue: value }));
  };

  const onPageChanged = ({ currentPage }) => {
    if (filters.page !== currentPage) {
      filters.page = currentPage;
      dispatch(setFilters(filters));
      window.scroll(0, 100);
    }
  };

  const openCategoryDropDown = (flag) => {
    setState(state => ({ ...state, isOpenCategory: flag }));
  };

  const onChangeCategory = (catId) => {
    setState(state => ({ ...state, selectedCategory: catId }));
    filters.codingGuidelineCategoryId = catId;
    filters.page = 1;
    dispatch(setFilters(filters));
  };

  const openDropDown = (id) => {
    setState(state => ({ ...state, categoryId: categoryId === id ? 0 : id }));
  };

  const onChange = async (id, payload) => {
    const response = await updateGuideline(id, payload);
    if (response) {
      await getGuidelines(filters);
      setState(state => ({ ...state, updateFlag: true }));
      setTimeout(() => {
        setState(state => ({ ...state, updateFlag: false }));
      }, 5000);
    }
  };

  const onSubmit = async ({ catId, link, label }) => {
    const response = await addNewGuideline(catId, { label, link });
    if (response) {
      getGuidelines(filters);
    }
  };

  const handleNewGuideline = (isOpen) => {
    setState({
      openNewUpdate: isOpen,
      form: {
        label: "",
        link: "",
      },
    });
  };

  return (
    <ContentWrapper data-test="ContentWrapper">
      { updateFlag && (<SuccessMsg>Changes Saved</SuccessMsg>) }
      { openNewUpdate && (
        <AddGuideline
          heading="Add new guideline rule"
          onClose={() => handleNewGuideline(false)}
          onCancel={() => handleNewGuideline(false)}
          onSubmit={onSubmit}
          progress={false}
        />
      )}
      <Header
        isOpenCategory={isOpenCategory}
        selectedCategory={selectedCategory}
        openCategoryDropDown={openCategoryDropDown}
        onChangeCategory={onChangeCategory}
        guidelineCategoriesList={guidelineCategoriesList}
        guidelineCategoriesNames={guidelineCategoriesNames}
        handleNewGuideline={handleNewGuideline}
      />

      { (!guidelineList || loader) && <PageLoader /> }
      <TableContainer>
        <ApiError />
        { guidelineList && (
          <Table
            data-test="guidelineTable"
            guidelineList={guidelineList}
            tableHeadColumns={tableHeadColumns}
            guidelineCategoriesList={guidelineCategoriesList}
            guidelineCategoriesNames={guidelineCategoriesNames}
            categoryId={categoryId}
            openDropDown={openDropDown}
            onChange={onChange}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
        )}
        { (!!totalCount && !loader) && (
          <Pagination
            currentPage={filters.page}
            totalRecords={totalCount}
            pageLimit={PER_PAGE_LIMIT}
            pageNeighbours={PAGE_NEIGHBOURS}
            onPageChanged={onPageChanged}
          />
        )}
      </TableContainer>
    </ContentWrapper>
  );
};

export default Guidelines;
