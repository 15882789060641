import styled from "styled-components/macro";

export default styled.div`
  width: 100%;
  margin-top: 20px;
  // Override some Bootstrap pagination styles
  & ul li {
    float: left;
    color: ${({ theme }) => theme.palette.paginationText};
  }

  & ul.pagination {
    margin-top: 0;
    margin-bottom: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

    & li.page-item.active a.page-link {  
      color: ${({ theme }) => theme.palette.paginationHoverText};
      border: 1px solid ${({ theme }) => theme.palette.bodyBg};
      background: ${({ theme }) => theme.palette.brandColor};
    }

    & a.page-link {
      background: ${({ theme }) => theme.palette.primaryBg};
      padding: 0.75rem 1rem;
      min-width: 3.5rem;
      text-align: center;
      box-shadow: none !important;
      border: 1px solid ${({ theme }) => theme.palette.bodyBg};
      color: ${({ theme }) => theme.palette.paginationText};
      font-weight: 900;
      font-size: 1rem;
      & :hover {
        background: ${({ theme }) => theme.palette.brandColor};
        color: ${({ theme }) => theme.palette.paginationHoverText};
      }
    }
  }
`;
