import React from "react";
import PropTypes from "prop-types";
import { getUserData } from "../../utils/helpers/userData";
import { withTheme, PALETTES } from "../ThemeControl";
import {
  Profile,
  Notifications,
  Search,
  ThemeSwitchIcon,
} from "../../components/TopPanelList";
import { PaletteSwitch } from "../../components/PaletteSwitch";
import { OrgSwitch } from "../OrgSwitch";
import { UpdatesSideBar } from "../UpdatesSideBar";
import { Notification } from "../Notification";
import { Container, TopPanelListContainer } from "./styled";

class TopPanel extends React.Component {
  state = { notificationActive: false, newNotificationFlag: 0 };

  handlePaletteSwitch = () => {
    const {
      theme: { activePalette },
      changePalette,
    } = this.props;
    if (activePalette === PALETTES.DEFAULT) {
      changePalette(PALETTES.LIGHT);
    } else {
      changePalette(PALETTES.DEFAULT);
    }
  };

  setNotificationFlag = (flag) => {
    this.setState({ newNotificationFlag: flag });
  };

  notificationToggle = () => {
    const { notificationActive, newNotificationFlag } = this.state;
    this.setState({ notificationActive: !notificationActive });
    if (newNotificationFlag && notificationActive) {
      this.setNotificationFlag(0);
    }
  };

  notificationClose = () => {
    const { notificationActive } = this.state;
    if (notificationActive) {
      this.notificationToggle();
    }
  };

  render() {
    const { photo, name } = getUserData() || {};
    const { notificationActive, newNotificationFlag } = this.state;
    return (
      <Container>
        <OrgSwitch />
        <UpdatesSideBar />
        <Notification
          notificationActive={notificationActive}
          setNotificationFlag={this.setNotificationFlag}
          notificationClose={this.notificationClose}
        />
        <nav>
          <TopPanelListContainer>
            {/* FEATURE FLAG */}
            {false && (
              <li>
                <Search />
              </li>
            )}
            <li className={`notification-col ${notificationActive ? 'active' : ''}`}>
              <Notifications
                newNotificationFlag={newNotificationFlag}
                setToggleOpen={this.notificationToggle}
              />
            </li>
            {false && (
              <li>
                <ThemeSwitchIcon />
              </li>
            )}
            <li>
              <PaletteSwitch switchPalette={this.handlePaletteSwitch} />
            </li>
            <li>
              <Profile avatarIcon={photo} userName={name} />
            </li>
          </TopPanelListContainer>
        </nav>
      </Container>
    );
  }
}

TopPanel.propTypes = {
  theme: PropTypes.object.isRequired,
  changePalette: PropTypes.func.isRequired,
};

export default withTheme(TopPanel);
