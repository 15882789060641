import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ROUTES } from "../Routes";
import ContentWrapper from "../../components/ContentWrapper";
import { PathHeader } from "../../components/PathHeader";
import { Pagination } from "../../components/Pagination/Pagination";
import PageLoader from "../../components/PageLoader";
import { Table } from "../../components/ManageProjects";
import Flex from "../../components/Flex";
import useGetProjectRows from "./hooks/projects";
import useGetTechnologies from "./hooks/technology";
import { tableHeadColumns, PER_PAGE_LIMIT, PAGE_NEIGHBOURS } from "./constants";
import { setFilters } from "./actions";
import { updateProject, getProjects } from "./api";
import {
  TableContainer,
  SuccessMsg,
  SearchContainer,
  Input,
} from "./styled";
import ApiError from "./ApiError";

const ManageProjects = () => {
  const { filters, loader } = useSelector(store => store.projects);
  const dispatch = useDispatch();
  const { projectList, totalCount } = useGetProjectRows();
  const { technologyList, technologyNames } = useGetTechnologies();
  const [{ technologyId, updateFlag, channelName }, setState] = useState({ technologyId: 0, updateFlag: false, channelName: null });

  const setChannelName = (value) => {
    setState(state => ({ ...state, channelName: value }));
  };

  const onPageChanged = ({ currentPage }) => {
    if (filters.page !== currentPage) {
      filters.page = currentPage;
      dispatch(setFilters(filters));
      window.scroll(0, 100);
    }
  };

  const onChangeSort = ({ column, order }) => {
    if (filters.order !== column) {
      filters.order = column;
      filters.sort = 'asc';
      dispatch(setFilters(filters));
    } else if (filters.sort !== order) {
      filters.sort = order;
      dispatch(setFilters(filters));
    }
  };

  const openTechnology = (id) => {
    setState(state => ({ ...state, technologyId: technologyId === id ? 0 : id }));
  };

  const onChange = async (projectId, payload) => {
    const response = await updateProject(projectId, payload);
    if (response) {
      await getProjects(filters);
      setState(state => ({ ...state, updateFlag: true }));
      setTimeout(() => {
        setState(state => ({ ...state, updateFlag: false }));
      }, 5000);
    }
  };

  const onSearchProject = async (key, project) => {
    if (key === 'Enter') {
      if (project.trim().length >= 3) {
        filters.name = project.trim();
      } else {
        filters.name = '';
      }
      dispatch(setFilters(filters));
      await getProjects(filters);
    }
  };

  return (
    <ContentWrapper data-test="ContentWrapper">
      { updateFlag && (<SuccessMsg>Changes Saved</SuccessMsg>) }
      <Flex justify="space-between">
        <PathHeader pathItems={[{ label: "Manage projects", link: ROUTES.MANAGE_PROJECTS }]} />
        <SearchContainer>
          <Flex justify="space-between">
            <Input
              type="text"
              placeholder="Search for project"
              onKeyDown={({ key, target: { value } }) => onSearchProject(key, value)}
            />
          </Flex>
        </SearchContainer>
      </Flex>
      { (!projectList || loader) && <PageLoader /> }
      <TableContainer>
        <ApiError />
        { projectList && (
          <Table
            data-test="projectTable"
            projectList={projectList}
            tableHeadColumns={tableHeadColumns}
            technologyList={technologyList}
            technologyNames={technologyNames}
            technologyId={technologyId}
            onChangeSort={onChangeSort}
            openTechnology={openTechnology}
            onChange={onChange}
            channelName={channelName}
            setChannelName={setChannelName}
          />
        )}
        { (!!totalCount) && (
          <Pagination
            currentPage={filters.page}
            totalRecords={totalCount}
            pageLimit={PER_PAGE_LIMIT}
            pageNeighbours={PAGE_NEIGHBOURS}
            onPageChanged={onPageChanged}
          />
        )}
      </TableContainer>
    </ContentWrapper>
  );
};

export default ManageProjects;
