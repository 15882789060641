import { CHANGE_PALETTE, SET_SCREEN_SIZE, TOGGLE_NAVBAR_WIDTH } from "./constants";

export const changePalette = palette => ({
  type: CHANGE_PALETTE,
  palette,
});

export const setScreenSize = screenSizeMap => ({
  type: SET_SCREEN_SIZE,
  screenSizeMap,
});

export const toggleNavbar = () => ({
  type: TOGGLE_NAVBAR_WIDTH,
});
