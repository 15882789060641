import React from "react";
import PropTypes from "prop-types";
import { NavItem } from "../NavItem";

const NavList = ({ navItems, classToggle }) => (
  <nav>
    <ul>
      {navItems.map(props => (
        <NavItem {...props} classToggle={classToggle} />
      ))}
    </ul>
  </nav>
);

NavList.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  classToggle: PropTypes.string.isRequired,
};

export default NavList;
