import styled from "styled-components/macro";

export default styled.div`
  padding: 6px 8px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 14px;
  color: white;
  white-space: pre;

  background: ${(props) => {
    switch (props.status) {
      case 0: return props.theme.palette.commitWaiting;
      case 1: return props.theme.palette.commitAccepted;
      case 2: return props.theme.palette.commitRejected;
      case 3: return props.theme.palette.commitPassed;
      default: return "none";
    }
  }};
`;
