import React from "react";
import PropTypes from "prop-types";
import { Logo, Content, Container } from "./styled";

const LoginButton = ({ children, ...props }) => (
  <Container {...props}>
    <Logo />
    <Content>{children}</Content>
  </Container>
);

LoginButton.propTypes = {
  children: PropTypes.string.isRequired,
};

export default LoginButton;
