import { axios, API_URL } from "../../utils/api";
import { getUserData } from "../../utils/helpers/userData";
import { setOrganizationData } from "../../utils/helpers/organizationData";
import { store } from "../../store";
import { setOrganizations, setMemberList } from "./actions";

export const formatResponse = ({ data: { data = [] } = {} } = {}) => data;
const dispatchOrgs = payload => store.dispatch(setOrganizations(payload));

export const getOrgs = async () => {
  const { name } = getUserData();
  const response = await axios.get(`${API_URL}/member/${name}/organizations`);

  if (response.status === 200) {
    const data = formatResponse(response);
    dispatchOrgs(data);
    setOrganizationData(data);
    return data;
  }

  return [];
};


export const getOrgMembers = async () => {
  const response = await axios.get(`${API_URL}/organization/members`);

  if (response.status === 200) {
    const data = formatResponse(response);
    store.dispatch(setMemberList(data));
    return data;
  }

  return [];
};
