import React from "react";
import PropTypes from "prop-types";
import { CommitCommentsList } from "./CommentsList";
// import { withTheme, PALETTES } from "containers/ThemeControl";
// import {
//   GuidelinesLinkStatusIconBW,
//   GuidelinesLinkStatusIconLightGreyBW,
//   ShareStatusIconBW,
//   ShareStatusIconLightGreyBW,
//   LikeStatusIconBW,
//   LikeStatusIconLightGreyBW,
//   CommentStatusIconBW,
//   CommentStatusIconLightGreyBW,
// } from "images";
// import { Container } from "./StatusTile/styled";
// import { StatusTile } from "./StatusTile";
import { ButtonsContainer } from "./Button/styled";
import { TitleBar } from "./TitleBar";
import { FileBlock } from "./FileBlock";
import { InfoBar, NavIcon } from "./styled";
import { Btn } from "./Button";
import { FilterBox } from "../../containers/Commits/styled";
import { Container } from "./TitleBar/styled";
import Flex from "../Flex";
import { ROUTES } from "../../containers/Routes";


const CommitDetailLayout = ({
  sha = "",
  teamMember: { avatarUrl = "", login = "", id = "" } = {},
  previousCommit = {},
  nextCommit = {},
  date = "",
  infoText = "",
  files = [],
  comments = [],
  onAccept,
  onReject,
  fetchCommitDetail,
  toggleActionBlock,
  shouldActionBlockOpen,
  isCommitAuthor,
  reviewerId,
  status,
  onMarkAsFixed,
  copyFileLink,
  openFileLink,
  commitImageUrl,
  buttonShare,
  onCancelShowOff,
  containerShowOff,
  setState,
  reviewedBy = "",
  copyShaToClipboard,
  parentCommits,
  openParentCommitLink,
  enableHighlighter,
  enablePreviousCommitButton,
  enableNextCommitButton,
  openPopup,
// theme: { activePalette },
}) => {
  const openPreviousCommitLink = (commitData) => {
    // eslint-disable-next-line max-len
    if (commitData.sha) {
      const link = encodeURI(`${ROUTES.COMMIT}?repoId=${commitData.repositoryId}&repoFullName=${commitData.repoFullName}&repoName=${commitData.repositoryName}&sha=${previousCommit.sha}&uuid=${previousCommit.uuid}`);
      window.open(link, '_self');
    } else {
      openPopup('This is first commit');
    }
  };
  const openNextCommitLink = (commitData) => {
    // eslint-disable-next-line max-len
    if (commitData.sha) {
      const link = encodeURI(`${ROUTES.COMMIT}?repoId=${commitData.repositoryId}&repoFullName=${commitData.repoFullName}&repoName=${commitData.repositoryName}&sha=${commitData.sha}&uuid=${commitData.uuid}`);
      window.open(link, '_self');
    } else {
      openPopup('This is last commit');
    }
  };

  const isRejectedBySystem = !reviewerId && status === 2;
  return (
    <>
      <TitleBar
        sha={sha}
        status={status}
        reviewedBy={reviewedBy}
        avatarUrl={avatarUrl}
        name={login}
        date={date}
        previousCommit={previousCommit}
        nextCommit={nextCommit}
        copyShaToClipboard={copyShaToClipboard}
        parentCommits={parentCommits}
        openParentCommitLink={openParentCommitLink}
        enablePreviousCommitButton={enablePreviousCommitButton}
        enableNextCommitButton={enableNextCommitButton}
      />
      <InfoBar>{infoText}</InfoBar>
      {files.map((file, fileIndex) => (
        <FileBlock
          {...file}
          committerId={id}
          toggleActionBlock={toggleActionBlock}
          shouldActionBlockOpen={shouldActionBlockOpen}
          fetchCommitDetail={fetchCommitDetail}
          key={file.sha}
          fileIndex={fileIndex}
          copyFileLink={copyFileLink}
          openFileLink={openFileLink}
          commitImageUrl={commitImageUrl}
          buttonShare={buttonShare}
          onCancelShowOff={onCancelShowOff}
          containerShowOff={containerShowOff}
          commitAuthor={login}
          setState={setState}
          enableHighlighter={enableHighlighter}
        />
      ))}
      {/* <Container>
        <StatusTile
          Icon={activePalette === PALETTES.DEFAULT
            ? GuidelinesLinkStatusIconBW : GuidelinesLinkStatusIconLightGreyBW}
        />
        <StatusTile
          Icon={activePalette === PALETTES.DEFAULT
            ? CommentStatusIconBW
            : CommentStatusIconLightGreyBW}
        />
        <StatusTile
          Icon={activePalette === PALETTES.DEFAULT
            ? LikeStatusIconBW
            : LikeStatusIconLightGreyBW}
        />
        <StatusTile
          Icon={activePalette === PALETTES.DEFAULT
            ? ShareStatusIconBW
            : ShareStatusIconLightGreyBW}
        />
      </Container> */}
      <CommitCommentsList fetchCommitDetail={fetchCommitDetail} comments={comments} committerId={id} sha={sha} />
      <Flex justify="space-between" flex="1">
        <Flex column flex="1" margin="15px 100px 0 100px" padding="0 16px 0 0">
          <Container>
            <Container>
              <ButtonsContainer>
                {
                  localStorage.getItem('previousPage') && localStorage.getItem('previousPage') === '/commits'
                    && <NavIcon disabled={!enablePreviousCommitButton} onClick={() => openPreviousCommitLink(previousCommit)} title="Previous commit" />
                }
              </ButtonsContainer>
            </Container>
          </Container>
        </Flex>
        <Flex column flex="2">
          <FilterBox>
            <Container>
              {!isCommitAuthor && (
                <ButtonsContainer>
                  {([0, 1, 3].some(item => item === status) || isRejectedBySystem) && <Btn label="reject" type="reject" onClick={onReject} />}
                  {(status === 0 || isRejectedBySystem) && <Btn label="accept" type="accept" onClick={onAccept} />}
                  {status === 2 && !isRejectedBySystem && <Btn label="mark as fixed" type="mark" onClick={onMarkAsFixed} />}
                </ButtonsContainer>
              )}
            </Container>
          </FilterBox>
        </Flex>
        <Flex column flex="1" margin="15px 0 0 200px" padding="0 0 0 16px">
          <ButtonsContainer>
            {
              localStorage.getItem('previousPage') && localStorage.getItem('previousPage') === '/commits'
                && <NavIcon disabled={!enableNextCommitButton} onClick={() => openNextCommitLink(nextCommit)} className="collapsed" title="Next commit" />
            }
          </ButtonsContainer>
        </Flex>
      </Flex>
    </>
  );
};

CommitDetailLayout.propTypes = {
  sha: PropTypes.string,
  infoText: PropTypes.string,
  date: PropTypes.string,
  teamMember: PropTypes.shape({
    avatarUrl: PropTypes.string,
    login: PropTypes.string,
    id: PropTypes.number,
  }),
  files: PropTypes.arrayOf(PropTypes.object),
  comments: PropTypes.arrayOf(PropTypes.object),
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  fetchCommitDetail: PropTypes.func,
  toggleActionBlock: PropTypes.func,
  shouldActionBlockOpen: PropTypes.func,
  isCommitAuthor: PropTypes.bool,
  status: PropTypes.number,
  onMarkAsFixed: PropTypes.func,
  reviewerId: PropTypes.number,
  copyFileLink: PropTypes.func.isRequired,
  openFileLink: PropTypes.func.isRequired,
  onCancelShowOff: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
  copyShaToClipboard: PropTypes.func.isRequired,
  openParentCommitLink: PropTypes.func.isRequired,
  buttonShare: PropTypes.object,
  containerShowOff: PropTypes.object,
  parentCommits: PropTypes.array,
  reviewedBy: PropTypes.string,
  commitImageUrl: PropTypes.string,
  // theme: PropTypes.object.isRequired,
  enableHighlighter: PropTypes.bool.isRequired,
  previousCommit: PropTypes.object,
  nextCommit: PropTypes.object,
  enablePreviousCommitButton: PropTypes.bool.isRequired,
  enableNextCommitButton: PropTypes.bool.isRequired,
  openPopup: PropTypes.func.isRequired,
};

CommitDetailLayout.defaultProps = {
  parentCommits: [],
  reviewedBy: "",
  commitImageUrl: "",
  buttonShare: {},
  containerShowOff: {},
};

// export default withTheme(CommitDetailLayout);
export default CommitDetailLayout;
