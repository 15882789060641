import React from "react";
import PropTypes from "prop-types";
import Flex from "../Flex";
import {
  Container,
  Avatar,
  Title,
  Subtitle,
  Options,
  Overlay,
} from "./styled";

const Comment = ({
  children,
  avatar,
  login,
  headerMessage,
  className,
  isCommentAuthor,
  onEdit,
  onDelete,
  deletePending,
  editButtonText,
}) => (
  <Container className={className}>
    <Overlay visible={deletePending} />
    <Avatar src={avatar} />
    <Flex column width="100%">
      <Flex margin="0 0 8px 9px">
        <Title>{login}</Title>
        <Subtitle>{headerMessage}</Subtitle>
        {isCommentAuthor && (
          <Options>
            <button type="button" onClick={onEdit}>{editButtonText}</button>
            <button type="button" onClick={onDelete}>delete</button>
          </Options>
        )}
      </Flex>
      {children}
    </Flex>
  </Container>
);

Comment.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  avatar: PropTypes.string.isRequired,
  login: PropTypes.string.isRequired,
  headerMessage: PropTypes.string.isRequired,
  className: PropTypes.string,
  isCommentAuthor: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  deletePending: PropTypes.bool,
  editButtonText: PropTypes.string.isRequired,
};

export default Comment;
