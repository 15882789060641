import styled from "styled-components/macro";

export default styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin: 20px 0 0px 16px;
  color: ${({ theme }) => theme.palette.mainText};
  display: flex;
  align-items: flex-start;
`;
