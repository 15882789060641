import styled from "styled-components/macro";

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex-grow: 1;

  width: 100%;
  height: 80%;
`;
