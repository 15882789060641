import React from "react";
import PropTypes from "prop-types";
import Flex from "../../Flex";
import { Label, Count, IconWrapper } from './styled';

const CommitCount = ({ label = "", count = 0, Icon }) => (
  <Flex column align="center">
    <Label>{label}</Label>
    <Flex width="100%">
      <IconWrapper><Icon /></IconWrapper>
      <Count>{count}</Count>
    </Flex>
  </Flex>
);

CommitCount.propTypes = {
  label: PropTypes.string.isRequired,
  count: PropTypes.string.isRequired,
  Icon: PropTypes.object.isRequired,
};

export default CommitCount;
