import styled, { css } from "styled-components/macro";

export default styled.div`
  display: flex;
  ${({ position }) => position && css`position: ${position};`};
  flex-direction: ${({ column }) => column ? "column" : "row"};
  justify-content: ${({ justify }) => justify || "flex-start"};
  align-items: ${({ align }) => align || "flex-start"};
  padding: ${({ padding }) => padding || "0px"};
  margin: ${({ margin }) => margin || "0px"};
  width: ${({ width }) => width ? width : "auto"};
  max-width: ${({ maxWidth }) => maxWidth ? maxWidth : "auto"};
  full-width: ${({ fullWidth }) => fullWidth ? fullWidth : "100%"};
  min-width: ${({ minWidth }) => minWidth ? minWidth : "auto"};
  flex: ${({ flex }) => flex ? flex : "initial"};
  flex-wrap: ${({ wrap }) => wrap ? "wrap" : "no-wrap"};
`;
