import styled from "styled-components/macro";

export default styled.h2`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.secondaryText};
  &.active {
    color: ${({ theme }) => theme.palette.primaryText};
  }
`;
