import styled from "styled-components/macro";

export default styled.div`
  position: absolute;
  top: 70px;
  max-width: 222px;
  width: 100%;
  background: ${({ theme }) => theme.palette.dropdownChoiceBG};
  border: 1px solid ${({ theme }) => theme.palette.dropdownBorder};
  border-top: none;
  border-radius: 0 0 8px 8px;
`;
