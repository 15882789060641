import React from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import { Loader } from "../Loader";
import { BlockContainer, ViewMore } from "./styled";
import NotificationTile from "./NotificationTile";

export const NotificationBlock = ({
  notifications, viewMore, loading, hasMore, notificationLastSeen, notificationClose,
}) => {
  return (
    <BlockContainer className="notificationArea">
      <Scrollbars className="custom-scroll" autoHeight autoHeightMax="93vh">
        {notifications.map(notification => (
          <NotificationTile
            notificationClose={notificationClose}
            notificationLastSeen={notificationLastSeen}
            key={notification.id.toString()}
            {...notification}
          />
        ))}
        <ViewMore onClick={viewMore}>
          {
            loading
              ? (<div className="loader"><Loader loading={true} size="small" /></div>)
              : (hasMore && "See More")
          }
        </ViewMore>
      </Scrollbars>
    </BlockContainer>
  );
};

NotificationBlock.propTypes = {
  notifications: PropTypes.array,
  viewMore: PropTypes.func.isRequired,
  notificationClose: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  notificationLastSeen: PropTypes.string.isRequired,
};

export default NotificationBlock;
