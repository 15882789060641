import styled from "styled-components/macro";

export default styled.div`
  padding: 10px 0px;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.palette.mainText};
  & a:hover {
    color: ${({ theme }) => theme.palette.primaryText};
  }
`;
