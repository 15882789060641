import React from "react";
import PropTypes from "prop-types";
import Flex from "../Flex";
import { Form, Paragraph } from "../../containers/Guidelines/AddGuideline/styled";
import { ToggleSwitch } from "../ToggleSwitch";
import {
  Container, Label, ControlBox, Input,
} from "./styled";

const MemberDetail = ({
  memberEmail,
  enableHighlighter,
  showHiddenRepositories,
  onChange,
}) => {
  return (
    <Container>
      <Form>
        <Flex column="column" width="100%">
          <ControlBox>
            <Label>Email</Label>
            <Input
              type="text"
              placeholder="email"
              value={memberEmail}
              disabled="disabled"
            />
            <Paragraph>Managed by github</Paragraph>
          </ControlBox>
          <ControlBox>
            <Label>Syntax Highlighter</Label>
            <ToggleSwitch
              onChange={() => onChange({ enableHighlighter: !enableHighlighter }, 'highlighter')}
              checked={enableHighlighter}
            />
          </ControlBox>
          <ControlBox>
            <Label>Show hidden repositories</Label>
            <ToggleSwitch
              onChange={() => onChange({ showHiddenRepositories: !showHiddenRepositories }, 'arhiveRepos')}
              checked={showHiddenRepositories}
            />
          </ControlBox>
        </Flex>
      </Form>
    </Container>
  );
};

MemberDetail.propTypes = {
  memberEmail: PropTypes.string,
  enableHighlighter: PropTypes.bool.isRequired,
  showHiddenRepositories: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

MemberDetail.defaultProps = {
  memberEmail: "",
};

export default MemberDetail;
