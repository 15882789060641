import styled from "styled-components/macro";

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 115px;
  padding-bottom: 115px;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  background: ${({ theme }) => theme.palette.bodyBg};
`;
