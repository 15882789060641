import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      errorInfo,
    });
    console.error(error);
  }

  render() {
    const { errorInfo } = this.state;
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;
    if (errorInfo) {
      return (
        <div>Something went wrong!</div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
