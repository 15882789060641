import React from "react";
import PropTypes from "prop-types";
import { NEW_COMMENT, CONTEXT_MENU, SHARE } from "../../../containers/CommitDetail/constants";
import { NewComment, ShareCommitFile } from "../CodeLineAction";
import Flex from "../../Flex";
import { ActionBtn } from "../ActionBtn";
import CodeMirror from "./CodeMirror";
import {
  Container,
  Content,
  LineNumber,
  ActionContainer,
  CodeContainer,
} from "./styled";

const CodeLine = ({
  cssClass,
  content,
  additionLineno,
  deletionLineNo,
  toggleActionBlock,
  shouldActionBlockOpen,
  path,
  position,
  sha,
  fetchCommitDetail,
  actionType,
  committerId,
  commitImageUrl,
  buttonShare,
  onCancelShowOff,
  containerShowOff,
  commitAuthor,
  setState,
  setUnSaveComments,
  unSaveComments,
  enableHighlighter,
}) => {
  return (
    <Container className={cssClass}>
      <ActionContainer className={cssClass}>
        <Flex>
          <ActionBtn
            className="action-btn"
            modifier={cssClass}
            shouldActionBlockOpen={() => shouldActionBlockOpen(CONTEXT_MENU)}
            toggleActionBlock={toggleActionBlock}
            actionType={actionType}
            path={path}
            position={position}
            buttonShare={buttonShare}
          />
          <LineNumber>
            <code>{deletionLineNo}</code>
          </LineNumber>
          <LineNumber>
            <code>{additionLineno}</code>
          </LineNumber>
        </Flex>
      </ActionContainer>

      <CodeContainer className={cssClass}>
        <Content>
          <Flex>
            {cssClass === "code-expandable"
            && <code>{content}</code>}

            {cssClass !== "code-expandable" && (!enableHighlighter
              ? <code>{content}</code>
              : (
                <CodeMirror
                  value={content}
                  mode="javascript"
                />
              )
            )}
          </Flex>
        </Content>
      </CodeContainer>
      {shouldActionBlockOpen(NEW_COMMENT) && (
        <NewComment
          committerId={committerId}
          path={path}
          position={position}
          sha={sha}
          fetchCommitDetail={fetchCommitDetail}
          setUnSaveComments={setUnSaveComments}
          unSaveComments={unSaveComments}
        />
      )}
      {shouldActionBlockOpen(SHARE) && commitImageUrl && (
        <ShareCommitFile
          committerId={committerId}
          fetchCommitDetail={fetchCommitDetail}
          commitImageUrl={commitImageUrl}
          onCancelShowOff={onCancelShowOff}
          containerShowOff={containerShowOff}
          commitAuthor={commitAuthor}
          setState={setState}
        />
      )}
    </Container>
  );
};

CodeLine.propTypes = {
  cssClass: PropTypes.string,
  content: PropTypes.string.isRequired,
  additionLineno: PropTypes.number,
  deletionLineNo: PropTypes.number,
  toggleActionBlock: PropTypes.func,
  shouldActionBlockOpen: PropTypes.func,
  path: PropTypes.string,
  position: PropTypes.number,
  sha: PropTypes.string,
  fetchCommitDetail: PropTypes.func,
  actionType: PropTypes.object.isRequired,
  committerId: PropTypes.number.isRequired,
  commitImageUrl: PropTypes.string,
  buttonShare: PropTypes.object,
  onCancelShowOff: PropTypes.func,
  containerShowOff: PropTypes.object,
  commitAuthor: PropTypes.string,
  setState: PropTypes.func.isRequired,
  setUnSaveComments: PropTypes.func.isRequired,
  unSaveComments: PropTypes.array.isRequired,
  enableHighlighter: PropTypes.bool.isRequired,
};

export default CodeLine;
