/* eslint-disable import/no-cycle */
import React from "react";
import PropTypes from "prop-types";
import Options from "./Options";

import {
  StyledLi,
  LiSpan,
  StyledCategory,
  ArrowIcon,
} from "./styled";

const Category = ({ label, content, onSelect }) => {
  return (
    <StyledLi className="ignore-click">
      <LiSpan className="ignore-click">{label}</LiSpan>
      <ArrowIcon />
      <StyledCategory>
        <Options options={content} onSelect={onSelect} />
      </StyledCategory>
    </StyledLi>
  );
};

Category.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Category;
