import history from "../../utils/history";
import { ROUTES } from "../Routes";
import {
  SET_ORGS, SET_ACTIVE_ORG, SET_MEMBERS_ORG, SET_MEMBERS_INITIAL_STATE,
} from "./constants";

// if the user switch organization during viewing commits, redirect them back to repositories
export const redirect = (hist) => {
  const redirectFrom = [ROUTES.REPO, ROUTES.COMMIT];
  const redirectTo = ROUTES.PROJECTS;

  if (redirectFrom.some(path => hist.location.pathname === path)) {
    hist.push(redirectTo);
    return redirectTo;
  }
};

export const initialState = {
  list: [],
  active: undefined,
  memberList: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGS: {
      const { payload = [] } = action;
      const activeOrgId = Number(localStorage.getItem("activeOrgId")) || payload[0].id;
      const activeOrg = payload.find(({ id }) => activeOrgId === id);
      return { ...state, list: payload, active: activeOrg };
    }

    case SET_ACTIVE_ORG: {
      const { payload: { id: payloadId } = {} } = action || {};
      const { active: { id: activeId } = {} } = state || {};

      if (payloadId !== activeId) redirect(history);
      localStorage.setItem("activeOrgId", action.payload.id);
      localStorage.setItem("activeOrgRole", action.payload.role);
      return { ...state, active: action.payload };
    }

    case SET_MEMBERS_ORG: {
      return { ...state, memberList: action.payload };
    }

    case SET_MEMBERS_INITIAL_STATE: {
      return action.payload || initialState;
    }

    default:
      return state;
  }
};
