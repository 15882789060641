import React from "react";
import { MainLayout } from "./containers/MainLayout";
import { Routes } from "./containers/Routes";
import ErrorBoundary from "./containers/ErrorBoundary";

const App = () => (
  <ErrorBoundary>
    <MainLayout>
      <Routes />
    </MainLayout>
  </ErrorBoundary>
);

export default App;
