import axios from 'axios';
import { authDispatch } from "../../containers/AuthUser";
import { getAuthHeaders } from './headers';

const handleErros = (error) => {
  const { response: { status } = {} } = error || {};
  /* eslint-disable default-case */
  switch (status) {
    case 403:
      authDispatch(false);
      break;
    case 401:
      authDispatch(false);
      break;
  }
  /* eslint-enable default-case */

  return Promise.reject(error);
};

// create a configurable axios instance
const instance = axios.create();

instance.interceptors.request.use(
  (request) => {
    request.headers = getAuthHeaders();
    return request;
  },
  error => handleErros(error)
);

// global request handling
instance.interceptors.response.use(
  response => response,
  error => handleErros(error)
);

export default instance;
