import styled from "styled-components/macro";

export default styled.button`
  color: ${({ theme }) => theme.palette.mainText};
  text-align: center;
  min-width: 100px;
  border-radius: 6px;
  padding: 12px 18px;
  margin-left: 16px;
  border: 1px solid ${({ theme }) => theme.palette.linkBorder};

  /* FIXME: placeholder color */
  background: ${({ theme }) => theme.palette.linkBg};

  /* FIXME: placeholder color */
  :hover {
    background: rgba(113, 126, 138, 1);
    border-color: rgba(113, 126, 138, 1);
  }
`;
