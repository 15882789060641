import Markdown from "markdown-it";
import emoji from "markdown-it-emoji";
import linkAttributes from "markdown-it-link-attributes";

export const md = new Markdown("zero", {
  html: false,
  breaks: true,
  langPrefix: false,
});

md.enable([
  "emphasis",
  "link",
  "code",
  "blockquote",
  "newline",
  "backticks",
  "fence",
]);

md.use(emoji);
md.use(linkAttributes, {
  attrs: {
    target: '_blank',
    rel: 'noopener',
  },
});
