export default {
  brandColor: "#ED663F",
  bodyBg: "#1F2327",
  primaryBg: "#292E33",
  primaryBgHover: "#343B41",
  secondaryBg: "#16191C",
  primaryText: "#FFFFFF",
  mainText: "#545F69",
  secondaryText: "#717E8A",
  infoText: "#748AA1",

  sidebarBg: "#16191C",
  sidebarBorder: "transparent",
  sidebarButtonBgHover: "#292E33",

  linkBorder: "#292E33",
  linkBg: "rgba(113, 126, 138, 0.1)",
  guideColor: "#717E8A",
  guideHover: "#FFFFFF",
  statusTileBorder: "#151718",
  paginationText: "#717E8A",
  paginationHoverText: "#FFFFFF",
  instructionText: "#717E8A",
  loaderBg: "#292E33",
  notificationActive: "rgba(22,25,28,0.6)",

  dropdownText: "#FFFFFF",
  dropdownBg: "rgba(41, 46, 51, 0.6)",
  dropdownSecondaryBg: "#343B41",
  dropdownChoiceBG: "#292E33",
  dropdownBorder: "rgba(22, 25, 28, 0.3)",
  dropdownHover: "rgba(22, 25, 28, 0.3)",
  overlayBg: "rgba(22, 25, 28, 0.5)",

  sidebarItem: "#545F69",
  sidebarItemHover: "#FFFFFF",
  sidebarItemActive: "#ffffff",

  paletteSwitch: "#ED663F",
  topPanelBorder: "#16191C",

  commitWaiting: "#E4B13E",
  commitAccepted: "#26BF93",
  commitRejected: "#DE5653",
  commitPassed: "#7346E1",

  code: "#FFFFFF",
  codeBg: "#151718",
  codeTabline: "#ABAEB4",
  codeTablineBg: "#292E33",
  codeHeaderBg: "#292E33",
  codeHeaderText: "#717E8A",
  codeAddition: "#49BA83",
  codeAdditionBg: "#002800",
  codeAdditionActionBg: "#001a00",
  codeDeletion: "#ff5370",
  codeDeletionActionBg: '#1a0000',
  codeDeletionBg: '#380000',
  lineNo: "#717E8A",
  lineHover: "rgba(255, 255, 255, 0.1)",

  commentHeaderBg: "#60DEA1",
  commentBlockBg: "rgba(255, 255, 255, 0.7)",
  commentBg: "#202428",
  commentText: "rgba(255, 255, 255, 0.7)",

  acceptButtonBg: "#41CA88",
  rejectButtonBg: "#CD4350",
  markButtonBg: "#7346E1",

  checkboxOn: "#FF8B38",
  checkboxOff: "#717E8A",

  shareHeaderBg: "#F8DA83",

  formHeaderBg: "#394046",
  formBg: "#292E33",
  formControlBg: "#202428",
  formText: "#717E8A",

  linkColor: "#C38FFF",
  deleteOverlayBg: "rgba(255, 255, 255, 0.25)",

  codeBorder: "rgba(255, 255, 255, 0.4)",
  blackColor: "#000000",
  calenderShadow: "#000 0px 1px 6px",
  textWhite: "#FFFFFF",
  iconColor: "#545F69",
  iconHoverStatae: "#FFFFFF",
};
