import { css } from "styled-components/macro";

export const ulStyles = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  margin-bottom: 25px;
  border-radius: 8px;
  ${({ theme }) => theme.mixins.dropShadow};
  background: ${({ theme }) => theme.palette.dropdownChoiceBG};
  border: 1px solid ${({ theme }) => theme.palette.dropdownBorder};
  z-index: 5;
`;

export const triangleStyles = css`
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  margin-top: -5px;
  transform: translateX(-50%);
  height: 0;
  width: 0;
  border: 15px solid transparent;
  border-bottom: 15px solid ${({ theme }) => theme.palette.dropdownChoiceBG};
`;
