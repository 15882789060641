import styled from "styled-components/macro";

export default styled.div`
  border-radius: 8px;
  width: 100%;
  & .dropdown {
    margin: 0px 0px 10px 0px;
    background: ${({ theme }) => theme.palette.formControlBg};
  }
`;
