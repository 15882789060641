import styled from "styled-components";
import palettes from "../../../containers/ThemeControl/themes/palettes";
import tick from "../../../images/accept-btn-orange-tick.svg";

const {
  LIGHT: {
    paletteSwitch,
    bodyBg,
  },
} = palettes;

export default styled.label`
  display: flex;
  position: relative;
  padding: 14px;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  background: ${({ checked, theme }) => checked ? theme.palette.brandColor : bodyBg};
  margin-bottom: 8px;

  :last-of-type {
    margin-bottom: 0;
  }

  input {
    visibility: hidden;
    position: absolute;
  }

  i {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 14px;
    background: transparent;
    border: 2px solid ${paletteSwitch};
    border-radius: 4px;
  }

  span {
    font-size: 14px;
    line-height: 22px;
    color: ${paletteSwitch};
  }

  input:checked ~ i {
    background: white;
    border: 2px solid ${({ theme }) => theme.palette.brandColor};
    background-image: url(${tick});
    background-position: center;
    background-repeat: no-repeat;
  }

  input:checked ~ span {
    color: white;
  }
`;
