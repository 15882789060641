import styled from "styled-components/macro";

export default styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  border-radius: 5px 5px 0 0;
  background: ${({ theme }) => theme.palette.commentHeaderBg};

  line-height: 20px;
  font-size: 14px;
  color: white;
`;
