import React from "react";
import PropTypes from "prop-types";
import { Container } from "./styled";
import CommitMessage from "./CommitMessage";

const CommitTitle = ({ commitTitle = [], trelloCardTitle }) => (
  <Container>
    {commitTitle.map((line, index) => {
      // eslint-disable-next-line react/no-array-index-key
      return <CommitMessage key={index} source={line} trelloCardTitle={trelloCardTitle} />;
    })}
  </Container>
);

CommitTitle.propTypes = {
  commitTitle: PropTypes.arrayOf(PropTypes.string),
  trelloCardTitle: PropTypes.string,
};

export default CommitTitle;
