import React, { useReducer, useRef } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { getUserData } from "../../utils/helpers/userData";
import {
  Comment,
  NewComment,
  CommentForm,
  Content,
} from "../../components/Comment";
import { GuidelinesDropdown } from "../GuidelinesDropdown";
import { useOrgMemberList } from "../../utils/helpers/hooks/withOrgs";
import { TYPE, PLACEHOLDER } from "./constants";
import {
  postComment, editComment, deleteComment,
} from "./api";

const CommentBlock = ({
  type,
  body = "",
  path,
  position,
  fetchCommitDetail,
  autoFocus,
  id: commentId,
  location: { search },
  author: {
    avatarUrl: authorAvatar = "",
    login: authorLogin = "",
    id: authorId = "",
  } = {},
  formattedDate = "",
  committerId = "",
  setUnSaveComments,
  unSaveComments,
}) => {
  const usersList = useOrgMemberList();
  const inputCommentRef = useRef(null);
  const initialState = {
    commentBody: body || "",
    loading: false,
    deletePending: false,
    inputType: type,
    editButtonText: "edit",
  };

  const reducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });
  const [state, setState] = useReducer(reducer, initialState);
  const {
    commentBody, loading, deletePending, inputType, editButtonText,
  } = state;

  const { uuid, repoId } = queryString.parse(search);
  const { photo, id: loggedId = "" } = getUserData() || {};

  const handlePublishCommentClick = async () => {
    setState({ commentBody: inputCommentRef.current.value });
    const data = {
      commentBody: inputCommentRef.current.value,
      path,
      position,
      repositoryId: repoId,
      committerId,
    };
    if (unSaveComments) {
      const elements = unSaveComments.filter(x => x.position !== position);
      setUnSaveComments(elements);
    }
    setState({ loading: true });
    try {
      await postComment(uuid, data);
      try {
        await fetchCommitDetail();
        setState({ loading: false, commentBody: "" });
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditCommentClick = async () => {
    const data = { commentBody: inputCommentRef.current.value };

    setState({ loading: true });
    try {
      await editComment(commentId, data);
      try {
        await fetchCommitDetail();
        setState({
          loading: false,
          commentBody: data.commentBody,
          inputType: "",
          editButtonText: 'edit',
        });
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteCommentClick = async () => {
    setState({ loading: true, deletePending: true });
    try {
      await deleteComment(uuid, commentId);
      try {
        await fetchCommitDetail();
        setState({ loading: true });
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const switchEditMode = () => {
    setState({
      inputType: inputType === TYPE.EDITABLE ? "" : TYPE.EDITABLE,
      editButtonText: inputType === TYPE.EDITABLE ? "edit" : "cancel",
    });
  };

  const handleRuleSelect = (link, label) => {
    const result = `**${label}:** [coding guidelines link](${link})\n${inputCommentRef.current.value}`;
    setState({ commentBody: result });
  };

  const commentProps = {
    avatar: authorAvatar,
    login: authorLogin,
    headerMessage: formattedDate,
    onEdit: switchEditMode,
    editButtonText,
    onDelete: handleDeleteCommentClick,
    isCommentAuthor: loggedId.toString() === authorId.toString(),
    deletePending,
  };

  const newCommentProps = {
    value: commentBody,
    placeholder: PLACEHOLDER,
    onSubmit: handlePublishCommentClick,
    loading,
    autoFocus,
    organizationMembers: usersList,
    inputCommentRef,
    editValue: body,
    buttonValue: 'Send',
    position,
    setUnSaveComments,
    unSaveComments,
  };

  const editCommentProps = {
    ...newCommentProps,
    buttonValue: 'Update',
    onSubmit: handleEditCommentClick,
  };

  switch (inputType) {
    case TYPE.EDITABLE:
      return (
        <Comment {...commentProps} className="ignore-click">
          <CommentForm {...editCommentProps}>
            <GuidelinesDropdown onSelect={handleRuleSelect} />
          </CommentForm>
        </Comment>
      );

    case TYPE.NEW:
      return (
        <NewComment avatar={photo} className="ignore-click new-comment">
          <CommentForm {...newCommentProps}>
            <GuidelinesDropdown onSelect={handleRuleSelect} />
          </CommentForm>
        </NewComment>
      );

    default:
      return (
        <Comment {...commentProps}>
          <Content body={body} usersList={usersList} />
        </Comment>
      );
  }
};

CommentBlock.propTypes = {
  type: PropTypes.oneOf([TYPE.EDITABLE, TYPE.NEW]),
  body: PropTypes.string,
  user: PropTypes.object,
  comment: PropTypes.string,
  path: PropTypes.string,
  position: PropTypes.number,
  fetchCommitDetail: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  location: PropTypes.object,
  id: PropTypes.number,
  committerId: PropTypes.number,
  author: PropTypes.shape({
    avatarUrl: PropTypes.string,
    login: PropTypes.string,
  }),
  formattedDate: PropTypes.string,
  setUnSaveComments: PropTypes.func,
  unSaveComments: PropTypes.array,
};

export default compose(
  withRouter,
)(CommentBlock);
