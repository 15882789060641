import styled from "styled-components/macro";

export default styled.button`
  /* FIXME: placeholder color */
  margin-left: 24px;
  opacity: 1;

  &:disabled {
    opacity: 0.25;
  }  
`;
