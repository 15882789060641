import React from "react";
import ContentWrapper from "../../components/ContentWrapper";
import Flex from "../../components/Flex";
import { PathHeader } from "../../components/PathHeader";
import { ChartViewContainer, LastActionsContainer } from "../../components/Dashboard";
import "react-datepicker/dist/react-datepicker.css";
export default function Dashboard() {
  const style = {
    display: "flex",
  };
  return (
    <ContentWrapper>
      <Flex justify="space-between">
        <PathHeader pathItems={[{ label: "Dashboard" }]} margin="0 0 20px 16px" />
      </Flex>
      <div style={style}>
        <ChartViewContainer />
        <LastActionsContainer />
      </div>
    </ContentWrapper>
  );
}
