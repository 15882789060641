import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getOrgMembers } from "../../../containers/withOrgs/api";

export const useOrgMemberList = () => {
  const memberList = useSelector(store => store.orgs.memberList);

  useEffect(() => {
    if (!memberList) {
      getOrgMembers();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return memberList || [];
};
