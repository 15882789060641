import React from "react";
import PropTypes from "prop-types";
import { ShareBlock } from "../../../containers/ShareBlock";
import { withTheme, PALETTES } from "../../../containers/ThemeControl";
import { ActionBlock } from "../../ActionBlock";
import { ShareIconBlack, ShareIconWhite, ShareIconWhiteGold } from "../../../images";
import { Container } from "./styled";

const ShareCommitFile = ({
  commitImageUrl,
  onCancelShowOff,
  containerShowOff,
  commitAuthor,
  setState,
  theme: { activePalette },
}) => (
  <Container className="ignore-click" ref={containerShowOff} isVisible>
    {commitImageUrl && (
      <ActionBlock
        type="SHARE"
        headerTxt="Show off copy:"
        icon={activePalette === PALETTES.DEFAULT
          ? ShareIconBlack
          : ShareIconWhiteGold}
        buttonName="Share"
      >
        <ShareBlock
          commitImageUrl={commitImageUrl}
          icon={ShareIconWhite}
          onCancelShowOff={onCancelShowOff}
          commitAuthor={commitAuthor}
          containerShowOff={containerShowOff}
          setParentState={setState}
        />
      </ActionBlock>
    )}
  </Container>
);

ShareCommitFile.propTypes = {
  commitImageUrl: PropTypes.string,
  onCancelShowOff: PropTypes.func,
  containerShowOff: PropTypes.object,
  commitAuthor: PropTypes.string,
  setState: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(ShareCommitFile);
