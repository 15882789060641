import React from "react";
import styled from "styled-components";

export default (icon) => {
  const Styled = styled(icon)`
    position: relative;
  `;

  return <Styled />;
};
