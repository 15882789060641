import styled from "styled-components/macro";

export default styled.h1`
  display: inline-block;
  font-weight: 500;
  font-size: 36px;
  color: ${({ theme }) => theme.palette.mainText};
  margin: ${({ margin }) => margin || "0 0 16px 0"};
  padding: ${({ padding }) => padding || 0};
`;
