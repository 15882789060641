import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./actions";

const mapStateToProps = ({ guidelines }) => ({ guidelines });
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default Component => connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
