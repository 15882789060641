import styled from "styled-components/macro";

export default styled.button`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.mainText};
  
  /* FIXME: placeholder color */
  :hover {
    color: ${({ theme }) => theme.palette.primaryText};
  }
`;
