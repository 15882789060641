import styled from "styled-components/macro";

export default styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.primaryText};

  .trelloLink {
    color: ${({ theme }) => theme.palette.linkColor}
  }
  a {
    :hover {
      color: ${({ theme }) => theme.palette.linkColor};
    }
  }
`;
