import React from "react";
import PropTypes from "prop-types";
import ContentBlock from "../ContentBlock";

import { Icon, Text } from "./styled";

const Popup = ({ text }) => (
  <ContentBlock align="center" padding="28px 37px 24px">
    <Icon />
    <Text>{text}</Text>
  </ContentBlock>
);

Popup.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Popup;
