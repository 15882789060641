import React from "react";
import PropTypes from "prop-types";
import { SwitchButton, Icon } from "./styled";

const PaletteSwitch = ({ switchPalette }) => (
  <SwitchButton onClick={switchPalette}>
    <Icon />
  </SwitchButton>
);

PaletteSwitch.propTypes = {
  switchPalette: PropTypes.func.isRequired,
};

export default PaletteSwitch;
