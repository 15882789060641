import React, { useEffect, useState } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withCookies } from "react-cookie";
import { withRouter } from "react-router-dom";
import { setUserData } from "../../utils/helpers/userData";
import App from "../../App";
import { ROUTES } from "../Routes";
import { getOrgs } from "../withOrgs";
import { Login } from "../../components/onboarding";
import { Loader } from "../../components/Loader";
import { API_URL } from "../../utils/api";
import { checkToken } from "./api";
import { socket } from "./socket";
import { PROVIDER, ORG_CALL_INTERVAL } from "./constants";
import withAuth from "./withAuth";
import { SuccessMsg } from "./styled";

let popup;
let debounceOrgs;

const AuthUser = ({
  cookies,
  isAuthorized,
  setAuthorization,
  history,
}) => {
  const githubToken = cookies.get("token");
  const [isAuthPending, setAuthPending] = useState(!!githubToken);
  const [errorMsg, setErrorMsg] = useState(null);
  const validateToken = async () => {
    try {
      await checkToken(githubToken);
      try {
        const orgsList = await getOrgs();
        if (orgsList.some(({ imported }) => !imported)) {
          debounceOrgs = setInterval(async () => {
            const orgs = await getOrgs();
            if (orgs.every(({ imported }) => !!imported)) clearInterval(debounceOrgs);
          }, ORG_CALL_INTERVAL);
        }
        setAuthorization(true);
      } catch (error) {
        console.error(error);
        setErrorMsg(error.message ? error.message : 'Something went wrong!');
        setAuthorization(false);
      }
    } catch (error) {
      console.error(error);
      setErrorMsg(error.message ? error.message : 'Something went wrong!');
      setAuthorization(false);
    } finally {
      setAuthPending(false);
    }
  };

  useEffect(() => {
    if (githubToken) {
      validateToken();
    }

    if (history.location.pathname === "/") {
      history.push(ROUTES.PROJECTS);
    }

    socket.on(PROVIDER, ({ token, admin, ...userData }) => {
      setTimeout(() => popup.close(), 0);
      login(token, userData);
    });

    return () => {
      clearInterval(debounceOrgs);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async (token, userData) => {
    if (token && userData.email) {
      const cookieMaxAge = 60 * 60 * 24 * 7;
      cookies.set("token", token,
        {
          path: "/",
          secure: process.env.REACT_APP_COOKIES_SECURE_FLAG === 'true',
          maxAge: cookieMaxAge,
        });
      setUserData(userData);
      setAuthPending(true);
      try {
        const orgsList = await getOrgs();
        if (orgsList.some(({ imported }) => !imported)) {
          debounceOrgs = setInterval(async () => {
            const orgs = await getOrgs();
            if (orgs.every(({ imported }) => !!imported)) clearInterval(debounceOrgs);
          }, ORG_CALL_INTERVAL);
        }
        setAuthorization(true);
      } catch (error) {
        console.error(error);
        setErrorMsg(error.message ? error.message : 'Something went wrong!');
        setAuthorization(false);
      }
    } else {
      setErrorMsg(!userData.email ? 'Email address is not public!' : 'Something went wrong!');
      setAuthorization(false);
    }
    setAuthPending(false);
  };

  const openPopup = () => {
    const width = 600;
    const height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const url = `${API_URL}/${PROVIDER}?socketId=${socket.id}`;

    return window.open(
      url,
      "loginWindow",
      `toolbar=no, location=no, directories=no, status=no, menubar=no, 
      scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
      height=${height}, top=${top}, left=${left}`
    );
  };

  const startAuth = (event) => {
    event.preventDefault();
    popup = openPopup();
  };

  switch (true) {
    case isAuthorized:
      return <App />;

    default:
      return (
        <Loader forceLightTheme loading={isAuthPending} height="100vh">
          {errorMsg && (<SuccessMsg>{errorMsg}</SuccessMsg>)}
          <Login startAuth={startAuth} />
        </Loader>
      );
  }
};

AuthUser.propTypes = {
  cookies: PropTypes.object.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  setAuthorization: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(
  withCookies,
  withAuth,
  withRouter,
)(AuthUser);
