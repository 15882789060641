import React from "react";
import { Container, Paragraph, Header } from "./styled";

export default () => (
  <Container>
    <Header>Hi!</Header>
    <Paragraph>Your repos are currently being imported</Paragraph>
    <Paragraph>this may take a few minutes</Paragraph>
    <Paragraph>grab a coffee in the meantime!</Paragraph>
  </Container>
);
