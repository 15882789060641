import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ProfileDropDown as ProfileDropDownItems,
} from "../../containers/ProfileDropDown";
import ProfileAvatar from "./ProfileAvatarComponent";
import ProfileDropdown from "./ProfileDropdownComponent";

import { ProfileContainer } from "./styled";

const ProfileComponent = ({
  avatarIcon,
  userName,
}) => {
  const [toggleOpen, setToggleOpen] = useState(false);

  return (
    <>
      <ProfileContainer onClick={() => setToggleOpen(!toggleOpen)} className="ignore-click">
        <ProfileAvatar avatarIcon={avatarIcon} />
        <ProfileDropdown userName={userName} />
        {toggleOpen
        && <ProfileDropDownItems setToggleOpen={setToggleOpen} toggleOpen={toggleOpen} />}
      </ProfileContainer>
    </>
  );
};

ProfileComponent.propTypes = {
  avatarIcon: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

export default ProfileComponent;
