import React, { useState } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { PathHeader } from "../../components/PathHeader";
import ContentWrapper from "../../components/ContentWrapper";
import { ROUTES } from "../Routes";
import { getUserData, setUserData } from "../../utils/helpers/userData";
import { MemberDetail } from "../../components/Settings";
import { SuccessMsg } from "../ManageProjects/styled";
import { updateMember } from "../UpdatesSideBar/api";
import PageLoader from "../../components/PageLoader";

const Settings = () => {
  const userData = getUserData() || {};
  const [
    {
      updateFlag, memberName, enableHighlighter, loader, showHiddenRepositories,
    }, setState,
  ] = useState({
    updateFlag: false,
    enableHighlighter: userData.enableHighlighter,
    loader: false,
    showHiddenRepositories: userData.showHiddenRepositories,
  });

  const onChange = async (payload, type) => {
    setState(state => ({ ...state, loader: true }));
    const response = await updateMember(userData.id, payload);
    if (response) {
      if (type === "highlighter") {
        userData.enableHighlighter = !enableHighlighter;
        setState(state => ({
          ...state,
          enableHighlighter: !enableHighlighter,
          updateFlag: true,
          loader: false,
        }));
      } else if (type === "arhiveRepos") {
        userData.showHiddenRepositories = !showHiddenRepositories;
        setState(state => ({
          ...state,
          showHiddenRepositories: !showHiddenRepositories,
          updateFlag: true,
          loader: false,
        }));
      }
      setUserData(userData);
      setTimeout(() => {
        setState(state => ({ ...state, updateFlag: false }));
      }, 5000);
    }
  };

  return (
    <ContentWrapper padding="30px 16px 40px 24px">
      { updateFlag && (<SuccessMsg>Changes Saved</SuccessMsg>) }
      <PathHeader
        pathItems={[{ label: "Settings", link: ROUTES.SETTINGS }]}
        margin="0 0 20px 16px"
      />
      { (!userData || loader) && <PageLoader /> }
      <MemberDetail
        memberName={memberName}
        memberEmail={userData.email}
        enableHighlighter={enableHighlighter}
        showHiddenRepositories={showHiddenRepositories}
        onChange={onChange}
      />
    </ContentWrapper>
  );
};

export default compose(
  withRouter,
)(Settings);
