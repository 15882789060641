import styled from "styled-components/macro";
import { CircleOkIcon } from "../../../images";

export default styled(CircleOkIcon)`
  path {
    stroke: ${({ theme }) => theme.palette.primaryText};
  }

  margin-bottom: 28.5px;
`;
