import React from "react";
import PropTypes from "prop-types";
import Flex from "../../Flex";
import { EyeIcon, CrossIcon, ArchiveIcon } from "../../../images";
import {
  Container,
  Link,
  Title,
} from "./styled";
import CommitCount from "./CommitCount";
import TechnologyTile from "./TechnologyTile";
import HideProject from "./HideProject";

const ProjectTile = ({
  id,
  name = "title goes here",
  rejectedcount = 0,
  reviewcount = "0",
  linkTo,
  onChange,
  repostate,
  projectType,
  technologyname,
}) => (
  <Container padding="0px">
    <TechnologyTile name={technologyname || ''} />
    <Link to={linkTo}>
      <Title title={name}>{name}</Title>
      <Flex width="100%" justify="space-between">
        <CommitCount count={reviewcount} label="To review" Icon={EyeIcon} />
        <CommitCount count={rejectedcount} label="Rejected" Icon={CrossIcon} />
      </Flex>
    </Link>
    <HideProject
      Icon={ArchiveIcon}
      repostate={repostate}
      projectType={projectType}
      onChange={onChange}
      repositoryId={id}
    />
  </Container>
);

ProjectTile.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  rejectedcount: PropTypes.string,
  reviewcount: PropTypes.string,
  linkTo: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  repostate: PropTypes.bool.isRequired,
  projectType: PropTypes.string.isRequired,
  technologyname: PropTypes.string,
};

export default ProjectTile;
