/* eslint-disable prefer-rest-params */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
// TODO: it's probably a good idea to refactor it at some point :x
import React from "react";

let hotjarScript;

function withHotJar(WrappedComponent) {
  return class extends React.Component {
    componentDidMount() {
      const hotjarTracking = function (h, o, t, j, a, r) {
        h.hj = h.hj
          || function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 1348560, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      };
      hotjarTracking(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
    }

    componentWillUnmount() {
      if (hotjarScript) {
        document.head.removeChild(hotjarScript);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default withHotJar;
