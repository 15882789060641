/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import PropTypes from "prop-types";
import { Container } from "./styled";

const ToggleSwitch = (props = {}) => (
  <Container>
    <label className="switch">
      <input type="checkbox" {...props} />
      <span className="slider round"></span>
    </label>
  </Container>
);

ToggleSwitch.propTypes = {
  props: PropTypes.object,
};

export default ToggleSwitch;
