import styled from "styled-components/macro";

export default styled.button`
  /* FIXME: placeholder color */
  background: ${({ theme }) => theme.palette.brandColor};
  font-weight: 500;
  line-height: 16px;
  color: white;
  padding: 11px 18px;
  border-radius: 8px;
  opacity: 1;

  &:disabled {
    opacity: 0.25;
  }
`;
