import styled from "styled-components/macro";

export default styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 24px;
  min-width: 250px;
`;
