import React from "react";
import PropTypes from "prop-types";
import {
  AndroidIcon, iOSIcon, DotNetIcon, NodeIcon, PhpIcon, ReactIcon,
} from "../../../images";
import { TECHNOLOGY_TYPE } from "./constants";
import { RenderIcon, TechnologyWrapper } from "./styled";

const TechnologyTile = ({ name }) => {
  switch (name) {
    case TECHNOLOGY_TYPE.REACT:
      return (
        <TechnologyWrapper>
          {RenderIcon(ReactIcon)}
        </TechnologyWrapper>
      );

    case TECHNOLOGY_TYPE.NODE:
      return (
        <TechnologyWrapper>
          {RenderIcon(NodeIcon)}
        </TechnologyWrapper>
      );

    case TECHNOLOGY_TYPE.IOS:
      return (
        <TechnologyWrapper>
          {RenderIcon(iOSIcon)}
        </TechnologyWrapper>
      );

    case TECHNOLOGY_TYPE.ANDROID:
      return (
        <TechnologyWrapper>
          {RenderIcon(AndroidIcon)}
        </TechnologyWrapper>
      );

    case TECHNOLOGY_TYPE.REACTNATIVE:
      return (
        <TechnologyWrapper>
          {RenderIcon(ReactIcon)}
        </TechnologyWrapper>
      );

    case TECHNOLOGY_TYPE.DOTNET:
      return (
        <TechnologyWrapper>
          {RenderIcon(DotNetIcon)}
        </TechnologyWrapper>
      );

    case TECHNOLOGY_TYPE.PHP:
      return (
        <TechnologyWrapper>
          {RenderIcon(PhpIcon)}
        </TechnologyWrapper>
      );

    default:
      return (
        ""
      );
  }
};

TechnologyTile.propTypes = {
  name: PropTypes.string.isRequired,
};

export default TechnologyTile;
