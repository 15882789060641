import styled from "styled-components/macro";
import icon from "../../../images/download-icon.svg";

export default styled.button`
  display: flex;
  align-self: flex-end;
  border-radius: 8px;

  /* FIXME: find a way to remove !important */
  margin-top: 32px !important;
  padding: 14px !important;
  background: ${({ theme }) => theme.palette.brandColor} !important;
  
  span {
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
  }

  i {
    display: block;
    margin-right: 14px;
    height: 20px;
    width: 20px;
    background-image: url(${icon});
    background-position: center;
    background-repeat: no-repeat;
  }
`;
