import React, { useReducer, useRef } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { withPopup, Popup } from "../Popup";
import { ShareCommit } from "../../components/ShareCommit";
import { useOrgMemberList } from "../../utils/helpers/hooks/withOrgs";
import { PLACEHOLDER, SHOWOFFTITLE } from "./constants";
import { postShowOff } from "./api";

const ShareBlock = ({
  commitImageUrl,
  icon,
  buttonName,
  onCancelShowOff,
  location: { search },
  commitAuthor,
  containerShowOff,
  setParentState,
  openPopup,
}) => {
  const usersList = useOrgMemberList();
  const { repoId } = queryString.parse(search);
  const inputShareMessage = useRef(null);

  const initialState = {
    loading: false,
    imageRef: null,
    commitImageSrc: null,
    crop: {
      unit: '%',
      width: 20,
    },
    croppedImageData: null,
  };
  const reducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });
  const [state, setState] = useReducer(reducer, initialState);
  const {
    loading, crop, croppedImageData, imageRef,
  } = state;

  const handleShareClick = async () => {
    setState({ loading: true });
    const data = {
      repositoryId: repoId,
      message: inputShareMessage.current.value,
      commitImageData: croppedImageData,
      commitAuthor,
    };

    try {
      await postShowOff(data);
      openPopup("Show-off shared!");
      setState({ loading: false });
      setParentState({ commitImageUrl: null });
      // eslint-disable-next-line no-param-reassign
      if (containerShowOff.current) containerShowOff.current.innerText = "";
      openPopup("Show-off shared!");
    } catch (error) {
      console.error(error);
    }
  };

  const onCropComplete = async (cropImage) => {
    if (imageRef && cropImage.width && cropImage.height) {
      const imageSource = await getCroppedImg(
        imageRef,
        cropImage,
      );
      setState({
        croppedImageData: imageSource,
      });
    }
  };

  const onCropChange = (cropImage) => {
    setState({ crop: cropImage });
  };

  const getCroppedImg = (image, cropImage) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = cropImage.width;
    canvas.height = cropImage.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      cropImage.x * scaleX,
      cropImage.y * scaleY,
      cropImage.width * scaleX,
      cropImage.height * scaleY,
      0,
      0,
      cropImage.width,
      cropImage.height
    );
    return canvas.toDataURL('image/png');
  };

  const onImageLoaded = (image) => {
    setState({ imageRef: image });
  };

  return (
    <div>
      <Popup />
      <ShareCommit
        commitImageSrc={commitImageUrl}
        placeholder={PLACEHOLDER}
        onSubmit={handleShareClick}
        loading={loading}
        showOffTitle={SHOWOFFTITLE}
        organizationMembers={usersList}
        icon={icon}
        buttonName={buttonName}
        onCancel={onCancelShowOff}
        crop={crop}
        onImageLoaded={onImageLoaded}
        onCropComplete={onCropComplete}
        onCropChange={onCropChange}
        inputShareMessage={inputShareMessage}
        croppedImageData={croppedImageData}
      />
    </div>
  );
};

ShareBlock.propTypes = {
  commitImageUrl: PropTypes.string,
  buttonName: PropTypes.string,
  icon: PropTypes.object,
  location: PropTypes.object,
  onCancelShowOff: PropTypes.func,
  commitAuthor: PropTypes.string,
  containerShowOff: PropTypes.object,
  openPopup: PropTypes.func.isRequired,
  setParentState: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  withPopup,
)(ShareBlock);
