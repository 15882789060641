import { connect } from "react-redux";
import { setAuthorization } from "./actions";

const mapStateToProps = ({ auth }) => ({ ...auth });

const mapDispatchToProps = dispatch => ({
  setAuthorization: payload => dispatch(setAuthorization(payload)),
});

export default Component => connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
