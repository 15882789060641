import styled from "styled-components/macro";

export default styled.div`
  display: flex;
  color: ${({ theme }) => theme.palette.primaryText};
  font-size: 14px;
  align-items: center;
  cursor: pointer;
  padding: 0 22px 0 0;
  cursor: pointer;
  
  & span {
    color: ${({ theme }) => theme.palette.brandColor};
  }
  
  &:after {
  content: "";
  width: 6px;
  height: 6px;
  border-left: 1.5px solid ${({ theme }) => theme.palette.brandColor};
  border-bottom: 1.5px solid ${({ theme }) => theme.palette.brandColor};
  border-top: transparent;
  border-right: transparent;
  transform: rotate(-45deg);
  display: inline-block;
}
`;
