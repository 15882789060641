import styled from "styled-components/macro";

export default styled.div`
  width: 25px;
  &.active::after {
    background: #ED663F;
    content: '';
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: flex;
    float: right;
  }
`;
