import styled from "styled-components/macro";
import { Arrow } from "../../../images";

export default styled(Arrow)`
  position: absolute;
  right: 16px;
  transform: rotate(180deg);
  path {
    stroke: ${({ theme }) => theme.palette.dropdownText};
  }
`;
