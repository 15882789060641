import React, { useState, useEffect } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { DropdownButton, Dropdown } from "../../components/GuidelinesDropdown";
import withGuidelines from "./withGuidelines";

const GuidelinesDropdown = ({ guidelines, onSelect }) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOutsideClick = (event) => {
    if (!event.target.classList.contains("ignore-click")) {
      setOpen(false);
      document.removeEventListener("click", handleOutsideClick);
    }
  };

  const handleButtonClick = () => {
    setOpen(true);
    document.addEventListener("click", handleOutsideClick);
  };

  return (
    <div>
      <DropdownButton onClick={handleButtonClick} isOpen={isOpen} />
      {isOpen && <Dropdown options={guidelines} onSelect={onSelect} />}
    </div>
  );
};

GuidelinesDropdown.propTypes = {
  onSelect: PropTypes.func.isRequired,
  guidelines: PropTypes.array.isRequired,
};

export default compose(withGuidelines)(GuidelinesDropdown);
