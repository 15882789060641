import styled from "styled-components/macro";

export default styled.div`
  margin-left: 20px; 
  padding: 6px 8px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 14px;
  color: white;
  background: ${({ theme }) => theme.palette.brandColor};
`;
