import React from "react";
import PropTypes from "prop-types";
import { md } from "../../utils/helpers/markdown";
import mentions from "../../utils/helpers/mentions";
import StyledContent from "./styled/Content";

const Content = ({ body = "", usersList = [] }) => {
  const innerHtml = { __html: md.use(mentions(usersList.map(({ login }) => login))).render(body) };
  return <StyledContent dangerouslySetInnerHTML={innerHtml} />;
};

Content.propTypes = {
  body: PropTypes.string,
  usersList: PropTypes.array,
};

export default Content;
