import styled from "styled-components/macro";

export default styled.span`
  display: block;
  color: white;
  font-weight: 500;
  line-height: 20px;
  font-size: 18px;
  padding: 0 20px;
`;
