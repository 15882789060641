import React from "react";
import PropTypes from "prop-types";
import { CommentBlock } from "../../../containers/CommentBlock";
import { CommentsContainer } from "./styled";

const CommitCommentsList = ({
  comments,
  path,
  position,
  fetchCommitDetail,
  committerId,
}) => (
  <CommentsContainer padding="32px 0 0 0">
    {comments.map(data => (
      <CommentBlock
        {...data}
        fetchCommitDetail={fetchCommitDetail}
        key={data.id}
      />
    ))}
    <CommentBlock
      fetchCommitDetail={fetchCommitDetail}
      committerId={committerId}
      path={path}
      position={position}
      type="NEW"
    />
  </CommentsContainer>
);

CommitCommentsList.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object),
  path: PropTypes.string,
  position: PropTypes.number,
  fetchCommitDetail: PropTypes.func,
  committerId: PropTypes.number,
};

export default CommitCommentsList;
