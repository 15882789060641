import React from "react";
import PropTypes from "prop-types";
import { Success, Failed } from "../Symbols";
import { CiStatus } from "./styled";

const CommitCiStatus = ({ ciName, ciStatus, ciBuildUrl }) => {
  const redirect = (e) => {
    e.preventDefault();
    window.open(ciBuildUrl, '_blank');
  };

  return (
    <CiStatus onClick={e => redirect(e)} title={`${ciName}: ${ciStatus.toUpperCase()}`}>
      { ciStatus.toLowerCase() === 'success' ? <Success size="26px" /> : <Failed size="26px" /> }
    </CiStatus>
  );
};

CommitCiStatus.propTypes = {
  ciName: PropTypes.string.isRequired,
  ciStatus: PropTypes.string.isRequired,
  ciBuildUrl: PropTypes.string.isRequired,
};

export default CommitCiStatus;
