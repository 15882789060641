import styled from "styled-components/macro";
import { SpinnerIcon } from "../../Loader";

export default styled(SpinnerIcon)`
  height: 24px;
  width: 24px;
  border-width: 4px;
  align-self: center;
  margin-left: 8px;
`;
